import APIController from "../APIController";
import { ICertificationExploitation } from "../types/certificationexploitation";

class CertificationExploitationController extends APIController {
  constructor() {
    super("/certification");
  }

  async getCertificationsExploitation(
    millesime: number,
    showHabilite = false,
    showActif = false,
  ): Promise<ICertificationExploitation[]> {
    return await this.getRequest("/", {
      params: {
        millesime: millesime,
        showHabilite: showHabilite,
        showActif: showActif,
      },
    });
  }
}

export default new CertificationExploitationController();
