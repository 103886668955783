import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CloseButton from "../components/Buttons/CloseButton";
import { getQueryParam, RenderIf } from "../config/utils";
import MentionsLegalesCard from "./mentionslegales/MentionsLegalesCard";

export default function PrivacyPolicy(): React.JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    if (getQueryParam("redirectlogin") === "true") {
      document.title = "Politique de confidentialité | Ecodim";
    }
  }, []);

  return (
    <>
      <Row style={{ marginTop: 20 }}>
        <Col>
          <Card style={{ display: "flex", width: "100%" }}>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h5">Politique de confidentialité</CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={getQueryParam("redirectlogin") === "true"}>
                    <CloseButton onClick={() => navigate("/auth/login")} />
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <h2>
                    Qu’est-ce que la Politique de confidentialité et que
                    couvre-t-elle ?
                  </h2>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <p>
                    À l'APGMB, nous souhaitons que vous compreniez quelles
                    informations nous recueillons et comment nous les utilisons
                    et les partageons. C’est pourquoi nous vous encourageons à
                    lire notre Politique de confidentialité. Cela vous permet
                    d’utiliser la plateforme de la manière qui vous convient le
                    mieux.
                  </p>
                  <p>
                    Dans cette Politique de confidentialité, nous expliquons
                    comment nous recueillons, utilisons, partageons, stockons et
                    transférons les informations. Nous vous faisons également
                    savoir quels sont vos droits. Chaque section de la Politique
                    comprend des exemples utiles et un langage simple pour
                    rendre nos pratiques plus simples à comprendre. Nous avons
                    également ajouté des liens vers des ressources qui vous
                    permettent d’en savoir plus sur les thèmes relatifs à la
                    confidentialité susceptibles de vous intéresser.
                  </p>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <h2>Quelles informations recueillons-nous ?</h2>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <p>
                    Les informations reccueillies par la plateforme Ecodim
                    permettent une amélioration continue de la production de la
                    graine de Moutarde.
                  </p>
                  <p>Voici les informations que nous recueillons :</p>
                  <ul>
                    <li>Informations administratives de l'utilisateur</li>
                    <li>Informations administratives de votre exploitation</li>
                    <li>Informations administratives des producteurs</li>
                    <li>Informations sur les intentions de semis</li>
                    <li>Informations sur les contrats de productions</li>
                    <li>Informations sur les conventions</li>
                    <li>Informations sur les certifications</li>
                    <li>Informations sur les parcelles</li>
                    <li>Informations sur les formations</li>
                  </ul>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <h2>Comment utilisons-nous vos informations ?</h2>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <p>
                    Nous utilisons les informations que nous reccueillons pour
                    vous fournir une expérience personnalisée, par le biais des
                    différents modules de la plateforme, ainsi qu’à d’autres
                    fins expliquées en détail ci-dessous.
                  </p>
                  <p>
                    À ces fins, nous utilisons des informations sur différents
                    appareils. Les informations que nous utilisons à ces fins
                    sont traitées automatiquement par nos systèmes. Dans
                    certains cas, nous effectuons également un examen manuel
                    pour accéder à vos informations et les examiner.
                  </p>
                  <p>
                    Dans certains cas, pour utiliser moins d’informations liées
                    à des utilisateurs individuels, nous désidentifions ou
                    agrégeons les informations. Nous pouvons également les
                    rendre anonymes afin qu’elles ne permettent plus de vous
                    identifier. Nous utilisons ces informations de la même
                    manière que les informations vous concernant décrites dans
                    cette section.
                  </p>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <h2>
                  Que se passe-t-il si vous ne nous laissez pas recueillir
                  certaines informations ?
                </h2>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <p>
                    Certaines informations sont requises pour que la plateforme
                    fonctionne. D’autres informations sont facultatives, mais la
                    qualité de votre expérience pourrait être affectée sans
                    celles-ci.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col>
          <MentionsLegalesCard />
        </Col>
      </Row>
    </>
  );
}
