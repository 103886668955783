import AuthService from "../../../config/AuthService";

export const columns = [
  {
    headerName: "Civilité",
    field: "idcivilite",
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          value: 1,
          label: "Monsieur",
        },
        {
          value: 2,
          label: "Madame",
        },
      ],
    },
    cellRenderer: (params) => params.data.civilite,
  },
  {
    headerName: "Nom",
    field: "nom",
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
  {
    headerName: "Prénom",
    field: "prenom",
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
  {
    headerName: "Raison sociale",
    field: "raisonsociale",
  },
  {
    headerName: "Adresse mail",
    field: "mail",
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
    flex: 2,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
    flex: 2,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
];
