import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeMatiereActive from "./TypeMatiereActive/ListeTypeMatiereActive";
import FormNewTypeMatiereActive from "./TypeMatiereActive/FormNewTypeMatiereActive";
import FormUpdateTypeMatiereActive from "./TypeMatiereActive/FormUpdateTypeMatiereActive";
import { createNotification } from "../../config/utils";

export default function TypeMatiereActive() {
  const store = useContext(StoreContext);
  const [showFormNewTypeMatiereActive, setShowFormNewTypeMatiereActive] =
    useState(false);
  const [showFormUpdateTypeMatiereActive, setShowFormUpdateTypeMatiereActive] =
    useState(false);
  const [idreftypematiereactiveToUpdate, setIdreftypematiereactiveToUpdate] =
    useState();

  const handleAddNewTypeMatiereActive = async (typeMatiereActive) => {
    const res =
      await ReferentielController.insertTypeMatiereActive(typeMatiereActive);
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de matière active créé avec succès",
      );
      store.referentiels.setMatieresActives([
        ...store.referentiels.matieresActives,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de matière active",
      );
    }
  };

  const handleUpdateTypeMatiereActive = async (typeMatiereActive) => {
    const res = await ReferentielController.updateTypeMatiereActive(
      idreftypematiereactiveToUpdate,
      typeMatiereActive,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de matière active modifié avec succès",
      );
      store.referentiels.setMatieresActives(
        await ReferentielController.getTypesMatieresActives(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de matière active",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeMatiereActive}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeMatiereActive
          onClickAdd={handleAddNewTypeMatiereActive}
          onClickClose={() => setShowFormNewTypeMatiereActive(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeMatiereActive}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeMatiereActive
          onClickUpdate={handleUpdateTypeMatiereActive}
          onClickClose={() => setShowFormUpdateTypeMatiereActive(false)}
          idreftypematiereactive={idreftypematiereactiveToUpdate}
        />
      </Collapse>
      <ListeTypeMatiereActive
        showFormUpdateTypeMatiereActive={(idreftypematiereactive) => {
          setIdreftypematiereactiveToUpdate(idreftypematiereactive);
          setShowFormUpdateTypeMatiereActive(true);
        }}
        onClickNewType={() => setShowFormNewTypeMatiereActive(true)}
      />
    </>
  );
}
