import React from "react";
import ContratsIncomplets from "../../views/analyses/ContratsIncomplets";
import ContratsNonRentres from "../../views/analyses/ContratsNonRentres";
import ComparatifContratConvention from "../../views/analyses/ComparatifContratConvention";
import ComparatifContratIntention from "../../views/analyses/ComparatifContratIntention";
import CotisationsImpayees from "../../views/analyses/CotisationsImpayees";
import ExploitationsZoneProduction from "../../views/analyses/ExploitationsZoneProduction";
import OrganismesStockeurs from "../../views/analyses/OrganismesStockeurs";
import LivraisonsNonSaisies from "../../views/analyses/LivraisonsNonSaisies";
import SurfaceVarieteOS from "../../views/analyses/SurfaceVarieteOS";
import ListeRouge from "../../views/analyses/ListeRouge";

import droits from "../CodesDroits";
import Layouts from "../layoutRoutes";
import IntentionSemisNonRentree from "../../views/analyses/IntentionSemisNonRentree";
import ExploitationSansProducteur from "../../views/analyses/ExploitationSansProducteur";
import ConformiteJacheresMelliferes from "../../views/analyses/ConformiteJacheresMelliferes";
import CodesOs from "../../views/analyses/CodesOs";
import TracabiliteContrats from "../../views/analyses/TracabiliteContrats";

const analysesRoutes = [
  {
    path: "/analyses/liste-rouge",
    name: "Exploitations sur liste rouge | Analyses",
    element: <ListeRouge />,
    restriction: droits.typesdroits.LISTE_ROUGE,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["exploitation", "liste", "rouge", "analyses"],
  },
  {
    path: "/analyses/contrats-non-rentres",
    name: "Contrats non rentrés | Analyses",
    element: <ContratsNonRentres />,
    restriction: droits.typesdroits.CONTRATS_NON_RENTRES,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Contrats", "analyses"],
  },
  {
    path: "/analyses/contrats-incomplets",
    name: "Contrats incomplets | Analyses",
    element: <ContratsIncomplets />,
    restriction: droits.typesdroits.CONTRATS_INCOMPLETS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Contrats", "analyses"],
  },
  {
    path: "/analyses/exploitations-zone-production",
    name: "Exploitations par zone de production | Analyses",
    element: <ExploitationsZoneProduction />,
    restriction: droits.typesdroits.EXPLOITATION_PAR_ZONE_PRODUCTION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Contrats", "analyses"],
  },
  {
    path: "/analyses/exploitations-sans-producteur",
    name: "Exploitations sans producteurs | Analyses",
    element: <ExploitationSansProducteur />,
    restriction: droits.typesdroits.EXPLOITATION_SANS_PRODUCTEUR,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Exploitations", "analyses"],
  },
  {
    path: "/analyses/livraisons-non-saisies",
    name: "Livraisons non saisies | Analyses",
    element: <LivraisonsNonSaisies />,
    restriction: droits.typesdroits.LIVRAISONS_NON_SAISIES,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["livraisons", "saisies", "analyses"],
  },
  {
    path: "/analyses/cotisations-impayees",
    name: "Cotisations non payées | Analyses",
    element: <CotisationsImpayees />,
    restriction: droits.typesdroits.COTISATIONS_IMPAYEES,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["cotisations", "analyses"],
  },
  {
    path: "/analyses/organismes-stockeurs",
    name: "Coordonnées des organismes stockeurs | Analyses",
    element: <OrganismesStockeurs />,
    restriction: droits.typesdroits.ORGANISMES_STOCKEURS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["organismes", "stockeurs", "analyses"],
  },
  {
    path: "/analyses/comparatif-contrat-convention",
    name: "Comparatif contrat / convention | Analyses",
    element: <ComparatifContratConvention />,
    restriction: droits.typesdroits.COMPARATIF_CONTRAT_CONVENTION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Contrats", "conventions", "analyses"],
  },
  {
    path: "/analyses/comparatif-contrat-intention",
    name: "Comparatif contrat / intention | Analyses",
    element: <ComparatifContratIntention />,
    restriction: droits.typesdroits.COMPARATIF_CONTRAT_INTENTION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Contrats", "intentions", "analyses"],
  },
  {
    path: "/analyses/surface-variete-os",
    name: "Surfaces par variétés et par OS | Analyses",
    element: <SurfaceVarieteOS />,
    restriction: droits.typesdroits.SURFACES_VARIETE_OS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Contrats", "analyses"],
  },
  {
    path: "/analyses/intention-semis-non-rentree",
    name: "Intentions de semis non rentrées | Analyses",
    element: <IntentionSemisNonRentree />,
    restriction: droits.typesdroits.INTENTION_SEMIS_NON_RENTREES,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Contrats", "analyses"],
  },
  {
    path: "/analyses/conformite-jacheres-melliferes",
    name: "Conformité des jachères Mellifères | Analyses",
    element: <ConformiteJacheresMelliferes />,
    restriction: droits.typesdroits.CONFORMITE_JACHERES_MELLIFERES,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Contrats", "analyses"],
  },
  {
    path: "/analyses/codes-os",
    name: "Codes Organisme Stockeur | Analyses",
    element: <CodesOs />,
    restriction: droits.typesdroits.CODES_OS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["codes", "organismes", "stockeurs", "analyses"],
  },
  {
    path: "/analyses/suivi-cultures-cotisations",
    name: "Suivi des cultures - cotisations | Analyses",
    element: <TracabiliteContrats />,
    restriction: droits.typesdroits.TRACABILITE_CONTRATS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: [
      "tracabilites",
      "contrats",
      "organismes",
      "stockeurs",
      "analyses",
    ],
  },
];

export default analysesRoutes;
