import AuthService from "../../../config/AuthService";
import droits from "../../../config/CodesDroits";

export const columns = [
  {
    headerName: "Statut juridique",
    field: "libellestatut",
  },
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "SIRET",
    field: "siret",
  },
  {
    headerName: "Adresse",
    field: "adresse",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
  },
  {
    headerName: "Code OS",
    field: "codeos",
    cellDataType: "text",
    editable: AuthService.hasDroit(
      droits.typesdroits.CODES_OS,
      droits.modalite.modification,
    ),
  },
  {
    headerName: "Code DC",
    field: "codedc",
    cellDataType: "text",
    editable: AuthService.hasDroit(
      droits.typesdroits.CODES_OS,
      droits.modalite.modification,
    ),
  },
];
