import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleDisabled,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterDate,
  valueFormatterSurface,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Validé",
    field: "idetatvalidationadministrative",
    minWidth: 170,
    flex: 1,
    cellStyle: function (params) {
      if (params.data.valide === true) {
        return cellStyleSuccess;
      }
      if (params.value === 0) {
        return cellStyleDisabled;
      }
      if (params.value === 1) {
        return cellStyleDanger;
      }
      return cellStyleWarning;
    },
    cellRenderer: (params) => params.data.libellevalidationadministrative,
  },
  {
    headerName: "Numéro du contrat",
    field: "numerocontrat",
    minWidth: 200,
    flex: 1,
    cellStyle: (params) =>
      params.data.abandon ? cellStyleDisabled : cellStyleDefault,
  },
  {
    headerName: "Date du contrat",
    field: "datecontrat",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Production",
    children: [
      {
        headerName: "Organisme stockeur",
        field: "nomorganismestockeur",
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: "Type de production",
        field: "libelletypeproduction",
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: "Nombre de parcelles",
        field: "nbparcelles",
        minWidth: 180,
        flex: 1,
      },
    ],
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface printemps (ha)",
        field: "surfaceprintemps",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface automne (ha)",
        field: "surfaceautomne",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface totale (ha)",
        field: "surfacetotale",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
      },
    ],
  },
  {
    headerName: "Abandonné",
    field: "abandon",
    minWidth: 200,
    flex: 1,
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleSuccess,
      ),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Commentaire abandon",
    field: "commentaireabandon",
    minWidth: 250,
    flex: 1,
  },
];
