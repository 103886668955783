import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../../config/routes";
import layouts from "../../config/layoutRoutes";
import backgrounds from "../../config/Backgrounds";
import { Container } from "reactstrap";

const Background = backgrounds[Math.floor(Math.random() * backgrounds.length)];

export default function MaintenanceLayout(): React.JSX.Element {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === `${layouts.maintenanceLayout}`) {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.element}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div style={styles.background}>
        <Container fluid style={styles.container}>
          <Routes>
            {getRoutes(routes)}
            <Route
              path="*"
              element={
                <Navigate
                  replace
                  to={`${layouts.maintenanceLayout}/${window.location.search}`}
                />
              }
            />
          </Routes>
        </Container>
      </div>
    </>
  );
}

const styles = {
  container: {
    maxWidth: "100%",
    height: "100%",
    overflowY: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  background: {
    backgroundImage: `url(${Background}) `,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
  },
};
