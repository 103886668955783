import { useContext, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import storeContext from "../../../context/StoreContext";
import { createNotification, RenderIf } from "../../../config/utils";
import OrganismeStockeurController from "../../../config/apiUtils/OrganismeStockeurController";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import ExploitationController from "../../../config/apiUtils/ExploitationController";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import moment from "moment/moment";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import useConstructor from "../../../config/hooks/useConstructor";
import SurfacePotentielleController from "../../../config/apiUtils/SurfacePotentielleController";
import useKeyPress from "../../../config/hooks/useKeyPress";
import Keys from "../../../config/Keys";

export default function ModalNewIntentionSemis(props) {
  const [loading, setLoading] = useState(false);
  const context = useContext(storeContext);
  const [idExploitation, setIdexploitation] = useState(
    context.exploitation.informations.idexploitation,
  );

  const checks = [
    {
      id: 1,
      name: "engagementcontratcotisation",
      required: true,
      label: (
        <>
          Je m'engage à <b>établir un contrat</b> de production au début de la
          campagne et à<b>payer ma cotisation</b> dès réception de la facture (à
          la validation du contrat)
        </>
      ),
    },
    {
      id: 2,
      name: "engagementbouestationepuration",
      required: true,
      label: (
        <>
          Je m'engage à ne pas apporter de boues de station d'épuration, compost
          contenant des boues, cendres incinérateur, produits contenant des
          boues de station d’épuration collective ou industrielle ou privée,
          pendant la campagne de production
        </>
      ),
    },
    {
      id: 3,
      name: "engagementensemencervarietes",
      required: true,
      label: (
        <>
          Je m'engage à ensemencer les variétés attribuées par l’APGMB et les
          surfaces engagées dans le contrat de production
        </>
      ),
    },
    {
      id: 4,
      name: "engagementmenerculture",
      required: true,
      label: (
        <>
          Je m'engage à mener la culture conformément au GUIDE TECHNIQUE et aux
          avertissements et{" "}
          <b>
            remettre le carnet de suivi ou export de votre logiciel de
            traçabilité
          </b>{" "}
          aux dates demandées
        </>
      ),
    },
    {
      id: 5,
      name: "engagementsignalerapgmb",
      required: true,
      label: (
        <>
          Je m'engage à signaler à l’APGMB toute modification de surface ou
          perte de culture totale ou partielle
        </>
      ),
    },
    {
      id: 6,
      name: "engagementrecolter",
      required: true,
      label: (
        <>
          Je m'engage à récolter en respectant les normes qualitatives
          ("consignes déclanchement de récolte")
        </>
      ),
    },
    {
      id: 7,
      name: "engagementlivrerrecolte",
      required: true,
      label: (
        <>
          Je m'engage à livrer la <b>TOTALITE</b> de la récolte, ne pas stocker
          la récolte
        </>
      ),
    },
    {
      id: 8,
      name: "engagementnepastransformer",
      required: true,
      label: (
        <>
          Je m'engage à ne pas transformer les graines produites avec des
          semences développées dans la cadre de la filière pour la mise en
          marché
        </>
      ),
    },
    {
      id: 9,
      name: "validationpriseconnaissance",
      required: true,
      label: (
        <>
          J'ai pris connaissance de mes engagements vis-à-vis de la filière, et
          m'engage à les respecter
        </>
      ),
    },
  ];

  useConstructor(async () => {
    if (context.utilisateur.informations.isProducteur) {
      createNotification(
        "info",
        "Information",
        "En remplissant ce formulaire, vous vous engagez à respectez les éléments de la convention accessible dans la page : Exploitations / Producteurs > Mes documents",
        20000,
      );
      createNotification(
        "info",
        "Information",
        "Dans les champs des surfaces printemps et automne, merci de ne saisir que des chiffres.",
        20000,
      );
    }
    setLoading(true);
    context.setOrganismesStockeur(
      await OrganismeStockeurController.getOrganismesStockeur(),
    );
    context.referentiels.setTypesProduction(
      await ReferentielController.getTypesProduction(true),
    );
    context.setExploitations(await ExploitationController.getExploitations());
    setLoading(false);
  });

  useKeyPress(Keys.Escape, () => {
    props.toggle();
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (event.target?.idorganismestockeur?.value == -1) {
      createNotification(
        "error",
        "Erreur - Formulaire incomplet",
        "Merci de sélectionner un Organisme Stockeur dans la liste déroulante.",
      );
      setLoading(false);
      return;
    }
    const intention = {
      surfacepotentielleprintemps:
        event.target.surfacepotentielleprintemps.value,
      surfacepotentielleautomne: event.target.surfacepotentielleautomne.value,
      commentaire: event.target.commentaire.value,
      idorganismestockeur: event.target.idorganismestockeur.value,
      idreftypeproduction: event.target.idreftypeproduction.value,
      idexploitation: event.target.idexploitation.value,
      datesurfacepotentielle: event.target.datesurfacepotentielle.value,
      millesime: context.millesime.idmillesime,
    };

    const res =
      await SurfacePotentielleController.insertSurfacePotentielle(intention);

    if (res.status === 200) {
      createNotification("success", "Succès", "Intention de semis créée");
      context.surfacesPotentielles.push(res.data);
      props.toggle();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la création de l'intention de semis...",
      );
    }
    setLoading(false);
  };

  const renderChecks = () => {
    return checks.map((check) => {
      return (
        <Row key={`row-${check.id}-${check.name}`} style={{ marginBottom: 10 }}>
          <Col>
            <Label className={check.required ? "required" : ""} check>
              <Input
                type="checkbox"
                name={check.name}
                required={check.required}
              />{" "}
              {check.label}
            </Label>
          </Col>
        </Row>
      );
    });
  };

  const renderExploitationOptions = () => {
    return context.exploitations.map((exploitation, key) => {
      return (
        <option key={key} value={exploitation.idexploitation}>
          {exploitation.raisonsociale}
        </option>
      );
    });
  };

  const renderOrganismesStockeursOptions = () => {
    let organismes = [...context.organismesStockeur];
    organismes.unshift({ idorganismestockeur: -1, nomorganismestockeur: "-" });
    return organismes.map((organisme, key) => {
      return (
        <option key={key} value={organisme.idorganismestockeur}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  const renderTypesProductionOptions = () => {
    return context.referentiels.typesProduction.map((type, key) => {
      return (
        <option key={key} value={type.idreftypeproduction}>
          {type.libelle}
        </option>
      );
    });
  };

  return (
    <Modal
      isOpen={props.isOpen}
      size={"lg"}
      centered
      scrollable={false}
      unmountOnClose
    >
      <ModalHeader className="modal-header-form">
        Nouvelle intention de semis
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="modal-body-form">
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Row style={{ marginBottom: 10 }}>
              <Col md="6">
                <FormGroup>
                  <Label for="datesurfacepotentielle">Date</Label>
                  <Input
                    type="date"
                    name="datesurfacepotentielle"
                    defaultValue={moment().format("YYYY-MM-DD")}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="idexploitation">Exploitation</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idexploitation"
                    value={idExploitation}
                    onChange={(event) => setIdexploitation(event.target.value)}
                    disabled={
                      context.utilisateur.profilsUtilisateur[0].codeprofil !==
                      "ADMINISTRATEUR"
                    }
                  >
                    {renderExploitationOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col md="4">
                <FormGroup>
                  <Label for="surfacepotentielleprintemps">
                    Surface potentielle printemps (Ha)
                  </Label>
                  <Input
                    type="text"
                    name="surfacepotentielleprintemps"
                    placeholder="Surface potentielle printemps (Ha)"
                    pattern="^\d{1,4}(?:\.\d{1,2})?$"
                    title="Merci de ne saisir que des chiffres"
                    required
                    defaultValue={0}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="surfacepotentielleautomne">
                    Surface potentielle automne (Ha)
                  </Label>
                  <Input
                    type="text"
                    name="surfacepotentielleautomne"
                    placeholder="Surface potentielle automne (Ha)"
                    pattern="^\d{1,4}(?:\.\d{1,2})?$"
                    title="Merci de ne saisir que des chiffres"
                    required
                    defaultValue={0}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="idreftypeproduction">Type de production</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idreftypeproduction"
                    placeholder="Type de production"
                  >
                    {renderTypesProductionOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col md="6">
                <FormGroup>
                  <Label for="idorganismestockeur">Organisme stockeur</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idorganismestockeur"
                    placeholder="Organisme stockeur"
                    defaultValue={
                      context.organismesStockeur.length === 1
                        ? context.organismesStockeur[0].idorganismestockeur
                        : -1
                    }
                  >
                    {renderOrganismesStockeursOptions()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="commentaire">Commentaire</Label>
                  <Input
                    type="textarea"
                    name="commentaire"
                    placeholder="Commentaire"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                Mes engagements :{" "}
                <a
                  style={{
                    textDecoration: "none",
                    color: "#f9aa33",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  href="/app/exploitation-document"
                  title="Page des documents"
                  id="mesdocuments"
                >
                  Cliquez ici
                </a>
              </Col>
            </Row>
            {renderChecks()}
          </RenderIf>
        </ModalBody>
        <ModalFooter
          className="modal-footer-form"
          style={{ justifyContent: "left" }}
        >
          <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
          <CancelButton
            style={{ marginTop: 15 }}
            onClick={(event) => {
              event.preventDefault();
              props.toggle();
            }}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
