import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Millésime",
    field: "millesime",
    minWidth: 110,
    flex: 1,
  },
  {
    headerName: "Date de la demande",
    field: "datedemande",
    minWidth: 130,
    flex: 1,
  },
  {
    headerName: "Date d'envoi",
    field: "dateenvoicertif",
    minWidth: 130,
  },
  {
    headerName: "Date de l'habilitation",
    field: "datehabilitation",
    minWidth: 140,
    flex: 1,
  },
  {
    headerName: "Habilité",
    field: "habilite",
    minWidth: 170,
    flex: 1,
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
  },
  {
    headerName: "Actif",
    field: "actif",
    minWidth: 170,
    flex: 1,
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
  },
];
