import { Row } from "reactstrap";
import React from "react";

export default function Page404(): React.JSX.Element {
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../assets/img/404.png")}
            style={{ height: 200, width: 225 }}
          ></img>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          La page demandée est introuvable...
        </Row>
      </Row>
    </>
  );
}
