export const columns = [
  {
    headerName: "Organisme",
    field: "nomorganismestockeur",
  },
  {
    headerName: "Adresse",
    children: [
      {
        headerName: "Adresse",
        field: "adresse",
        flex: 2,
        valueGetter: (params) => {
          return `${params.data.adresse} - ${params.data.codepostal} ${params.data.nomcommune}`;
        },
        columnGroupShow: "closed",
      },
      {
        headerName: "Adresse",
        field: "adresse",
        flex: 2,
        columnGroupShow: "open",
      },
      {
        headerName: "Code postal",
        field: "codepostal",
        flex: 2,
        columnGroupShow: "open",
      },
      {
        headerName: "Commune",
        field: "nomcommune",
        flex: 2,
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Coordonnées",
    children: [
      {
        headerName: "Mail",
        field: "mail",
        flex: 2,
      },
      {
        headerName: "Téléphone",
        field: "telephone",
        flex: 2,
      },
    ],
  },
];
