import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";
import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
} from "../../../config/gridUtils";
import AgGrid from "../../../components/AgGrid";

const columns = [
  {
    headerName: "Type de produit",
    field: "libelletypeproduit",
  },
  {
    headerName: "Produit",
    field: "libelleproduit",
  },
  {
    headerName: "Unité",
    field: "unite",
  },
  {
    headerName: "Utilisée",
    field: "estutilise",
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
];

export default function ListeProduit(props) {
  const store = useContext(StoreContext);

  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setProduits(await ReferentielController.getProduits());
    store.referentiels.setTypesProduits(
      await ReferentielController.getTypesProduit(),
    );
    store.referentiels.setMatieresActives(
      await ReferentielController.getTypesMatieresActives(),
    );
    setLoading(false);
  });

  const onRowClick = async (params) => {
    props.showFormUpdateProduit(params.data.idrefproduit);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Produits</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <AddButton onClick={props.onClickNewProduit} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col
              style={
                loading ? { display: "flex", justifyContent: "center" } : {}
              }
            >
              {loading && <SpinLoadingAnimation />}
              {store.referentiels.produits !== null &&
                store.referentiels.produits !== [] &&
                store.referentiels.produits !== undefined &&
                !loading && (
                  <AgGrid
                    columnDefs={columns}
                    rowData={store.referentiels.produits}
                    onRowClicked={onRowClick}
                    showFilterLine
                  />
                )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
