import APIController from "../APIController";
import {
  IAppelCotisation,
  IAppelIntentionsSemis,
  IAttestationLivraisonSemences,
  IAvertissement,
  IConfirmationSurface,
  IConsigneRecolte,
  IEnvoiGuide,
  IExploitationActiveProduction,
  IHistoriqueFormation,
  IPreAvertissement,
} from "src/config/types/actionsgroupees";

class ActionGroupeeController extends APIController {
  constructor() {
    super("/actionsgroupees");
  }

  async getAppelIntentionsSemis(): Promise<IAppelIntentionsSemis[]> {
    return await this.getRequest("/appelintentionsemis");
  }

  async getAvertissement(millesime: number): Promise<IAvertissement[]> {
    return await this.getRequest("/avertissements", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getPreAvertissements(millesime: number): Promise<IPreAvertissement[]> {
    return await this.getRequest("/preavertissements", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getConfirmationSurfaces(
    millesime: number,
  ): Promise<IConfirmationSurface[]> {
    return await this.getRequest("/confirmationsurfaces", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getAppelCotisations(millesime: number): Promise<IAppelCotisation[]> {
    return await this.getRequest("/appelcotisation", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getHistoriqueFormation(
    millesime: number,
  ): Promise<IHistoriqueFormation[]> {
    return await this.getRequest("/historiqueformation", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getExploitationsActivesProduction(
    millesime: number,
  ): Promise<IExploitationActiveProduction[]> {
    return await this.getRequest("/exploitationsactives", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getExploitationsActivesIntentionsContrats(
    millesime: number,
  ): Promise<IExploitationActiveProduction[]> {
    return await this.getRequest("/exploitationsactivesintentionscontrats", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getEnvoiGuidesCarnetSuivi(millesime: number): Promise<IEnvoiGuide[]> {
    return await this.getRequest("/envoiguide", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getAttestationLivraisonSemences(
    millesime: number,
  ): Promise<IAttestationLivraisonSemences[]> {
    return await this.getRequest("/attestationlivraisonsemences", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getConsignesRecolte(millesime: number): Promise<IConsigneRecolte[]> {
    return await this.getRequest("/consignesrecolte", {
      params: {
        millesime: millesime,
      },
    });
  }
}

export default new ActionGroupeeController();
