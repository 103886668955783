import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { columns } from "../../components/Grids/FormulaireUtilisateur/formulaires";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import AgGrid from "../../components/AgGrid";
import React from "react";
import { RowClickedEvent } from "ag-grid-community";
import { IUtilisateurFormulaire } from "../../config/types/user";

interface IProps {
  loading: boolean;
  formulaires: IUtilisateurFormulaire[];
  onClickFormulaire: (formulaire: IUtilisateurFormulaire) => void;
}

export default function ListeFormulaireUtilisateur(
  props: IProps,
): React.JSX.Element {
  const onRowClick = async (params: RowClickedEvent) => {
    props.onClickFormulaire(params.data);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Formulaires de demande</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!props.loading}>
          <AgGrid
            columnDefs={columns}
            rowData={props.formulaires}
            onRowClicked={onRowClick}
            showFilterLine
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
