import React, { useContext, useState } from "react";
import { Alert, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import CertificationIgpController from "../../config/apiUtils/CertificationIgpController";
import { columns } from "../../components/Grids/CertificationIgp/certificationigp";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import AgGrid from "../../components/AgGrid";

export default function TableCertificationsIgp(): React.JSX.Element {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.certifications.setCertificationsIgp(
      await CertificationIgpController.getCertificationsIgp(
        store.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Certifications IGP</CardTitle>
      </CardHeader>
      <CardBody>
        {loading && <SpinLoadingAnimation />}
        {!loading && (
          <>
            {store.certifications.certificationsIgp.length > 0 ? (
              <AgGrid
                columnDefs={columns}
                rowData={store.certifications.certificationsIgp}
                showFilterLine
              />
            ) : (
              <Alert fade={false} color="danger">
                Aucune certification IGP trouvée pour le millésime...
              </Alert>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}
