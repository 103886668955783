import { Col, Collapse, Form, Input, Label, Row, Table } from "reactstrap";
import { useContext, useEffect, useState } from "react";
import storeContext from "../../../../context/StoreContext";
import ContratController from "../../../../config/apiUtils/ContratController";
import { columns } from "../../../../components/Grids/Contrat/tableparcelleformcompleter";
import { columns as columnsParcelleContrat } from "../../../../components/Grids/Contrat/tableparcellerecapformcompleter";
import { createNotification, RenderIf } from "../../../../config/utils";
import SpinLoadingAnimation from "../../../../components/SpinLoadingAnimation";
import useConstructor from "../../../../config/hooks/useConstructor";
import ExploitationController from "../../../../config/apiUtils/ExploitationController";
import ValidateButton from "../../../../components/Buttons/ValidateButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { useRecoilState } from "recoil";
import { parcelleAssocieeSelectedState } from "../../../../config/atoms/parcellaireexploitation";
import AgGrid from "../../../../components/AgGrid";

export default function CompleterContrat({
  close,
  idcontrat,
  onClickSelectParcelle,
  onClickZoomParcelle,
}) {
  const context = useContext(storeContext);
  const [loading, setLoading] = useState(false);
  const [parcelleSelected, setParcelleSelected] = useState(null);
  const [contrat, setContrat] = useState({});
  const [nbParcellesRestantes, setNbParcellsRestantes] = useState(0);
  const [parcelleAssocieeSelected, setParcelleAssocieeSelected] =
    useRecoilState(parcelleAssocieeSelectedState);

  const load = async () => {
    setLoading(true);
    const tmp = await ContratController.getContratDetail(idcontrat);
    setContrat(tmp);
    setNbParcellsRestantes(tmp.nbparcelles - tmp.nbparcellesrenseignee);
    if (tmp.nbparcelles - tmp.nbparcellesrenseignee <= 0) {
      close();
    }

    const res = await ExploitationController.getParcellesByIdexploitation(
      context.exploitation.informations.idexploitation,
      context.millesime.idmillesime,
    );
    if (res.length == 0) {
      createNotification(
        "error",
        "Vous n'avez renseigné aucune parcelle cette année. Pour renseigner ce contrat, merci de créer des parcelles.",
      );
      close();
      return false;
    }
    context.setParcelles(
      res.filter(
        (p) =>
          !tmp.parcelles.map((item) => item.idparcelle).includes(p.idparcelle),
      ),
    );
    const typesSol = [];
    res.forEach((p) => {
      if (!typesSol.some((type) => type === p.libelletypesol)) {
        typesSol.push(p.libelletypesol);
      }
    });
    setLoading(false);
  };

  useConstructor(async () => {
    if (await load()) {
      context.carto.zoomEmprise();
    }
  });

  useEffect(() => {
    if (
      parcelleAssocieeSelected != null &&
      parcelleSelected == null &&
      context.parcelles
        .map((p) => p.idparcelle)
        .includes(parcelleAssocieeSelected.idparcelle)
    ) {
      onClickZoomParcelle(parcelleAssocieeSelected.idparcelle);
      setParcelleSelected(
        context.parcelles.find(
          (p) => p.idparcelle === parcelleAssocieeSelected.idparcelle,
        ),
      );
      onClickSelectParcelle(parcelleAssocieeSelected.idparcelle);
    }
    if (parcelleAssocieeSelected == null) {
      setParcelleSelected(null);
      setParcelleAssocieeSelected(null);
      context.carto.zoomEmprise();
    }
  }, [parcelleAssocieeSelected]);

  const onRowClick = (params) => {
    onClickZoomParcelle(params.data.idparcelle);
    setParcelleSelected(params.data);
    setParcelleAssocieeSelected(params.data);
    onClickSelectParcelle(params.data.idparcelle);
  };

  const onRowClickParcelleContrat = (params) => {
    onClickZoomParcelle(params.data.idparcelle);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let sommeSurfaces =
      (event.target.surfaceengageeprintemps.value != ""
        ? event.target.surfaceengageeprintemps.value
        : 0) +
      (event.target.surfaceengageeautomne.value != ""
        ? event.target.surfaceengageeautomne.value
        : 0);

    if (sommeSurfaces == 0) {
      createNotification(
        "error",
        "Merci de renseigner une surface supérieure à 0 Ha",
        "",
      );
      return;
    }
    const form = {
      idparcelle: parcelleSelected.idparcelle,
      surfaceengageeprintemps:
        event.target.surfaceengageeprintemps.value != ""
          ? event.target.surfaceengageeprintemps.value
          : 0,
      surfaceengageeautomne:
        event.target.surfaceengageeautomne.value != ""
          ? event.target.surfaceengageeautomne.value
          : 0,
      commentaire: event.target.commentaire.value,
      millesime: context.millesime.idmillesime,
    };

    const res = await ContratController.completerContrat(
      contrat.idcontrat,
      form,
    );

    if (res.status === 200) {
      setParcelleSelected(null);
      const tmp = await ContratController.getContratDetail(idcontrat);
      setContrat(tmp);
      const nbRestant = tmp.nbparcelles - tmp.nbparcellesrenseignee;
      setNbParcellsRestantes(nbRestant);
      if (nbRestant === 0) {
        // prettier-ignore
        createNotification(
                    "success",
                    "Contrat complété",
                    "Votre contrat a bien été complété. Pour continuer, merci de confirmer que vous avez bien pris connaissance des engagements en cochant la case ci-dessous, et de signer le contrat en cliquant sur le bouton \"Signer le contrat\" ci-dessous",
                    15000,
                );
        close();
      } else {
        createNotification("success", "Succès", "Contrat modifié avec succès");
      }
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }

    await load();
  };

  const renderNbParcellesRestantes = () => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col>
          Il reste {nbParcellesRestantes} parcelle
          {nbParcellesRestantes > 1 ? "s" : ""} à renseigner pour compléter le
          contrat {contrat.numerocontrat}
        </Col>
      </Row>
    );
  };

  const renderParcelleInformation = () => {
    return (
      <Table style={{ color: "#FFFFFF" }}>
        <tbody>
          <tr>
            <th scope="row">Nom de la parcelle</th>
            <td>{parcelleSelected?.nomparcelle}</td>
          </tr>
          <tr>
            <th scope="row">Commune</th>
            <td>
              {parcelleSelected?.codepostal} {parcelleSelected?.nomcommune}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderInformationExploitation = () => {
    return (
      <Table style={{ color: "#FFFFFF" }}>
        <tbody>
          <tr>
            <th scope="row">Raison sociale</th>
            <td>{contrat.raisonsociale}</td>
          </tr>
          <tr>
            <th scope="row">Numéro SIRET</th>
            <td>{contrat.siret}</td>
          </tr>
          <tr>
            <th scope="row">Adresse</th>
            <td>
              {contrat.adresse}
              <br />
              {contrat.codepostal} {contrat.nomcommune}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <hr />
      <Row>
        <Col>
          <h5>Compléter mon contrat {contrat.numerocontrat}</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            {renderNbParcellesRestantes()}
            <h5>Informations exploitation</h5>
            {renderInformationExploitation()}
            <h5>Parcelle du contrat {contrat.numerocontrat}</h5>
            <AgGrid
              columnDefs={columnsParcelleContrat}
              rowData={contrat?.parcelles}
              height={350}
              onRowClick={onRowClickParcelleContrat}
              showFilterLine
            />
            <Row style={{ marginTop: 20, marginBottom: 10 }}>
              <h5>
                Parcelles -{" "}
                {parcelleSelected != null
                  ? parcelleSelected.nomparcelle
                  : "Sélectionnez une parcelle dans la liste pour compléter le contrat"}
              </h5>
              <Col>
                <Collapse isOpen={parcelleSelected == null}>
                  <AgGrid
                    columnDefs={columns}
                    rowData={context.parcelles}
                    height={400}
                    onRowClicked={onRowClick}
                    showFilterLine
                  />
                </Collapse>
                <Collapse
                  isOpen={parcelleSelected != null}
                  mountOnEnter
                  unmountOnExit
                >
                  <Form onSubmit={onSubmit}>
                    <Row style={{ marginBottom: 10 }}>
                      <Col md="6">{renderParcelleInformation()}</Col>
                      <Col md="6">
                        <Label for="commentaire">
                          Commentaires et remarques
                        </Label>
                        <Input
                          name="commentaire"
                          type="textarea"
                          placeholder="Commentaires et remarques..."
                        ></Input>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                      <Col md="6">
                        <Label for="surfaceengageeprintemps">
                          Surface engagée pour le printemps
                        </Label>
                        <Input
                          name="surfaceengageeprintemps"
                          type="text"
                          placeholder="Surface engagée pour le printemps"
                          pattern="^\d{1,4}(?:\.\d{1,2})?$"
                          title="Merci de ne saisir que des chiffres"
                          required
                          defaultValue={0}
                          onChange={(event) => {
                            if (
                              event.target.value >
                              parcelleSelected.surfacesaisie
                            ) {
                              createNotification(
                                "warning",
                                "Attention",
                                "La surface saisie au printemps est supérieure à la surface déclarée de votre parcelle " +
                                  parcelleSelected.nomparcelle +
                                  " de " +
                                  parcelleSelected.surfacesaisie +
                                  " Ha",
                              );
                            }
                          }}
                        ></Input>
                      </Col>
                      <Col md="6">
                        <Label for="surfaceengageeautomne">
                          Surface engagée pour l'automne
                        </Label>
                        <Input
                          name="surfaceengageeautomne"
                          type="text"
                          placeholder="Surface engagée pour l'automne"
                          pattern="^\d{1,4}(?:\.\d{1,2})?$"
                          title="Merci de ne saisir que des chiffres"
                          required
                          defaultValue={0}
                          onChange={(event) => {
                            if (
                              event.target.value >
                              parcelleSelected.surfacesaisie
                            ) {
                              createNotification(
                                "warning",
                                "Attention",
                                "La surface saisie à l'automne est supérieure à la surface déclarée de votre parcelle " +
                                  parcelleSelected.nomparcelle +
                                  " de " +
                                  parcelleSelected.surfacesaisie +
                                  " Ha",
                              );
                            }
                          }}
                        ></Input>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                      <Col>
                        <ValidateButton style={{ marginRight: 10 }} />
                        <CancelButton
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            context.carto
                              .getParcellaireLayer()
                              .clearSelection();
                            setParcelleSelected(null);
                            context.carto.zoomEmprise();
                          }}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Collapse>
              </Col>
            </Row>
          </RenderIf>
        </Col>
      </Row>
    </>
  );
}
