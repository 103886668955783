import {
  valueFormatterTonnage,
  valueFormatterSurface,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Code DC",
    field: "codedc",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Date surface potentielle",
    field: "datesurfacepotentielle",
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Surface potentielle printemps",
    field: "surfacepotentielleprintemps",
    valueFormatter: valueFormatterTonnage,
  },
  {
    headerName: "Surface potentielle automne",
    field: "surfacepotentielleautomne",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Surface potentielle totale",
    field: "surfacepotentielletotale",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Tonnage totale",
    field: "tonnagetotal",
    valueFormatter: valueFormatterSurface,
  },
];
