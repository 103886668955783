import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterDate,
  valueFormatterSurface,
  valueFormatterTonnage,
  valueFormatterUnite,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Contrat",
    children: [
      {
        headerName: "Validé administrativement",
        field: "idetatvalidationadministrative",
        minWidth: 170,
        flex: 1,
        cellStyle: function (params) {
          if (params.data.valide === true) {
            return cellStyleSuccess;
          }
          if (params.value === 1) {
            return cellStyleDanger;
          }
          return cellStyleWarning;
        },
        cellRenderer: (params) => params.data.libellevalidationadministrative,
      },
      {
        headerName: "Numéro du contrat",
        field: "numerocontrat",
        minWidth: 170,
        flex: 1,
      },
      {
        headerName: "Date de dernière modification",
        field: "datedernieremodification",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Organisme stockeur",
        field: "nomorganismestockeur",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Exploitation",
    children: [
      {
        headerName: "SIRET",
        field: "exploitation.siret",
        minWidth: 180,
        flex: 1,
      },
      {
        headerName: "Statut juridique",
        field: "exploitation.libellestatutjuridique",
        minWidth: 150,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Raison sociale",
        field: "exploitation.raisonsociale",
        minWidth: 300,
        flex: 1,
      },
      {
        headerName: "Numéro UNILEVER",
        field: "exploitation.numerounilever",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Localisation",
        field: "exploitation.codeinsee",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Code DC",
        field: "exploitation.codedc",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Code OS",
        field: "exploitation.codeos",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Zones de production",
        field: "exploitation.libellezoneproduction",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Parcelle",
    children: [
      {
        headerName: "Nom de la parcelle",
        field: "parcelle.nomparcelle",
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: "Type de production",
        field: "libelletypeproduction",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Variété",
        field: "libellevariete",
        minWidth: 200,
        flex: 1,
      },
    ],
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface réelle printemps (ha)",
        field: "surfaceprintemps",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface réelle automne (ha)",
        field: "surfaceautomne",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface réelle totale (ha)",
        field: "surfacetotale",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
      },
      {
        headerName: "Quantité de semences",
        field: "quantitesemence",
        minWidth: 200,
        flex: 1,
      },
    ],
  },
  {
    headerName: "Silo",
    field: "nomsilo",
    minWidth: 200,
    flex: 1,
    cellRenderer: (params) => params.value ?? "PAS DE SILO",
  },
  {
    headerName: "Rendement de référence moyen",
    field: "exploitation.rendementrefmoyen",
    minWidth: 200,
    flex: 1,
    valueFormatter: (params) => valueFormatterUnite(params, "T/ha"),
  },
  {
    headerName: "Tonnage de référence moyen",
    field: "tonnageestime",
    minWidth: 200,
    flex: 1,
    valueFormatter: valueFormatterTonnage,
  },
  {
    headerName: "Traçabilité conforme",
    field: "estconforme",
    minWidth: 170,
    flex: 1,
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
];
