import APIController from "../APIController";
import {
  IConvention,
  IConventionPOST,
  IConventionPUT,
} from "../types/convention";
import { AxiosResponse } from "axios";

class ConventionController extends APIController {
  constructor() {
    super("/convention");
  }

  async getConventionMillesime(millesime: number): Promise<IConvention[]> {
    return await this.getRequest(`/?millesime=${millesime}`);
  }

  async insertConvention(
    convention: IConventionPOST,
  ): Promise<AxiosResponse<IConvention>> {
    return await this.postRequest("/", convention);
  }

  async updateConvention(
    idconvention: number,
    convention: IConventionPUT,
  ): Promise<AxiosResponse<IConvention>> {
    return await this.putRequest(`/${idconvention}`, convention);
  }
}

export default new ConventionController();
