import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { RenderIf } from "../config/utils";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import Parcellaire from "./parcellaireexploitation/Parcellaire";

export default function ParcellaireExploitation(): React.JSX.Element {
  const [loading] = useState(false);

  return (
    <>
      <Row>
        <Col md="12">
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Parcellaire />
          </RenderIf>
        </Col>
      </Row>
    </>
  );
}
