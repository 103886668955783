import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import { valueFormatterSurface } from "../../config/gridUtils";
import AgGrid from "../../components/AgGrid";

const columns = [
  {
    headerName: "Organisme",
    field: "nomorganismestockeur",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface printemps",
        field: "surfaceprintemps",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
      },
      {
        headerName: "Surface automne",
        field: "surfaceautomne",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
      },
      {
        headerName: "Surface totale",
        field: "surfacetotale",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
      },
    ],
  },
  {
    headerName: "Quantités",
    field: "quantite",
    children: [],
  },
];

export default function BilanParOganismeStockeur(props) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Bilan Par Oganisme Stockeur</CardTitle>
        <CloseButton onClick={props.close} />
      </CardHeader>
      <CardBody>
        <AgGrid columnDefs={columns} rowData={[]} height={350} showFilterLine />
      </CardBody>
    </Card>
  );
}
