import { Fragment, useContext, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import { columns } from "../../components/Grids/planproduction/listeparorganismestockeur";
import StoreContext from "../../context/StoreContext";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import PlanProductionController from "../../config/apiUtils/PlanProductionController";
import CloseButton from "../../components/Buttons/CloseButton";
import useConstructor from "../../config/hooks/useConstructor";
import {
  getQueryParam,
  setQueryParam,
  RenderIf,
  createNotification,
  downloadCsv,
  convertToCSV,
  unflattenList,
} from "../../config/utils";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment";
import AgGrid from "../../components/AgGrid";

export default function ListeParOrganismeStockeur(props) {
  const store = useContext(StoreContext);
  const [idOrganismeStockeur, setIdOrganismeStockeur] = useState("-1");
  const [dataSource, setDataSource] = useState(
    store.planProduction.planProductionPrevisionnel,
  );
  const [organisme, setOrganisme] = useState({});
  const [organismesStockeur, setOrganismesStockeur] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalAutomne, setTotalAutomne] = useState(0);
  const [totalPrintemps, setTotalPrintemps] = useState(0);
  const [varietes, setVarietes] = useState({});
  const [totalBio, setTotalBio] = useState(0);
  const [totalNonBio, setTotalNonBio] = useState(0);

  const mapKeysNames = {
    "millesime": "Année de récolte",
    "libelletypeproduction": "Type de production",
    "numerocontrat": "Contrat",
    "idrefvariete": "Code variété",
    "libellevariete": "Variété",
    "quantitesemence": "Quantité de semence",
    "quantitelivree": "Quantité livrée",
    "tonnage": "Tonnage de référence moyen",
    "tonnageestime": "Tonnage estimé de l'année",
    "nomsilo": "Silo de livraison",
    "nomsilodefinitif": "Silo définitif",
    "nomorganismestockeur": "Organisme stockeur",
    "nomparcelle": "Parcelle",
    "parcelle.codepostal": "Code postal parcelle",
    "parcelle.nomcommune": "Commmune parcelle",
    "surfaceengageeprintemps": "Surface engagée au printemps",
    "surfaceengageeautomne": "Surface engagée en automne",
    "surfaceprintemps": "Surface réelle printemps",
    "surfaceautomne": "Surface réelle automne",
    "surfacetotale": "Surface totale",
    "rendementbrut": "Rendement brut",
    "rendementnet": "Rendement net",
    "estconforme": "Conformité",
    "surveillancecolzasanve": "Surveillance (Colza/Sanve)",
    "libellestatutjuridique": "Statut juridique",
    "raisonsociale": "Exploitation",
    "siret": "Numéro SIRET",
    "adresse": "Adresse",
    "codepostal": "Code postal",
    "ville": "Commune",
    "codedc": "Code DC",
    "codeoctagri": "Code OCTAGRI",
    "codeos": "Code OS",
    "numerounilever": "Numéro UNILEVER",
    "rendementrefmoyen": "Rendement de référence moyen",
    "rendementrefmillesime": "Rendement estimé de l'année",
    "libellezoneproduction": "Zone de production",
  };

  useConstructor(() => {
    var osTemp = [...store.organismesStockeur];
    osTemp.unshift({
      idorganismestockeur: "-1",
      nomorganismestockeur: "--",
    });

    setOrganismesStockeur(osTemp);

    if (getQueryParam("idos") && getQueryParam("idos") !== "") {
      setIdOrganismeStockeur(getQueryParam("idos"));
    }
  });

  const renderOrganismesStockeurOption = () => {
    return organismesStockeur.map((organisme, key) => {
      return (
        <option value={organisme.idorganismestockeur} key={key}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  useEffect(() => {
    const loadDataSource = () => {
      let totPrintemps = 0;
      let totAutomne = 0;
      let totBio = 0;
      let totNonBio = 0;
      let surfacesVarietes = {};
      dataSource.forEach((element) => {
        totPrintemps += element.surfaceprintemps;
        totAutomne += element.surfaceautomne;
        if (element.idreftypeproduction === 4) {
          totBio +=
            parseFloat(element.surfaceprintemps) +
            parseFloat(element.surfaceautomne);
        } else {
          totNonBio +=
            parseFloat(element.surfaceprintemps) +
            parseFloat(element.surfaceautomne);
        }
        if (
          Object.prototype.hasOwnProperty.call(
            surfacesVarietes,
            element.libellevariete,
          )
        ) {
          surfacesVarietes[element.libellevariete] +=
            parseFloat(element.surfaceprintemps) +
            parseFloat(element.surfaceautomne);
        } else {
          surfacesVarietes[element.libellevariete] =
            parseFloat(element.surfaceprintemps) +
            parseFloat(element.surfaceautomne);
        }
      });
      setTotalPrintemps(totPrintemps.toFixed(2));
      setTotalAutomne(totAutomne.toFixed(2));
      setTotalBio(totBio.toFixed(2));
      setTotalNonBio(totNonBio.toFixed(2));
      setVarietes(surfacesVarietes);
    };

    setDataSource(store.planProduction.planProductionOrganismeStockeur);

    loadDataSource();
  }, [
    idOrganismeStockeur,
    dataSource,
    store.planProduction.planProductionOrganismeStockeur,
  ]);

  const getDataForCsv = () => {
    var temp = [...dataSource];
    const exploitationKeysToRemove = [
      "idexploitation",
      "listerouge",
      "abandon",
      "rendementref",
      "idstatutjuridique",
      "millesimeadhesion",
      "idcommune",
    ];
    const parcelleKeysToRemove = [
      "idparcelle",
      "idcommune",
      "idsilo",
      "idreftypesol",
      "libelletypesol",
      "surface",
    ];
    const planProductionKeyToRemove = [
      "exploitation",
      "parcelle",
      "ilot",
      "idcommune",
      "idilot",
      "idorganismestockeur",
      "idparcelle",
      "idplanproduction",
      "idreftypeproduction",
      "idsilo",
      "idsilodefinitif",
      "idcontrat",
    ];

    temp.forEach((data) => {
      if (data.exploitation != null) {
        exploitationKeysToRemove.forEach(
          (key) => delete data.exploitation[key],
        );
        Object.keys(data.exploitation).forEach((key) => {
          data[key] = data.exploitation[key];
        });
        parcelleKeysToRemove.forEach((key) => {
          if (data.parcelle && data.parcelle[key]) delete data.parcelle[key];
        });
        if (data.parcelle) {
          Object.keys(data.parcelle).forEach((key) => {
            switch (key) {
              case "codepostal":
                data["parcelle.codepostal"] = data.parcelle[key];
                break;
              case "nomcommune":
                data["parcelle.nomcommune"] = data.parcelle[key];
                break;
              default:
                data[key] = data.parcelle[key];
                break;
            }
          });
        }

        planProductionKeyToRemove.forEach((key) => delete data[key]);
      }
    });

    return temp;
  };

  const changePlanProductionOrganisme = async (idorganismestockeur) => {
    setLoading(true);
    if (idOrganismeStockeur === "-1") {
      store.planProduction.setPlanProductionOrganismeStockeur([]);
    } else {
      setOrganisme(
        organismesStockeur.find(
          (organismestockeur) =>
            organismestockeur.idorganismestockeur ===
            parseInt(idOrganismeStockeur),
        ),
      );

      const resPlanProductionOrganismeStockeur =
        await PlanProductionController.getPlanProductionPrevisionnelOrganismeStockeur(
          idorganismestockeur,
          store.millesime.idmillesime,
        );
      store.planProduction.setPlanProductionOrganismeStockeur(
        resPlanProductionOrganismeStockeur,
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    changePlanProductionOrganisme(idOrganismeStockeur);
  }, [idOrganismeStockeur]);

  const onCellClick = (params) => {
    navigator.clipboard.writeText(params.value);
    createNotification(
      "info",
      "Information",
      params.colDef.headerName + " copié dans le presse-papier",
      3000,
    );
  };

  const renderLoading = () => {
    return <SpinLoadingAnimation />;
  };

  const renderSurfacesVarietes = () => {
    const libelles = Object.keys(varietes);
    return libelles.map((libellevariete, i) => {
      return (
        <Fragment key={i}>
          Total {libellevariete != "null" ? libellevariete : "sans variété"} :{" "}
          {varietes[libellevariete].toFixed(2)} Ha{" "}
          {i + 1 == libelles.length ? "" : "| "}
        </Fragment>
      );
    });
  };

  const renderFooter = () => {
    return (
      <RenderIf isTrue={idOrganismeStockeur !== "-1" && !loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
          }}
        >
          Total printemps : {totalPrintemps} Ha | Total automne : {totalAutomne}{" "}
          Ha | Total :{" "}
          {(parseFloat(totalAutomne) + parseFloat(totalPrintemps)).toFixed(2)}{" "}
          Ha
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
          }}
        >
          Total bio : {totalBio} Ha | Total non bio : {totalNonBio} Ha
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
            marginTop: 10,
          }}
        >
          {renderSurfacesVarietes()}
        </div>
      </RenderIf>
    );
  };

  const renderGridOrganisme = () => {
    return (
      <Row>
        <h4>
          <b>
            {idOrganismeStockeur !== "-1"
              ? organisme.nomorganismestockeur
              : "Veuillez sélectionner un organisme stockeur"}
          </b>
        </h4>
        {loading && renderLoading()}
        {idOrganismeStockeur !== "-1" && !loading && (
          <Col md="12">
            <AgGrid
              columnDefs={columns}
              rowData={unflattenList(dataSource)}
              showFilterLine
              height={700}
              onCellClicked={onCellClick}
            />
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>
              Plan de production par organisme stockeur
            </CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={dataSource.length > 0}>
              <CsvButton
                color="primary"
                style={{ marginRight: 30 }}
                onClick={() => {
                  downloadCsv(
                    convertToCSV(getDataForCsv(), ";", mapKeysNames),
                    "plan_prod_os_" +
                      store.millesime.idmillesime +
                      "_" +
                      moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
            <CloseButton onClick={props.close} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="4">
            <Label for="idorganismestockeur">
              Sélectionnez l'organisme stockeur
            </Label>
            <Input
              type="select"
              className="select-input"
              name="idorganismestockeur"
              value={idOrganismeStockeur}
              onChange={(event) => {
                setQueryParam("idos", event.target.value);
                setIdOrganismeStockeur(event.target.value);
              }}
            >
              {renderOrganismesStockeurOption()}
            </Input>
          </Col>
        </Row>
        <hr />
        {renderGridOrganisme()}
        {renderFooter()}
      </CardBody>
    </Card>
  );
}
