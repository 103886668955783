export const columns = [
  {
    headerName: "Numéro de contrat",
    field: "numerocontrat",
  },
  {
    headerName: "Date du contrat",
    field: "datecontrat",
  },
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Adresse",
    field: "adresse",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Mail",
    field: "mail",
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
  },
  {
    headerName: "Code DC",
    field: "codedc",
  },
  {
    headerName: "Variétés",
    children: [],
  },
];
