import { useState, useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import ProfilController from "../../config/apiUtils/ProfilController";
import UtilisateurController from "../../config/apiUtils/UtilisateurController";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import CloseButton from "../../components/Buttons/CloseButton";
import CommuneInput from "../../components/CommuneInput";
import { createNotification, hash, RenderIf } from "../../config/utils";

export default function FormNewUtilisateur(props) {
  const store = useContext(StoreContext);
  const [idorganismestockeur, setIdorganismestockeur] = useState(-1);
  const [idprofil, setIdprofil] = useState(-1);
  const [organismes, setOrganismes] = useState([]);
  const [estorganismestockeur, setEstorganismestockeur] = useState(false);

  useConstructor(async () => {
    store.profils.setProfils(await ProfilController.getProfils());
    setOrganismes(await OrganismeStockeurController.getOrganismesStockeur());
  });

  const onSubmitUtilisateur = async (event) => {
    event.preventDefault();
    if (event.target.password.value !== event.target.repeatedpassword.value) {
      createNotification(
        "error",
        "Erreur",
        "Les mots de passes ne correspondent pas",
      );
    } else {
      const utilisateur = {
        nom: event.target.nom.value,
        prenom: event.target.prenom.value,
        mail: event.target.mail.value,
        username: event.target.mail.value,
        password: hash(event.target.password.value),
        telephone: event.target.telephone.value,
        profils: [event.target.profil.value],
        adresse: event.target.adresse.value,
        idorganismestockeurs: estorganismestockeur
          ? [event.target.idorganismestockeur.value]
          : [],
        idcommune: event.target.idcommune.value,
        pays: "France",
      };
      const resUser = await UtilisateurController.createUser(utilisateur);
      props.hideFormNewUtilisateur();
      if (resUser.status === 200) {
        props.onSuccessNewUtilisateur();
      } else {
        props.onErrorNewUtilisateur();
      }
    }
  };

  const renderProfilsOptions = () => {
    return store.profils.profils.map((profil, key) => {
      return (
        <option value={profil.idprofil} key={key}>
          {profil.libelleprofil}
        </option>
      );
    });
  };

  const renderOrganismesStockeursOptions = () => {
    return organismes.map((organisme, key) => {
      return (
        <option value={organisme.idorganismestockeur} key={key}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  useEffect(() => {
    const profil = store.profils.profils.find(
      (profil) => profil.idprofil == idprofil,
    );
    if (profil) {
      setEstorganismestockeur(profil.estorganismestockeur);
    }
  }, [idprofil]);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Nouvel utilisateur</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseButton onClick={props.hideFormNewUtilisateur} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmitUtilisateur}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="nom">Nom</Label>
                <Input
                  type="text"
                  name="nom"
                  placeholder="Nom"
                  required
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="nom">Prénom</Label>
                <Input
                  type="text"
                  name="prenom"
                  placeholder="Prénom"
                  required
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="nom">Adresse</Label>
                <Input
                  type="text"
                  name="adresse"
                  placeholder="Adresse"
                  required
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <CommuneInput required />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="mail">Adresse mail</Label>
                <Input
                  type="mail"
                  name="mail"
                  placeholder="Adresse mail"
                  required
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="telephone">Téléphone</Label>
                <Input
                  type="text"
                  name="telephone"
                  placeholder="Téléphone"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="password">Mot de passe</Label>
                <Input
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*?[0-9])(?=.*?[~`!@#$%\^&*()\-_=+[\]{};:\x27.,\x22\\|/?><]).{8,}"
                  required
                  title="8 caractères minimum, au moins une minuscule, une majuscule, un chiffre et un caractère spécial"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="repeatedpassword">Répétez le mot de passe</Label>
                <Input
                  type="password"
                  name="repeatedpassword"
                  placeholder="Répétez le mot de passe"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*?[0-9])(?=.*?[~`!@#$%\^&*()\-_=+[\]{};:\x27.,\x22\\|/?><]).{8,}"
                  required
                  title="8 caractères minimum, au moins une minuscule, une majuscule, un chiffre et un caractère spécial"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={estorganismestockeur ? "6" : "12"}>
              <FormGroup>
                <Label for="profil">Profil utilisateur</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="profil"
                  onChange={(event) => setIdprofil(event.target.value)}
                >
                  {renderProfilsOptions()}
                </Input>
              </FormGroup>
            </Col>
            <RenderIf isTrue={estorganismestockeur}>
              <Col md={"6"}>
                <FormGroup>
                  <Label for="idorganismestockeur">Organisme stockeur</Label>
                  <Input
                    type="select"
                    className="select-input"
                    value={idorganismestockeur}
                    onChange={(event) =>
                      setIdorganismestockeur(event.target.value)
                    }
                    name="idorganismestockeur"
                  >
                    {renderOrganismesStockeursOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </RenderIf>
          </Row>
          <Row>
            <Col md="6">
              <Button
                style={{ marginTop: 15, marginRight: 15 }}
                color="primary"
                type="submit"
              >
                Valider
              </Button>
              <Button
                style={{ marginTop: 15 }}
                color="danger"
                onClick={(event) => {
                  event.preventDefault();
                  props.hideFormNewUtilisateur();
                }}
              >
                Annuler
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
