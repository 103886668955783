import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { columns } from "../../components/Grids/Producteur/formation";
import StoreContext from "../../context/StoreContext";
import { RenderIf } from "../../config/utils";
import AgGrid from "../../components/AgGrid";

export default function FormationProducteur(props) {
  const store = useContext(StoreContext);

  const onRowClick = async (params) => {
    props.onSelectFormation(params.data);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Formations</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={store.utilisateur.hasDroits("GESTION_FORMATION", 40)}
              >
                <Button
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={props.showFormNewFormation}
                >
                  Nouvelle formation
                </Button>
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              <AgGrid
                columnDefs={columns}
                rowData={store.formations.formations}
                onRowClicked={onRowClick}
                height={300}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
