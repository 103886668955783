export const columns = [
  {
    headerName: "Exploitation",
    children: [
      {
        headerName: "Raison sociale",
        field: "raisonsociale",
      },
      {
        headerName: "Code DC",
        field: "codedc",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Commune",
    children: [
      {
        headerName: "Commune",
        columnGroupShow: "closed",
        valueGetter: (params) =>
          `${params.data.codepostal} ${params.data.nomcommune}`,
      },
      {
        headerName: "Code postal",
        field: "codepostal",
        columnGroupShow: "open",
      },
      {
        headerName: "Commune",
        field: "nomcommune",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Coordonnées",
    children: [
      {
        headerName: "Mail",
        field: "mail",
      },
      {
        headerName: "Téléphone portable",
        field: "telephoneportable",
      },
      {
        headerName: "Téléphone fixe",
        field: "telephonefixe",
      },
    ],
  },
  {
    headerName: "Organisme",
    field: "nomorganismestockeur",
  },
];
