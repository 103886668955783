import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Mailto } from "../../config/utils";

export default function Contact(): React.JSX.Element {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3">Contacts</CardTitle>
      </CardHeader>
      <CardBody>
        <p>Pour toute question, vous pouvez contacter :</p>
        <div className="row">
          <div className="col">
            <p style={{ textAlign: "center" }}>
              Damien Baumont et Laure OHLEYER
              <br />
              <i>Responsable APGMB</i>
              <br />
              <script type="text/javascript">
                cryptm("apgmb.bourgogne21","gmail","com")
              </script>
              <Mailto
                style={{ textDecoration: "none", color: "#f9aa33" }}
                email="apgmb.bourgogne21@gmail.com"
                subject="Contact Ecodim"
              >
                apgmb.bourgogne21@gmail.com
              </Mailto>
            </p>
          </div>
          <div className="col">
            <p style={{ textAlign: "center" }}>
              Alexandre BILLON <br />
              <i>Contacts techniques</i>
              <br />(
              <a
                style={{ textDecoration: "none", color: "#f9aa33" }}
                href="https://www.billonprestations.fr/"
                target="_blank"
                rel="noreferrer"
              >
                <i>Billon Prestations</i>
              </a>
              )
              <br />
              <Mailto
                style={{ textDecoration: "none", color: "#f9aa33" }}
                email="alexandre@billonprestations.fr"
                subject="Contact Ecodim"
              >
                alexandre@billonprestations.fr
              </Mailto>
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
