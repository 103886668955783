import { useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import FichierController from "../../config/apiUtils/FichierController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import CloseButton from "../../components/Buttons/CloseButton";
import useConstructor from "../../config/hooks/useConstructor";
import { createNotification, RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";

export default function FormNewFichier(props) {
  const fileInputRef = useRef(null); // Référence pour l'input file
  const [typesDocuments, setTypesDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxMo = 100;
  const maxSize = 1024 * 1024 * maxMo; // 100MB en octets

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const file = event.target.file.files[0];
    let formData = new FormData();
    formData.append("file", file);
    const res = await FichierController.uploadDocument(
      event.target.idreftypedocument.value,
      formData,
    );
    if (res.status === 200) {
      createNotification("success", "Succès", "Fichier créé !");
      await props.loadDocuments();
      setLoading(false);
      props.hideFormNewFichier();
    }
  };

  useConstructor(async () => {
    setTypesDocuments(await ReferentielController.getTypesDocument());
  });

  const renderTypesDocumentsOptions = () => {
    return typesDocuments.map((type, key) => {
      return (
        <option key={key} value={type.idreftypedocument}>
          {type.libelle}
        </option>
      );
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > maxSize) {
      createNotification(
        "error",
        `Le fichier est trop volumineux. Taille maximale : ${maxMo}Mo.`,
      );
      event.target.value = "";
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Nouveau document</CardTitle>
        <CloseButton onClick={props.hideFormNewFichier} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md="4">
                <Label for="idreftypedocument">Type de document</Label>
                <Input
                  type="select"
                  name="idreftypedocument"
                  className="select-input"
                >
                  {renderTypesDocumentsOptions()}
                </Input>
              </Col>
              <Col md="8">
                <FormGroup style={{ position: "relative", display: "grid" }}>
                  <Label for="file">Nouveau document</Label>
                  <Input
                    type="file"
                    name="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <FormText className={"formtext-input-file"}>
                    Sur cet espace, une limitation de 2 Go de stockage global
                    s'applique, avec une taille maximale de 50 Mo par fichier.
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Button
                  style={{ marginTop: 15, marginRight: 15 }}
                  color="primary"
                  type="submit"
                >
                  Valider
                </Button>
                <Button
                  style={{ marginTop: 15 }}
                  color="danger"
                  onClick={(event) => {
                    event.preventDefault();
                    props.hideFormNewFichier();
                  }}
                >
                  Annuler
                </Button>
              </Col>
            </Row>
          </Form>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
