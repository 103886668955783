import React, { useCallback, useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { columns } from "../../components/Grids/CommandeIndustriel/commandes";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import droits from "../../config/CodesDroits";
import StoreContext from "../../context/StoreContext";
import AgGrid from "../../components/AgGrid";
import { RowClickedEvent } from "ag-grid-community";
import { ICommande } from "src/config/types/commande";

interface IProps {
  loading: boolean;
  commandes: ICommande[];
  onSelectCommande: ((data: ICommande) => void) | undefined;
}

export default function TableCommandeIndustrielle(
  props: IProps,
): React.JSX.Element {
  const store = useContext(StoreContext);

  const onRowClicked = useCallback(
    async (event: RowClickedEvent) => {
      if (props.onSelectCommande) {
        props.onSelectCommande(event.data);
      }
    },
    [props.commandes],
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>
          Récapitulatif des commandes industrielles
        </CardTitle>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf
          isTrue={
            !props.loading &&
            props.commandes !== null &&
            props.commandes !== undefined
          }
        >
          <AgGrid
            rowData={props.commandes}
            columnDefs={columns}
            onRowClicked={
              store.utilisateur.hasDroits(
                droits.typesdroits.GESTION_COMMANDE,
                droits.modalite.modification,
              )
                ? onRowClicked
                : undefined
            }
            height={350}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
