import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Numéro de contrat",
    field: "numerocontrat",
  },
  {
    headerName: "Dates",
    children: [
      {
        headerName: "Date du contrat",
        field: "datecontrat",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
      },
      {
        headerName: "Date d'envoi",
        field: "dateenvoi",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
      },
      {
        headerName: "Date de retour",
        field: "dateretour",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
      },
    ],
  },
  {
    headerName: "Convention signée",
    field: "signee",
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Mail",
    field: "mail",
  },
];
