import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Typography } from "@mui/material";
import VersionController from "../config/apiUtils/VersionController";
import useConstructor from "../config/hooks/useConstructor";
import React, { useContext, useState } from "react";
import StoreContext from "../context/StoreContext";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import { IDescriptionVersion, IVersion } from "../config/types/version";

export default function Version(): React.JSX.Element {
  const [versions, setVersions] = useState<IVersion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const store = useContext(StoreContext);

  useConstructor(async () => {
    setLoading(true);
    const resVersions = await VersionController.getVersions();
    setVersions(resVersions);
    store.versions.setVersions(resVersions);
    setLoading(false);
  });

  const renderVersionChanges = (changes: IDescriptionVersion[] | null) => {
    if (changes === null) {
      return <></>;
    }
    return changes.map((item: IDescriptionVersion, key: number) => (
      <Typography key={key}>{item.detail}</Typography>
    ));
  };

  const renderVersionItem = (item: IVersion, key: number) => {
    return (
      <TimelineItem key={key}>
        <TimelineSeparator>
          <TimelineDot color="success" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <h3>{item.numeroversion}</h3>
          {renderVersionChanges(item.description)}
        </TimelineContent>
      </TimelineItem>
    );
  };

  const renderVersion = () => {
    return versions.map((item, key) => renderVersionItem(item, key));
  };

  const renderEmptyDot = () => {
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="success" />
        </TimelineSeparator>
        <TimelineContent>
          <Typography></Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Historique des versions</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <small>{window.env.BUILD_VERSION}</small>
        </Col>
      </Row>
      {loading && <SpinLoadingAnimation />}
      {versions.length > 0 && !loading && (
        <Row
          style={{ marginTop: 30, display: "flex", justifyContent: "center" }}
        >
          <Col md="8">
            <Card>
              <CardBody>
                <Timeline position="alternate">
                  {renderVersion()}
                  {renderEmptyDot()}
                </Timeline>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}
