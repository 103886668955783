import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { culturesState } from "../../config/atoms/referentiels";
import useConstructor from "../../config/hooks/useConstructor";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import AgGrid from "../../components/AgGrid";
import { ICulture } from "../../config/types/referentiel";

const columns = [
  {
    headerName: "Groupe de culture",
    field: "libellegroupeculture",
  },
  {
    headerName: "Culture",
    field: "libelle",
  },
  {
    headerName: "Code culture",
    field: "codeculture",
  },
  {
    headerName: "Année de début",
    field: "millesimedebut",
  },
  {
    headerName: "Année de fin",
    field: "millesimefin",
  },
];

export default function Cultures(): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [cultures, setCultures] = useRecoilState<ICulture[]>(culturesState);

  useConstructor(async () => {
    setLoading(true);
    setCultures(await ReferentielController.getCultures());
    setLoading(false);
  });

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Cultures</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {cultures !== null && cultures !== undefined && !loading && (
              <AgGrid columnDefs={columns} rowData={cultures} showFilterLine />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
