import React, { useEffect, useRef, useState } from "react";
import ExploitationController from "src/config/apiUtils/ExploitationController";
import MillesimeController from "src/config/apiUtils/MillesimeController";
import FormationController from "src/config/apiUtils/FormationController";
import UtilisateurController from "src/config/apiUtils/UtilisateurController";
import AuthService from "src/config/AuthService";
import useDeviceDetect from "src/config/hooks/useDeviceDetect";
import StoreContext from "src/context/StoreContext";
import {
  createNotification,
  deleteQueryParam,
  getQueryParam,
} from "src/config/utils";
import Layer from "src/config/carto/Layer";
import { getExtentFeatures, getLayerById } from "src/config/carto/utils";
import * as olEasing from "ol/easing";
import CartoController from "src/config/apiUtils/CartoController";
import GeoJSON from "ol/format/GeoJSON";
import { ICivilite } from "src/config/types/civilite";
import { ICommune } from "src/config/types/commune";
import { ICommande } from "src/config/types/commande";
import {
  ICulture,
  IEtatValidationAdministrative,
  IMateriel,
  IProduit,
  IStatutJuridique,
  ITypeDocument,
  ITypeFormation,
  ITypeIntervention,
  ITypeMateriel,
  ITypeProduction,
  ITypeProduit,
  ITypeSilo,
  ITypeSol,
  ITypeVariete,
  IVariete,
} from "src/config/types/referentiel";
import { IMatiereActive } from "src/config/types/matiereproduit";
import { IDroit, IProfil } from "src/config/types/profil";
import { IMillesime } from "src/config/types/millesime";
import {
  IBilanRecolte,
  IPlanProduction,
} from "src/config/types/planproduction";
import { IConvention } from "src/config/types/convention";
import { IContrat } from "src/config/types/contrat";
import { IParcelle } from "src/config/types/parcelle";
import { IProducteur } from "src/config/types/producteur";
import { ISurfacePotentielle } from "src/config/types/surfacepotentielle";
import { IExploitation } from "src/config/types/exploitation";
import { ICertificationIgp } from "src/config/types/certificationigp";
import { ICertificationExploitation } from "src/config/types/certificationexploitation";
import { IUserInfo } from "src/config/types/user";
import { IOrganismeStockeur } from "src/config/types/organismestockeur";
import { ILayer } from "src/config/types/carto/layer";
import { IFeature } from "src/config/types/geojson";
import { FeatureType } from "ol/format/WFS";
import { FitOptions } from "ol/View";

interface IProps {
  children: React.JSX.Element;
}

export default function StoreProvider(props: IProps): React.JSX.Element {
  // Initialisation des states

  const [loading, setLoading] = useState<boolean>(true);

  /* CERTIFICATIONS */

  const [certificationsIgp, setCertificationsIgp] = useState<
    ICertificationIgp[]
  >([]);
  const [certificationsExploitation, setCertificationsExploitation] = useState<
    ICertificationExploitation[]
  >([]);

  const [civilites, setCivilites] = useState<ICivilite[]>([]);

  const [droits, setDroits] = useState<IDroit[]>([]);
  const [codesDroit, setCodesDroit] = useState<string[]>([]);
  const [profils, setProfils] = useState<IProfil[]>([]);

  const [communes, setCommunes] = useState<ICommune[]>([]);

  const [commandes, setCommandes] = useState<ICommande[]>([]);

  const [contrats, setContrats] = useState<IContrat[]>([]);
  const [contratsExploitation, setContratsExploitation] = useState<IContrat[]>(
    [],
  );

  const [conventions, setConventions] = useState<IConvention[]>([]);

  const [details, setDetails] = useState<IPlanProduction[]>([]);

  const [exploitation, setExploitation] = useState<IExploitation | null>(null);
  const [exploitations, setExploitations] = useState<IExploitation[]>([]);

  const [formations, setFormations] = useState([]);
  const [formationsMillesime, setFormationsMillesime] = useState([]);

  const [millesimeCourant, setMillesimeCourant] = useState({});
  const [millesime, setMillesime] = useState<IMillesime>({
    idmillesime: null,
  });
  const [millesimes, setMillesimes] = useState<IMillesime[]>([]);

  const [nbParcelles, setNbParcelles] = useState<number>(0);
  const [nomUtilisateur, setNomUtilisateur] = useState<string>("");

  const [organismesStockeur, setOrganismesStockeur] = useState<
    IOrganismeStockeur[]
  >([]);

  const [parcelles, setParcelles] = useState<IParcelle[]>([]);
  const [parcellesExploitation, setParcellesExploitation] = useState<
    IParcelle[]
  >([]);

  const [zonesProductionExploitation, setZonesProductionExploitation] =
    useState([]);

  /* PLAN DE PRODUCTION */

  const [planProductionParcelle, setPlanProductionParcelle] = useState<
    IPlanProduction[]
  >([]);
  const [planProductionPrevisionnel, setPlanProductionPrevisionnel] = useState<
    IPlanProduction[]
  >([]);
  const [planProductionOrganismeStockeur, setPlanProductionOrganismeStockeur] =
    useState<IPlanProduction[]>([]);

  const [bilanRecolteExploitation, setBilanRecolteExploitation] = useState<
    IBilanRecolte[]
  >([]);

  const [producteurs, setProducteurs] = useState<IProducteur[]>([]);
  const [producteursMillesime, setProducteursMillesime] = useState([]);
  const [profilsUtilisateur, setProfilsUtilisateur] = useState<IProfil[]>([]);

  const [utilisateur, setUtilisateur] = useState<IUserInfo | null>(null);

  const [zonesProduction, setZonesProduction] = useState([]);

  const [versions, setVersions] = useState([]);

  const [surfacesPotentielles, setSurfacesPotentielles] = useState<
    ISurfacePotentielle[]
  >([]);

  const [silos, setSilos] = useState([]);

  /* STATES DES REFERENTIELS */

  const [cultures, setCultures] = useState<ICulture[]>([]);
  const [typesProduits, setTypesProduits] = useState<ITypeProduit[]>([]);
  const [produits, setProduits] = useState<IProduit[]>([]);
  const [typesMateriels, setTypesMateriels] = useState<ITypeMateriel[]>([]);
  const [materiels, setMateriels] = useState<IMateriel[]>([]);
  const [typesInterventions, setTypesInterventions] = useState<
    ITypeIntervention[]
  >([]);
  const [typesDocuments, setTypesDocuments] = useState<ITypeDocument[]>([]);
  const [typesFormations, setTypesFormations] = useState<ITypeFormation[]>([]);
  const [typesProduction, setTypesProduction] = useState<ITypeProduction[]>([]);
  const [etatsValidationAdministrative, setEtatsValidationAdministrative] =
    useState<IEtatValidationAdministrative[]>([]);
  const [typesVarietes, setTypesVarietes] = useState<ITypeVariete[]>([]);
  const [varietes, setVarietes] = useState<IVariete[]>([]);
  const [statutsJuridique, setStatutsJuridique] = useState<IStatutJuridique[]>(
    [],
  );
  const [matieresActives, setMatieresActives] = useState<IMatiereActive[]>([]);
  const [typesSol, setTypesSol] = useState<ITypeSol[]>([]);
  const [typesSilo, setTypesSilo] = useState<ITypeSilo[]>([]);

  /* CARTOGRAPHIE */

  const [geometries, setGeometries] = useState([]);

  const [layers, setLayers] = useState<Layer[]>([]);
  const [geolocationEnabled, setGeolocationEnabled] = useState(false);

  const [parcellaire, setParcellaire] = useState<IParcelle[]>([]);
  const [parcelleSelected, setParcelleSelected] = useState(null);

  const { isMobile } = useDeviceDetect();

  const mapRef = useRef(null);
  const parcelleAssocieeSelecterRef = useRef(null);

  const getLayer = (codeCouche: string) => {
    return layers.find(
      (couche: Layer) => couche.getCodeCouche() === codeCouche,
    );
  };

  const getParcellaireLayer = () => {
    return getLayer(Layer.codeCouche.PARCELLAIRE_EXPLOITATION);
  };

  const getRpgLayer = () => {
    return getLayer(Layer.codeCouche.RPG_ANONYME);
  };

  const toggleGeolocation = (active: boolean) => {
    setGeolocationEnabled(active);
    // @ts-ignore
    getLayerById(mapRef, "GEOLOCATION").setVisible(active);
  };

  const zoomMap = (
    features: Array<FeatureType>,
    options: FitOptions,
    ref = mapRef,
  ) => {
    const interval = setInterval(() => {
      if (ref.current != null) {
        // @ts-ignore
        ref.current.getView().fit(
          getExtentFeatures(features),
          options ?? {
            duration: 2000,
            easing: olEasing.easeOut,
            padding: [10, 10, 10, 10],
          },
        );
        clearInterval(interval); // this line is to stop interval once mapRef.current is defined
      }
    }, 100);
  };

  const zoomLocation = () => {
    if (
      // @ts-ignore
      getLayerById(mapRef, "GEOLOCATION")
        .getSource()
        .getFeatures()[0]
        .getGeometry()
    ) {
      // @ts-ignore
      zoomMap(getLayerById(mapRef, "GEOLOCATION").getSource().getFeatures(), {
        duration: 2000,
        easing: olEasing.easeOut,
        padding: [10, 10, 10, 10],
        maxZoom: 18,
      });
    } else {
      createNotification(
        "info",
        "Localisation en cours de récupération, veuillez patienter...",
      );
    }
  };

  const zoomEmprise = () => {
    // @ts-ignore
    zoomMap(getParcellaireLayer().getFeatures());
  };

  const loadFicheExploitation = async (millesime: number) => {
    const fiche = await ExploitationController.getFicheExploitation(millesime);
    if (fiche) {
      setExploitation(fiche.exploitation);
      setConventions(fiche.conventions);
      setContratsExploitation(fiche.contrats);
      setParcellesExploitation(fiche.parcelles);
      setProducteurs(fiche.producteurs);
      setSurfacesPotentielles(fiche.surfaces);
      return fiche;
    }
    return null;
  };

  const loadDernierDossier = async () => {
    const resDernierDossier =
      await ExploitationController.getDernierDossierUtilisateur();
    setExploitation(resDernierDossier);
    return resDernierDossier;
  };

  const isLoaded = () =>
    nomUtilisateur !== "" &&
    millesimes.length > 0 &&
    codesDroit !== null &&
    exploitation !== null &&
    !loading;

  const updateFormationsMillesime = async () => {
    const resFormations = await FormationController.getFormations(
      millesime.idmillesime,
    );
    setFormationsMillesime(resFormations);
  };

  const hasDroits = (codetypedroit: string, iddroitmodalite: number) => {
    return droits.some(
      (droit: IDroit) =>
        droit.codetypedroit === codetypedroit &&
        droit.iddroitmodalite >= iddroitmodalite,
    );
  };

  const loadData = async (silent = true) => {
    if (!silent) {
      setLoading(true);
    }
    const resProfils = await UtilisateurController.getProfilsUtilisateur();
    localStorage.removeItem("profils");
    localStorage.setItem("profils", JSON.stringify(resProfils));
    setProfilsUtilisateur(resProfils);

    const resDroits = await UtilisateurController.getDroitsUtilisateur();
    localStorage.removeItem("droits");
    localStorage.setItem("droits", JSON.stringify(resDroits));
    setDroits(resDroits);

    MillesimeController.getMillesimes().then((res) => {
      setMillesimes(res);
    });

    const millesimeUtilisateur =
      await MillesimeController.getDernierMillesimeUtilisateur();
    setMillesime(millesimeUtilisateur);

    const resUtilisateur = await UtilisateurController.getUserInfo();
    setNomUtilisateur(resUtilisateur.prenom + " " + resUtilisateur.nom);

    if (resUtilisateur.isProducteur) {
      setMillesimeCourant(await MillesimeController.getMillesimeCourant());
    }

    const resFiche = await loadFicheExploitation(
      millesimeUtilisateur.idmillesime,
    );

    if (resFiche !== null) {
      if (getQueryParam("loggedin") == "true") {
        createNotification("success", "Succès", "Connecté avec succès");
        deleteQueryParam("loggedin");

        if (resUtilisateur.isProducteur) {
          if (resFiche.contrats.length === 0) {
            createNotification(
              "warning",
              "Contrat manquant",
              "Vous n'avez pas de contrat saisi pour la campagne 2024.",
              10000,
            );
          }
          if (resFiche.surfaces.length === 0) {
            createNotification(
              "warning",
              "Intention de semis manquante",
              "Vous n'avez pas d'intention de semis saisie pour la campagne 2024.",
              10000,
            );
          }
        }
      }
      setCodesDroit(resDroits.map((droit: IDroit) => droit.codetypedroit));
      setUtilisateur(resUtilisateur);
    }

    setLoading(false);
  };

  const loadDataParcellaireCarto = async (force: () => void) => {
    if (millesime.idmillesime === null || exploitation === null) {
      return;
    }

    const tmpLayers = await CartoController.getLayersCatalogue(
      Layer.catalogue.PARCELLAIRE_EXPLOITATION,
    );

    const tmp = tmpLayers.map(
      (layer: ILayer) => new Layer(layer, mapRef, force),
    );

    const parcellaire =
      await ExploitationController.getParcellesByIdexploitation(
        exploitation.idexploitation,
        millesime.idmillesime,
      );

    const features = parcellaire
      .filter((parcelle: IParcelle) => parcelle.geometrie != null)
      .map((parcelle: IParcelle) => ({
        ...parcelle.geometrie,
      }))
      .map((geom: IFeature | null) => {
        return new GeoJSON().readFeatures(geom)[0];
      });
    tmp
      .find(
        (couche: Layer) =>
          couche.getCodeCouche() === Layer.codeCouche.PARCELLAIRE_EXPLOITATION,
      )
      .addFeatures(features);

    setLayers([...tmp]);
    setParcellaire([...parcellaire]);
  };

  // Récupération des données au lancement de l'application
  useEffect(() => {
    const load = async () => {
      try {
        if (AuthService.isLoggedIn()) {
          await loadData();
        }
      } catch (e) {
        console.warn(e);
        createNotification("error", "Erreur", "Une erreur s'est produite");
      }
    };
    load();
  }, []);

  const context = {
    loadData,
    isLoaded,

    userDevice: {
      isMobile,
    },

    carto: {
      mapRef,
      parcelleAssocieeSelecterRef,

      layers,
      setLayers,

      parcellaire,
      setParcellaire,

      parcelleSelected,
      setParcelleSelected,

      geolocationEnabled,
      setGeolocationEnabled,

      getLayer,
      getParcellaireLayer,
      getRpgLayer,

      toggleGeolocation,

      zoomMap,
      zoomLocation,
      zoomEmprise,

      loadDataParcellaireCarto,

      typeLayers: {
        WMS: 1,
        TMS: 2,
        WMTS: 3,
        WMS_VECTOR: 4,
        METIER: 5,
        WFS: 6,
        GEOJSON: 7,
        OSM: 8,
      },

      urlCarto: {
        pac: {
          url: "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM&STYLE=normal&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&FORMAT=image/jpeg",
          libelle: "Fond carto PAC",
          attribution: "attributions",
        },
        ign: {
          url: "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM&STYLE=normal&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&FORMAT=image/png",
          libelle: "Fond carto IGN",
          attribution: "attributions",
        },
        osm: {
          url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
          libelle: "Fond carto OSM",
          attribution: "attributions",
        },
        mtbmap: {
          url: "http://tile.mtbmap.cz/mtbmap_tiles/{z}/{x}/{y}.png",
          libelle: "Fond carto mtbmap",
          attribution: "attributions",
        },
      },
    },

    utilisateur: {
      codesDroit,
      droits,
      informations: utilisateur,
      setUtilisateur,
      nomUtilisateur,
      profilsUtilisateur,
      hasDroits,
    },

    certifications: {
      certificationsIgp,
      setCertificationsIgp,

      certificationsExploitation,
      setCertificationsExploitation,
    },

    commandes,
    setCommandes,

    communes,
    setCommunes,

    civilites,
    setCivilites,

    surfacesPotentielles,
    setSurfacesPotentielles,

    silos,
    setSilos,

    millesimes,
    setMillesimes,

    millesime,
    setMillesime,

    millesimeCourant,

    parcelles,
    setParcelles,

    geometries,
    setGeometries,

    producteursMillesime,
    setProducteursMillesime,

    formations: {
      formations,
      setFormations,

      formationsMillesime,
      setFormationsMillesime,
      updateFormationsMillesime,
    },

    exploitations,
    setExploitations,

    exploitation: {
      informations: exploitation,
      setExploitation,

      loadDernierDossier,

      zonesProduction: zonesProductionExploitation,
      setZonesProduction: setZonesProductionExploitation,

      producteurs,
      setProducteurs,

      parcellesExploitation,
      setParcellesExploitation,
    },

    planProduction: {
      planProductionParcelle,
      setPlanProductionParcelle,

      planProductionPrevisionnel,
      setPlanProductionPrevisionnel,

      planProductionOrganismeStockeur,
      setPlanProductionOrganismeStockeur,

      bilanRecolteExploitation,
      setBilanRecolteExploitation,
    },

    profils: {
      profils,
      setProfils,
    },

    zonesProduction,
    setZonesProduction,

    contrats,
    setContrats,

    contratsExploitation,
    setContratsExploitation,

    detailContratsExploitation: {
      nbParcelles,
      setNbParcelles,
      details,
      setDetails,
    },

    organismesStockeur,
    setOrganismesStockeur,

    conventions,
    setConventions,

    referentiels: {
      etatsValidationAdministrative,
      setEtatsValidationAdministrative,

      cultures,
      setCultures,

      materiels,
      setMateriels,

      produits,
      setProduits,

      matieresActives,
      setMatieresActives,

      typesDocuments,
      setTypesDocuments,

      typesFormations,
      setTypesFormations,

      typesInterventions,
      setTypesInterventions,

      typesMateriels,
      setTypesMateriels,

      typesProduits,
      setTypesProduits,

      typesProduction,
      setTypesProduction,

      typesVarietes,
      setTypesVarietes,

      typesSol,
      setTypesSol,

      typesSilo,
      setTypesSilo,

      varietes,
      setVarietes,

      statutsJuridique,
      setStatutsJuridique,
    },

    versions: {
      versions,
      setVersions,
    },
  };

  return (
    <StoreContext.Provider value={context}>
      {props.children}
    </StoreContext.Provider>
  );
}
