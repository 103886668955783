import APIController from "../APIController";
import {
  IChangePasswordPOST,
  IUser,
  IUserInfo,
  IUserInfoPUT,
  IUserPOST,
  IUserPUT,
  IUtilisateurFormulairePOST,
  IUtilisateurFormulairePUT,
} from "../types/user";
import { IDroit, IProfil } from "../types/profil";
import { IExploitation } from "../types/exploitation";

class UtilisateurController extends APIController {
  constructor() {
    super("");
  }

  async getUserInfo(): Promise<IUserInfo> {
    return await this.getRequest("/users/me");
  }

  async getUtilisateurs(): Promise<IUserInfo[]> {
    return await this.getRequest("/utilisateur");
  }

  async getDroitsUtilisateur(): Promise<IDroit[]> {
    return await this.getRequest("/profil/droits/utilisateur");
  }

  async getAccesUtilisateur(idutilisateur: number): Promise<IExploitation[]> {
    return await this.getRequest(`/utilisateur/${idutilisateur}/acces`);
  }

  async getProfilsUtilisateur(): Promise<IProfil[]> {
    return await this.getRequest("/profil/utilisateur/");
  }

  async isRespoOSDijonCereales() {
    const res = (await this.getProfilsUtilisateur())[0];
    return res.codeprofil === "RESPO_OS" && res.idorganismestockeur === 3;
  }

  async createUser(utilisateur: IUserPOST): Promise<IUser> {
    return await this.postRequest("/utilisateur", utilisateur);
  }

  async createFormulaireUtilisateur(formulaire: IUtilisateurFormulairePOST) {
    return await this.postRequest("/utilisateur/formulaire", formulaire);
  }

  async getFormulairesUtilisateur() {
    return await this.getRequest("/utilisateur/formulaire");
  }

  async updateFormulairesUtilisateur(
    idformulaireutilisateur: number,
    formulaire: IUtilisateurFormulairePUT,
  ) {
    return await this.putRequest(
      `/utilisateur/formulaire/${idformulaireutilisateur}`,
      formulaire,
    );
  }

  async deleteFormulaireUtilisateur(idformulaireutilisateur: number) {
    return await this.deleteRequest(
      `/utilisateur/formulaire/${idformulaireutilisateur}`,
    );
  }

  async validerFormulaireUtilisateur(
    idformulaireutilisateur: number,
    idprofil: number,
    idorganismestockeur: number,
  ) {
    if (idorganismestockeur) {
      return await this.putRequest(
        `/utilisateur/formulaire/${idformulaireutilisateur}/validation?idprofil=${idprofil}&idorganismestockeur=${idorganismestockeur}`,
      );
    }
    return await this.putRequest(
      `/utilisateur/formulaire/${idformulaireutilisateur}/validation?idprofil=${idprofil}`,
    );
  }

  async createAccesUtilisateur(idutilisateur: number, acces: number[]) {
    return await this.postRequest(`/utilisateur/${idutilisateur}/acces`, acces);
  }

  async changePasswordUtilisateur(
    idutilisateur: number,
    utilisateur: IChangePasswordPOST,
  ) {
    return await this.putRequest(
      `/utilisateur/${idutilisateur}/changepassword`,
      utilisateur,
    );
  }

  async reinitPasswordUtilisateur(idutilisateur: number) {
    return await this.postRequest(
      `/utilisateur/${idutilisateur}/reinitpassword`,
    );
  }

  async activerUtilisateur(idutilisateur: number) {
    return await this.putRequest(`/utilisateur/${idutilisateur}/activation`);
  }

  async modifierUtilisateur(idutilisateur: number, utilisateur: IUserPUT) {
    return await this.putRequest(`/utilisateur/${idutilisateur}`, utilisateur);
  }

  async updateInfosUtilisateur(
    idutilisateur: number,
    utilisateur: IUserInfoPUT,
  ) {
    return await this.putRequest(
      `/utilisateur/${idutilisateur}/infos`,
      utilisateur,
    );
  }

  async desactiverUtilisateur(idutilisateur: number) {
    return await this.putRequest(`/utilisateur/${idutilisateur}/desactivation`);
  }

  async deleteUtilisateur(idutilisateur: number) {
    return await this.deleteRequest(`/utilisateur/${idutilisateur}`);
  }
}

export default new UtilisateurController();
