import { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Collapse, Row } from "reactstrap";
import SurfacePotentielleController from "../config/apiUtils/SurfacePotentielleController";
import useConstructor from "../config/hooks/useConstructor";
import { createNotification, GoToTopPage } from "../config/utils";
import StoreContext from "../context/StoreContext";
import FormNewIntention from "./intentionsemis/FormNewIntention";
import FormUpdateIntention from "./intentionsemis/FormUpdateIntention";
import TableIntentionGeneral from "./intentionsemis/TableIntentionGeneral";

export default function IntentionSemis() {
  const [showNewIntentionForm, setShowNewIntentionForm] = useState(false);
  const [showUpdateIntentionForm, setShowUpdateIntentionForm] = useState(false);
  const [intentionToUpdate, setIntentionToUpdate] = useState({});
  const [surfacesPotentielle, setSurfacesPotentielle] = useState([]);
  const [totalPrintemps, setTotalPrintemps] = useState(0);
  const [totalAutomne, setTotalAutomne] = useState(0);
  const [totalTonnage, setTotalTonnage] = useState(0);
  const [loading, setLoading] = useState(false);
  const store = useContext(StoreContext);

  const loadSurfacesPotentielles = async () => {
    setLoading(true);
    const resSurfaces =
      await SurfacePotentielleController.getSurfacesPotentielles(
        store.millesime.idmillesime,
      );
    setSurfacesPotentielle(resSurfaces);
    setLoading(false);
  };

  useConstructor(async () => {
    await loadSurfacesPotentielles();
  });

  const handleSubmitNewIntention = async (surfacePotentielle) => {
    const res =
      await SurfacePotentielleController.insertSurfacePotentielle(
        surfacePotentielle,
      );
    if (res.status === 200) {
      createNotification("success", "Succès", "Intention de semis créée");
      await loadSurfacesPotentielles();
      setShowNewIntentionForm(false);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la création de l'intention de semis...",
      );
    }
  };

  const handleSubmitUpdateIntention = async (surfacePotentielle) => {
    const res = await SurfacePotentielleController.updateSurfacePotentielle(
      intentionToUpdate.idsurfacepotentielle,
      surfacePotentielle,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Intention de semis modifiée avec succès !",
      );
      setShowUpdateIntentionForm(false);
      setLoading(true);
      var tmp = [...surfacesPotentielle];
      var i = -1;
      tmp.forEach((surface, index) => {
        if (
          surface.idsurfacepotentielle == intentionToUpdate.idsurfacepotentielle
        ) {
          i = index;
        }
      });
      if (i !== -1) {
        tmp[i] = res.data;
      }
      setSurfacesPotentielle(tmp);
      setLoading(false);
    }
  };

  const handleDeleteIntention = async () => {
    setLoading(true);
    const res = await SurfacePotentielleController.deleteSurfacePotentielle(
      intentionToUpdate.idsurfacepotentielle,
    );
    if (res.status !== 200) {
      createNotification("error", "Erreur", "Une erreur est survenue...");
    } else {
      createNotification(
        "success",
        "Succès",
        "Intention de semis supprimée avec succès",
      );
      setShowUpdateIntentionForm(false);
      await loadSurfacesPotentielles();
      await store.loadData();
    }
    setLoading(false);
  };

  useEffect(() => {
    let tempPrintemps = 0;
    let tempAutomne = 0;
    let tempTonnage = 0;

    surfacesPotentielle.forEach((surface) => {
      if (!surface.abandon) {
        tempPrintemps += surface.surfacepotentielleprintemps;
        tempAutomne += surface.surfacepotentielleautomne;
        tempTonnage += surface.tonnageestime;
      }
    });

    setTotalPrintemps(tempPrintemps.toFixed(2));
    setTotalAutomne(tempAutomne.toFixed(2));
    setTotalTonnage(tempTonnage.toFixed(2));
  }, [surfacesPotentielle]);

  const renderTotaux = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              Total printemps : {totalPrintemps} ha
            </Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              Total automne : {totalAutomne} ha
            </Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              Tonnage total : {totalTonnage} T
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Intentions de semis</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button color="primary" onClick={() => setShowNewIntentionForm(true)}>
            <i className="fa-solid fa-plus" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={showNewIntentionForm} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <FormNewIntention
              onClickAdd={handleSubmitNewIntention}
              hideFormNewIntention={() => setShowNewIntentionForm(false)}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={showUpdateIntentionForm} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <FormUpdateIntention
              onClickUpdate={handleSubmitUpdateIntention}
              onClickDelete={handleDeleteIntention}
              intention={intentionToUpdate}
              hideFormUpdateIntention={() => setShowUpdateIntentionForm(false)}
            />
          </Col>
        </Row>
      </Collapse>
      <Row style={{ marginBottom: 30 }}>
        <Col>{renderTotaux()}</Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <TableIntentionGeneral
            loading={loading}
            surfacesPotentielle={surfacesPotentielle}
            showFormUpdateIntention={(intention) => {
              GoToTopPage();
              setIntentionToUpdate(intention);
              setShowUpdateIntentionForm(true);
            }}
          />
        </Col>
      </Row>
    </>
  );
}
