import APIController from "../APIController";
import {
  ICodeOS,
  IComparatifContratConvention,
  IComparatifContratIntention,
  IConformiteJacheresMelliferes,
  IContratIncomplet,
  IContratsNonRentres,
  ICotisationsImpayees,
  IExploitationSansProducteur,
  IExploitationZoneProduction,
  IIntentionSemisNonRentree,
  IListeRouge,
  ILivraisonsNonSaisies,
  IOrganismesStockeurs,
  ISurfaceVarieteOS,
  ITracabiliteContrats,
} from "src/config/types/analyses";
import { AxiosResponse } from "axios";

class AnalysesController extends APIController {
  constructor() {
    super("/analyse");
  }

  async getOrganismesStockeurs(): Promise<IOrganismesStockeurs[]> {
    return await this.getRequest("/organismesstockeurs");
  }

  async getCotisationsImpayees(
    millesime: number,
  ): Promise<ICotisationsImpayees[]> {
    return await this.getRequest("/cotisationsimpayees", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getComparatifContratConvention(
    millesime: number,
  ): Promise<IComparatifContratConvention[]> {
    return await this.getRequest("/comparatifcontratconvention", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getListeRouge(): Promise<IListeRouge[]> {
    return await this.getRequest("/listerouge");
  }

  async getComparatifContratIntention(
    millesime: number,
  ): Promise<IComparatifContratIntention[]> {
    return await this.getRequest("/comparatifcontratintention", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getContratsNonRentres(
    millesime: number,
  ): Promise<IContratsNonRentres[]> {
    return await this.getRequest("/contratsnonrentres", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getContratsIncomplets(millesime: number): Promise<IContratIncomplet[]> {
    return await this.getRequest("/contratsincomplets", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getLivraisonsNonSaisies(
    millesime: number,
  ): Promise<ILivraisonsNonSaisies[]> {
    return await this.getRequest("/livraisonsnonsaisies", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getExploitationsZoneProduction(): Promise<
    IExploitationZoneProduction[]
  > {
    return await this.getRequest("/exploitationszoneproduction");
  }

  async getSurfaceVarieteOS(millesime: number): Promise<ISurfaceVarieteOS[]> {
    return await this.getRequest("/surfacevarieteos", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getIntentionsSemisNonRentrees(
    millesime: number,
  ): Promise<IIntentionSemisNonRentree[]> {
    return await this.getRequest("/intentionsemisnonrentree", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getExploitationsSansProducteurs(): Promise<
    IExploitationSansProducteur[]
  > {
    return await this.getRequest("/exploitationsansproducteur");
  }

  async getConformitesJacheresMelliferes(
    millesime: number,
  ): Promise<IConformiteJacheresMelliferes[]> {
    return await this.getRequest(
      `/conformitejacheremellifere?millesime=${millesime}`,
    );
  }

  async getTracabiliteContrats(
    millesime: number,
  ): Promise<ITracabiliteContrats[]> {
    return await this.getRequest("/tracabilitecontrats", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getCodesOs(): Promise<ICodeOS[]> {
    return await this.getRequest("/codeos");
  }

  async updateCodeOs(
    idexploitation: number,
    idorganismestockeur: number,
    code: string,
  ): Promise<AxiosResponse> {
    return await this.putRequest(
      `/codeos?idexploitation=${idexploitation}&idorganismestockeur=${idorganismestockeur}&code=${code}`,
    );
  }

  async updateCodeDc(
    idexploitation: number,
    code: string,
  ): Promise<AxiosResponse> {
    return await this.putRequest(
      `/codeos/codedc?idexploitation=${idexploitation}&code=${code}`,
    );
  }

  async updateDateReceptionTracabilite(
    idcontrat: number,
    datereceptiontracabilite: string | null,
  ): Promise<AxiosResponse> {
    return await this.putRequest(
      `/tracabilitecontrats/datereceptiontracabilite?idcontrat=${idcontrat}${
        datereceptiontracabilite != null
          ? `&datereceptiontracabilite=${datereceptiontracabilite}`
          : ""
      }`,
    );
  }

  async updateDateVerificationTracabilite(
    idcontrat: number,
    dateverificationtracabilite: string | null,
  ): Promise<AxiosResponse> {
    return await this.putRequest(
      `/tracabilitecontrats/dateverificationtracabilite?idcontrat=${idcontrat}${
        dateverificationtracabilite != null
          ? `&dateverificationtracabilite=${dateverificationtracabilite}`
          : ""
      }`,
    );
  }
}

export default new AnalysesController();
