import APIController from "../APIController";
import {
  IOrganismeStockeur,
  IOrganismeStockeurPUT,
} from "../types/organismestockeur";
import { AxiosResponse } from "axios";
import { ISilo } from "../types/silo";

class OrganismeStockeurController extends APIController {
  constructor() {
    super("/organismestockeur");
  }

  async getOrganismesStockeur(): Promise<IOrganismeStockeur[]> {
    return await this.getRequest("/");
  }

  async getOrganismesStockeurById(
    idorganismestockeur: number,
  ): Promise<IOrganismeStockeur> {
    return await this.getRequest(`/${idorganismestockeur}`);
  }

  async getSiloByIdOrganismeStockeur(
    idorganismestockeur: number,
    millesime: number,
  ): Promise<ISilo[]> {
    return await this.getRequest(
      `/${idorganismestockeur}/silo?millesime=${millesime}`,
    );
  }

  async updateOrganismeStockeur(
    idorganismestockeur: number,
    organisme: IOrganismeStockeurPUT,
  ): Promise<AxiosResponse<IOrganismeStockeur>> {
    return await this.putRequest(`/${idorganismestockeur}`, organisme);
  }
}

export default new OrganismeStockeurController();
