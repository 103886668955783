import APIController from "../APIController";
import { ILayer } from "../types/carto/layer";

class CartoController extends APIController {
  constructor() {
    super("/carto");
  }

  async getLayers(): Promise<ILayer[]> {
    return await this.getRequest("/layer");
  }

  async getLayersCatalogue(codecatalogue: string): Promise<ILayer[]> {
    return await this.getRequest(`/catalogue/${codecatalogue}`);
  }
}

export default new CartoController();
