import React from "react";
import CertificationExploitation from "../../views/CertificationExploitation";
import CertificationIgp from "../../views/CertificationIgp";
import DocumentExploitation from "../../views/DocumentExploitation";
import Exploitations from "../../views/Exploitations";
import FormationsProducteur from "../../views/FormationsProducteur";
import Parcelles from "../../views/Parcelles";
import Producteurs from "../../views/Producteurs";

import droits from "../CodesDroits";
import Layouts from "../layoutRoutes";

const ExploitationRoutes = [
  {
    path: "/producteurs",
    name: "Producteurs",
    element: <Producteurs />,
    layout: Layouts.userLayout,
    restriction: droits.typesdroits.ACCES_PRODUCTEUR,
    show: true,
  },
  {
    path: "/certification-igp",
    name: "Certifications IGP",
    element: <CertificationIgp />,
    restriction: droits.typesdroits.ACCES_CERTIFICATION_IGP,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/certification-exploitation",
    name: "Certifications exploitation",
    element: <CertificationExploitation />,
    restriction: droits.typesdroits.ACCES_CERTIFICATION_EXPLOITATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/exploitation",
    name: "Mon exploitation",
    element: <Exploitations />,
    layout: Layouts.userLayout,
    restriction: droits.typesdroits.ACCES_EXPLOITATION,
    show: true,
  },
  {
    path: "/exploitation-document",
    name: "Mes documents",
    element: <DocumentExploitation />,
    layout: Layouts.userLayout,
    restriction: droits.typesdroits.ACCES_EXPLOITATION,
    show: true,
  },
  {
    path: "/formations-producteur",
    name: "Formations producteur",
    element: <FormationsProducteur />,
    restriction: droits.typesdroits.ACCES_FORMATION,
    layout: Layouts.userLayout,
    show: true,
  },
  {
    path: "/parcelles",
    name: "Parcelles",
    element: <Parcelles />,
    restriction: droits.typesdroits.ACCES_FORMATION,
    layout: Layouts.userLayout,
    show: true,
  },
];

export default ExploitationRoutes;
