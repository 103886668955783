import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import ReferentielController from "../config/apiUtils/ReferentielController";
import useConstructor from "../config/hooks/useConstructor";
import FormUpdateCotisation from "./cotisationannuelle/FormUpdateCotisation";
import { createNotification, RenderIf } from "../config/utils";
import AgGrid from "../components/AgGrid";
import { columns } from "../components/Grids/CotisationAnnuelle/cotisationannuelle";
import { ICotisation, ICotisationPUT } from "../config/types/referentiel";

export default function CotisationAnnuelle(): React.JSX.Element {
  const [cotisations, setCotisations] = useState<ICotisation[]>([]);
  const [selectedCotisation, setSelectedCotisation] =
    useState<ICotisation | null>(null);
  const [isFormUpdateCotisationOpen, setIsFormUpdateCotisationOpen] =
    useState(false);

  const onSelectCotisation = (params: any) => {
    setSelectedCotisation(params.data);
    setIsFormUpdateCotisationOpen(true);
  };

  const onClickUpdateCotisation = async (cotisation: ICotisationPUT) => {
    if (selectedCotisation === null) {
      return;
    }

    const resUpdateCotisation = await ReferentielController.updateRefCotisation(
      selectedCotisation.idrefcotisation,
      cotisation,
    );
    if (resUpdateCotisation.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Cotisation annuelle modifiée avec succès !",
      );
      await getCotisations();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification de la cotisation...",
      );
    }
  };

  const getCotisations = async () => {
    const resCotisations = await ReferentielController.getRefCotisation();
    setCotisations(resCotisations);
  };

  useConstructor(async () => {
    await getCotisations();
  });

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des cotisations annuelles</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button color="primary">
            <i className="fa-solid fa-plus" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={isFormUpdateCotisationOpen} mountOnEnter unmountOnExit>
        <RenderIf isTrue={selectedCotisation !== null}>
          <Row style={{ marginBottom: 30 }}>
            <Col md={12}>
              <FormUpdateCotisation
                cotisation={selectedCotisation}
                hideFormUpdateCotisation={() => {
                  setIsFormUpdateCotisationOpen(false);
                }}
                onClickUpdateCotisation={onClickUpdateCotisation}
              />
            </Col>
          </Row>
        </RenderIf>
      </Collapse>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>
                    Liste des cotisations annuelles
                  </CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <AgGrid
                columnDefs={columns}
                rowData={cotisations}
                showFilterLine
                onRowClicked={onSelectCotisation}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
