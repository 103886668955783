import Section from "../Section";
import { Alert, Table } from "reactstrap";
import Constantes from "../../../../config/constantes";

export default function IntentionSemis({ intention, ind }) {
  return (
    <Section
      id={`intention-${intention.idsurfacepotentielle}`}
      key={ind}
      title={`Intention du ${intention.datesurfacepotentielle}`}
      styleBody={{ padding: 0 }}
    >
      <Table style={{ color: "#FFFFFF" }}>
        <tbody>
          <tr>
            <th scope={"row"}>Production</th>
            <td>{intention.libelletypeproduction ?? "-"}</td>
          </tr>
          <tr>
            <th scope={"row"}>Organisme Stockeur</th>
            <td>{intention.nomorganismestockeur ?? "-"}</td>
          </tr>
          <tr>
            <th scope={"row"}>Date d'intention de semis</th>
            <td>{intention.datesurfacepotentielle ?? "-"}</td>
          </tr>
          <tr>
            <th scope={"row"}>Surface potentielle printemps</th>
            <td>
              {intention.surfacepotentielleprintemps
                ? intention.surfacepotentielleprintemps + " Ha"
                : "-"}
            </td>
          </tr>
          <tr>
            <th scope={"row"}>Surface potentielle automne</th>
            <td>
              {intention.surfacepotentielleautomne
                ? intention.surfacepotentielleautomne + " Ha"
                : "-"}
            </td>
          </tr>
          <tr>
            <th scope={"row"}>Etat</th>
            <td>
              <Alert
                fade={false}
                color={
                  intention.idetatvalidationadministrative ==
                  Constantes.etatsValidationAdministrative.valide
                    ? "success"
                    : intention.idetatvalidationadministrative ==
                        Constantes.etatsValidationAdministrative.nonValide
                      ? "danger"
                      : "warning"
                }
                style={styles.alertStyleValidation}
              >
                {intention.libellevalidationadministrative ?? "-"}
              </Alert>
            </td>
          </tr>
          <tr>
            <th scope={"row"}>Commentaire</th>
            <td>
              {intention.commentaire?.length > 0 ? intention.commentaire : "-"}
            </td>
          </tr>
        </tbody>
      </Table>
    </Section>
  );
}

const styles = {
  alertStyle: {
    height: 12,
    width: "100%",
    padding: "0rem 1rem 1.5rem",
    display: "flex",
    justifyContent: "center",
    marginBottom: "0.5rem",
  },
  alertStyleValidation: {
    width: "100%",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    marginBottom: "0.5rem",
  },
};
