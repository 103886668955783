import colors from "../../../config/colors";
import {
  cellRendererSwitch,
  cellStyleSwitch,
  valueFormatterSurface,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "SIRET",
    field: "siret",
  },
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Adresse",
    field: "adresse",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Surface du contrat (Ha)",
    field: "surfacefinale",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Surface jachère (Ha)",
    field: "surfacejachere",
    valueFormatter: valueFormatterSurface,
    cellStyle: { textAlign: "center" },
  },
  {
    headerName: "Surface jachère conforme (4%)",
    field: "estconforme",
    cellStyle: (params) =>
      cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        {
          value: false,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: null,
          cellStyle: {
            border: "1px solid",
            color: colors.warning.color,
            backgroundColor: colors.warning.background,
          },
        },
      ]),
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: "Non renseigné",
        },
      ]);
    },
  },
];
