import { IVisite } from "../../../config/types/visiteparcelles";
import React from "react";
import { Col, Row, Table } from "reactstrap";
import moment from "moment";

interface IProps {
  visite: IVisite | null;
}

export default function RecapVisitePrecedente(
  props: IProps,
): React.JSX.Element {
  const renderVisite = () => {
    if (props.visite === null) {
      return null;
    }

    return (
      <Row style={{ marginTop: 10 }}>
        <Col md={6} style={{ padding: 0 }}>
          <Table
            style={{
              color: "#FFFFFF",
            }}
            striped
          >
            <tbody>
              <tr>
                <th scope="row">Date de la visite</th>
                <td>
                  {props.visite.datevisite
                    ? moment(props.visite.datevisite).format("DD/MM/YYYY")
                    : "-"}
                </td>
              </tr>
              <tr>
                <th scope="row">Nom du technicien</th>
                <td>
                  {props.visite.nomtechnicien} {props.visite.prenomtechnicien}
                </td>
              </tr>
              <tr>
                <th scope="row">Conforme</th>
                <td>{props.visite.conforme ? "Oui" : "Non"}</td>
              </tr>
              <tr>
                <th scope="row">Présence de Colza</th>
                <td>
                  {props.visite.presencecolza
                    ? "Oui - " + props.visite.nbcolza + " plants / m²"
                    : "Non"}
                </td>
              </tr>
              <tr>
                <th scope="row">Présence de Sanves ravenelles</th>
                <td>
                  {props.visite.presencesanvesravenelles
                    ? "Oui - " +
                      props.visite.nbsanvesravenelles +
                      " plants / m²"
                    : "Non"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col md={6} style={{ padding: 0 }}>
          <Table
            style={{
              color: "#FFFFFF",
            }}
            striped
          >
            <tbody>
              <tr>
                <th scope="row">Action corrective proposée</th>
                <td>{props.visite.actioncorrectiveproposee}</td>
              </tr>
              <tr>
                <th scope="row">Action corrective mise en place</th>
                <td>{props.visite.actioncorrectivemiseplace ?? "-"}</td>
              </tr>
              <tr>
                <th scope="row">Date de prochaine visite</th>
                <td>
                  {props.visite.datevisitesupplementaire
                    ? moment(props.visite.datevisitesupplementaire).format(
                        "DD/MM/YYYY",
                      )
                    : "-"}
                </td>
              </tr>
              <tr>
                <th scope="row">Autres anomalies</th>
                <td>{props.visite.autresanomalies}</td>
              </tr>
              <tr>
                <th scope="row">Commentaires</th>
                <td>{props.visite.commentaire}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Col>
          <h3>Visite précédente</h3>
        </Col>
      </Row>
      {renderVisite()}
      <hr />
    </>
  );
}
