import APIController from "../APIController";
import { IParcelle, IParcellePOST, IParcellePUT } from "../types/parcelle";
import { IGeoJson } from "../types/geojson";
import { ICommune } from "../types/commune";

class ParcelleController extends APIController {
  constructor() {
    super("/parcelle");
  }

  async getParcelles(): Promise<IParcelle[]> {
    return await this.getRequest("/");
  }

  async insertParcelle(parcelle: IParcellePOST): Promise<IParcelle> {
    return await this.postRequest("/", parcelle);
  }

  async updateParcelle(
    idparcelle: number,
    parcelle: IParcellePUT,
  ): Promise<IParcelle> {
    return await this.putRequest(`/${idparcelle}`, parcelle);
  }

  async associerParcelle(
    idparcelle: number,
    parcelle: IGeoJson,
  ): Promise<IParcelle> {
    return await this.postRequest(`/${idparcelle}/associer`, parcelle);
  }

  async getCommuneParcelleByGeom(geojson: IGeoJson): Promise<ICommune> {
    return await this.getRequest(
      `/communebygeom?geojson=${JSON.stringify(geojson)}`,
    );
  }
}

export default new ParcelleController();
