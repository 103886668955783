import { useCallback, useEffect, useRef, useState } from "react";
import { createNotification, RenderIf } from "../../config/utils";
import AuthService from "../../config/AuthService";
import { Card, CardBody, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import Select from "react-select";
import { colourStyles } from "../../config/carto/utils";
import ValidateButton from "../../components/Buttons/ValidateButton";
import { columns } from "../../components/Grids/Contrat/contrats";
import AgGrid from "../../components/AgGrid";

export default function CardValidationContrats({
  contrats,
  loading,
  handleSubmitValidation,
}) {
  const [selected, setSelected] = useState({});
  const [idetatvalidationadministrative, setIdetatvalidationadministrative] =
    useState(null);

  const actionSelectRef = useRef();

  const onSelectionChange = useCallback((params) => {
    setSelected(params.map((p) => p.data));
  }, []);

  const submitValidation = async () => {
    if (
      Object.values(selected).map((contrat) => contrat.idcontrat).length <= 0
    ) {
      createNotification("error", "Merci de sélectionner au moins un contrat");
      return;
    }

    if (actionSelectRef.current.getValue().length <= 0) {
      createNotification(
        "error",
        "Merci de sélectionner une action à effectuer",
      );
      return;
    }

    let valide = false;
    let idetatvalidationadministrative =
      actionSelectRef.current.getValue()[0].value;

    if (AuthService.isOrganismeStockeur()) {
      valide = actionSelectRef.current.getValue()[0].value;
    }

    await handleSubmitValidation(
      idetatvalidationadministrative,
      Object.values(selected).map((contrat) => contrat.idcontrat),
      valide,
    );
  };

  const getActionsOptions = () => {
    if (AuthService.isOrganismeStockeur()) {
      return [
        {
          value: false,
          label: "Non validé",
        },
        {
          value: true,
          label: "Validé",
        },
      ];
    }

    return [
      {
        value: 1,
        label: "Non validé administrativement",
      },
      {
        value: 2,
        label:
          "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
      },
      {
        value: 3,
        label:
          "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
      },
      {
        value: 4,
        label: "Validé administrativement",
      },
      {
        value: 5,
        label: "Pré-validé, en attente paiement cotisation",
      },
    ];
  };

  useEffect(() => {
    const organismes = [];
    const typesproduction = [];
    contrats.forEach((contrat) => {
      if (
        !organismes.some(
          (organisme) => organisme === contrat.nomorganismestockeur,
        )
      ) {
        organismes.push(contrat.nomorganismestockeur);
      }

      if (
        !typesproduction.some((type) => type === contrat.libelletypeproduction)
      ) {
        typesproduction.push(contrat.libelletypeproduction);
      }
    });

    columns.forEach((column) => {
      if (column.name === "nomorganismestockeur") {
        column.filterEditorProps = {
          multiple: true,
          wrapMultiple: false,
          dataSource: organismes.map((c) => {
            return { id: c, label: c };
          }),
        };
      }
      if (column.name === "libelletypeproduction") {
        column.filterEditorProps = {
          multiple: true,
          wrapMultiple: false,
          dataSource: typesproduction.map((c) => {
            return { id: c, label: c };
          }),
        };
      }
    });
  }, [contrats]);

  return (
    <Card>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Row style={{ marginBottom: 10 }}>
            <Col md={"6"}>
              <Select
                isClearable
                classNamePrefix="react-select"
                placeholder="Action..."
                ref={actionSelectRef}
                menuPortalTarget={document.body}
                autosize={false}
                menuPlacement={"auto"}
                menuPosition={"fixed"}
                defaultValue={getActionsOptions().find(
                  (etat) => etat.value == idetatvalidationadministrative,
                )}
                styles={colourStyles}
                options={getActionsOptions()}
                onChange={(selection) => {
                  setIdetatvalidationadministrative(selection?.value ?? null);
                }}
              />
            </Col>
            <Col md={"2"}>
              <ValidateButton onClick={submitValidation} />
            </Col>
            <Col md={"4"} style={{ display: "flex", justifyContent: "end" }}>
              {`${Object.values(selected).length} contrats sélectionnés`}
            </Col>
          </Row>
          <Row>
            <Col>
              <AgGrid
                columnDefs={columns}
                rowData={contrats}
                onRowSelectionChanged={onSelectionChange}
                showFilterLine
                selectable
              />
            </Col>
          </Row>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
