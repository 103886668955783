import React from "react";
import AppelCotisations from "../../views/actionsgroupees/AppelCotisations";
import AppelIntentionsSemis from "../../views/actionsgroupees/AppelIntentionsSemis";
import AttestationLivraisonSemences from "../../views/actionsgroupees/AttestationLivraisonSemences";
import Avertissements from "../../views/actionsgroupees/Avertissements";
import ConfirmationSurfaces from "../../views/actionsgroupees/ConfirmationSurfaces";
import ConsignesRecolte from "../../views/actionsgroupees/ConsignesRecolte";
import EnvoiGuideCarnetSuivi from "../../views/actionsgroupees/EnvoiGuideCarnetSuivi";
import ExploitationsActivesIntentionsContrats from "../../views/actionsgroupees/ExploitationsActivesIntentionsContrats";
import ExploitationsActivesProduction from "../../views/actionsgroupees/ExploitationsActivesProduction";
import HistoriqueFormation from "../../views/actionsgroupees/HistoriqueFormation";
import PreAvertissements from "../../views/actionsgroupees/PreAvertissements";

import droits from "../CodesDroits";
import Layouts from "../layoutRoutes";

const ActionsGroupeesRoutes = [
  {
    path: "/actions-groupees/preavertissements",
    name: "Pré avertissement (intention de semis + contrat) | Actions groupées",
    element: <PreAvertissements />,
    restriction: droits.typesdroits.AVERTISSEMENTS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/avertissements",
    name: "Avertissement (contrat) | Actions groupées",
    element: <Avertissements />,
    restriction: droits.typesdroits.AVERTISSEMENTS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/appel-cotisations",
    name: "Appel des cotisations | Actions groupées",
    element: <AppelCotisations />,
    restriction: droits.typesdroits.APPEL_COTISATIONS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/appel-intentions-semis",
    name: "Appel des intentions de semis | Actions groupées",
    element: <AppelIntentionsSemis />,
    restriction: droits.typesdroits.APPEL_INTENTIONS_SEMIS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/attestation-livraison-semences",
    name: "Attestation de livraison des semences (contrats) | Actions groupées",
    element: <AttestationLivraisonSemences />,
    restriction: droits.typesdroits.ATTESTATION_LIVRAISON_SEMENCES,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/confirmation-surfaces",
    name: "Confirmation des surfaces | Actions groupées",
    element: <ConfirmationSurfaces />,
    restriction: droits.typesdroits.CONFIRMATION_SURFACES,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/consignes-recolte",
    name: "Consignes de récolte | Actions groupées",
    element: <ConsignesRecolte />,
    restriction: droits.typesdroits.CONSIGNES_RECOLTE,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/envoi-guides-carnet-suivi",
    name: "Envoi des guides et carnet de suivi (contrats) | Actions groupées",
    element: <EnvoiGuideCarnetSuivi />,
    restriction: droits.typesdroits.ENVOI_GUIDES_CARNET_SUIVI,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/exploitations-actives-production",
    name: "Exploitations actives en production (contrat) | Actions groupées",
    element: <ExploitationsActivesProduction />,
    restriction: droits.typesdroits.EXPLOITATIONS_ACTIVES_PRODUCTION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/exploitations-actives-production-intentions-contrats",
    name: "Exploitations actives en production (intentions de semis + contrats) | Actions groupées",
    element: <ExploitationsActivesIntentionsContrats />,
    restriction: droits.typesdroits.EXPLOITATIONS_ACTIVES_PRODUCTION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/actions-groupees/historique-formation",
    name: "Historique des formations | Actions groupées",
    element: <HistoriqueFormation />,
    restriction: droits.typesdroits.HISTORIQUE_FORMATIONS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
];

export default ActionsGroupeesRoutes;
