import APIController from "../APIController";
import {
  ICommande,
  ICommandePOST,
  ICommandePUT,
} from "src/config/types/commande";
import { IResponse } from "src/config/types/response";
import { AxiosResponse } from "axios";

class CommandeController extends APIController {
  constructor() {
    super("/commande");
  }

  async getCommandes(millesime: number | null = null): Promise<ICommande[]> {
    return await this.getRequest("/", {
      params: {
        millesime: millesime,
      },
    });
  }

  async updateCommande(
    idcommande: number,
    commande: ICommandePUT,
  ): Promise<AxiosResponse<IResponse>> {
    return await this.putRequest(`/${idcommande}`, commande);
  }

  async addCommande(
    commande: ICommandePOST,
  ): Promise<AxiosResponse<ICommande>> {
    return await this.postRequest("/", commande);
  }
}

export default new CommandeController();
