import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import FormNewMateriel from "./Materiel/FormNewMateriel";
import FormUpdateMateriel from "./Materiel/FormUpdateMateriel";
import { createNotification } from "../../config/utils";
import useConstructor from "../../config/hooks/useConstructor";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import AgGrid from "../../components/AgGrid";

const columns = [
  {
    headerName: "Type de matériel",
    field: "libelletypemateriel",
  },
  {
    headerName: "Matériel",
    field: "libellemateriel",
  },
];

export default function Materiel() {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [showFormNewMateriel, setShowFormNewMateriel] = useState(false);
  const [showFormUpdateMateriel, setShowFormUpdateMateriel] = useState(false);
  const [idrefmaterielToUpdate, setIdrefmaterielToUpdate] = useState();

  const handleAddNewMateriel = async (materiel) => {
    const res = await ReferentielController.insertMateriel(materiel);
    if (res.status === 200) {
      createNotification("success", "Succès", "Matériel créé avec succès");
      store.referentiels.setMateriels([
        ...store.referentiels.materiels,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du matériel",
      );
    }
  };

  const handleUpdateMateriel = async (materiel) => {
    const res = await ReferentielController.updateMateriel(
      idrefmaterielToUpdate,
      materiel,
    );
    if (res.status === 200) {
      createNotification("success", "Succès", "Matériel modifié avec succès");
      store.referentiels.setMateriels(
        await ReferentielController.getMateriels(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du matériel",
      );
    }
  };

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setMateriels(await ReferentielController.getMateriels());
    store.referentiels.setTypesMateriels(
      await ReferentielController.getTypesMateriel(),
    );
    setLoading(false);
  });

  return (
    <>
      <Collapse
        isOpen={showFormNewMateriel}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewMateriel
          onClickAdd={handleAddNewMateriel}
          onClickClose={() => setShowFormNewMateriel(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateMateriel}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateMateriel
          onClickUpdate={handleUpdateMateriel}
          onClickClose={() => setShowFormUpdateMateriel(false)}
          idrefmateriel={idrefmaterielToUpdate}
        />
      </Collapse>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Matériels</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                color="primary"
                onClick={() => setShowFormNewMateriel(true)}
              >
                <i className="fa-solid fa-plus" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col
              style={
                loading ? { display: "flex", justifyContent: "center" } : {}
              }
            >
              {loading && <SpinLoadingAnimation />}
              {store.referentiels.materiels !== null &&
                store.referentiels.materiels !== [] &&
                store.referentiels.materiels !== undefined &&
                !loading && (
                  <AgGrid
                    columnDefs={columns}
                    rowData={store.referentiels.materiels}
                    onRowClicked={(params) => {
                      setIdrefmaterielToUpdate(params.data.idrefmateriel);
                      setShowFormUpdateMateriel(true);
                    }}
                    showFilterLine
                  />
                )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
