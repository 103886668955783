import { Col, Row } from "reactstrap";
import React from "react";

export default function Reglages(): React.JSX.Element {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Réglages généraux</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
      </Row>
    </>
  );
}
