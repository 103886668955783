import APIController from "../APIController";
import {
  ICotisation,
  ICotisationPUT,
  ICulture,
  IEtatValidationAdministrative,
  IMateriel,
  IMaterielPOST,
  IMaterielPUT,
  IProduit,
  IProduitPOST,
  IProduitPUT,
  IStatutJuridique,
  IStatutJuridiquePOST,
  IStatutJuridiquePUT,
  ITypeDocument,
  ITypeDocumentPOST,
  ITypeDocumentPUT,
  ITypeFormation,
  ITypeFormationPOST,
  ITypeFormationPUT,
  ITypeIntervention,
  ITypeInterventionPOST,
  ITypeInterventionPUT,
  ITypeMateriel,
  ITypeMaterielPUT,
  ITypeProduction,
  ITypeProductionPOST,
  ITypeProductionPUT,
  ITypeProduit,
  ITypeProduitPOST,
  ITypeProduitPUT,
  ITypeSilo,
  ITypeSiloPOST,
  ITypeSiloPUT,
  ITypeSol,
  ITypeSolPOST,
  ITypeSolPUT,
  ITypeVariete,
  ITypeVarietePOST,
  ITypeVarietePUT,
  IVariete,
  IVarietePOST,
  IVarietePUT,
} from "src/config/types/referentiel";
import { AxiosResponse } from "axios";
import {
  IMatiereActive,
  IMatiereActivePOST,
  IMatiereActivePUT,
} from "src/config/types/matiereproduit";

class ReferentielController extends APIController {
  constructor() {
    super("/referentiel");
  }

  async getEtatsValidationAdministrative(): Promise<
    IEtatValidationAdministrative[]
  > {
    return await this.getRequest("/etat/validationadministrative");
  }

  async getStatutsJuridique(): Promise<IStatutJuridique[]> {
    return await this.getRequest("/statutjuridique");
  }

  async updateStatutJuridique(
    idstatutjuridique: number,
    statutjuridique: IStatutJuridiquePUT,
  ): Promise<AxiosResponse<IStatutJuridique>> {
    return await this.putRequest(
      `/statutjuridique/${idstatutjuridique}`,
      statutjuridique,
    );
  }

  async insertStatutJuridique(
    statutjuridique: IStatutJuridiquePOST,
  ): Promise<AxiosResponse<IStatutJuridique>> {
    return await this.postRequest("/statutjuridique", statutjuridique);
  }

  async getTypesIntervention(): Promise<ITypeIntervention[]> {
    return await this.getRequest("/types/intervention");
  }

  async insertTypeIntervention(
    intervention: ITypeInterventionPOST,
  ): Promise<AxiosResponse<ITypeIntervention>> {
    return await this.postRequest("/types/intervention", intervention);
  }

  async updateTypeIntervention(
    idreftypeintervention: number,
    typeIntervention: ITypeInterventionPUT,
  ): Promise<AxiosResponse<ITypeIntervention>> {
    return await this.putRequest(
      `/types/intervention/${idreftypeintervention}`,
      typeIntervention,
    );
  }

  async getTypesMateriel(): Promise<ITypeMateriel[]> {
    return await this.getRequest("/types/materiel");
  }

  async insertTypeMateriel(
    materiel: ITypeMateriel,
  ): Promise<AxiosResponse<ITypeMateriel>> {
    return await this.postRequest("/types/materiel", materiel);
  }

  async updateTypeMateriel(
    idreftypemateriel: number,
    typeMateriel: ITypeMaterielPUT,
  ): Promise<AxiosResponse<ITypeMateriel>> {
    return await this.putRequest(
      `/types/materiel/${idreftypemateriel}`,
      typeMateriel,
    );
  }

  async getMateriels(): Promise<IMateriel[]> {
    return await this.getRequest("/materiel");
  }

  async insertMateriel(
    materiel: IMaterielPOST,
  ): Promise<AxiosResponse<IMateriel>> {
    return await this.postRequest("/materiel", materiel);
  }

  async updateMateriel(
    idrefmateriel: number,
    materiel: IMaterielPUT,
  ): Promise<AxiosResponse<IMateriel>> {
    return await this.putRequest(`/materiel/${idrefmateriel}`, materiel);
  }

  async getTypesProduction(onlyActif = false): Promise<ITypeProduction[]> {
    return await this.getRequest(`/types/production?onlyActif=${onlyActif}`);
  }

  async insertTypeProduction(
    typeProduction: ITypeProductionPOST,
  ): Promise<AxiosResponse<ITypeProduction>> {
    return await this.postRequest("/types/production", typeProduction);
  }

  async updateTypeProduction(
    idreftypeproduction: number,
    typeProduction: ITypeProductionPUT,
  ): Promise<AxiosResponse<ITypeProduction>> {
    return await this.putRequest(
      `/types/production/${idreftypeproduction}`,
      typeProduction,
    );
  }

  async getTypesProduit(): Promise<ITypeProduit[]> {
    return await this.getRequest("/types/produit");
  }

  async insertTypeProduit(
    typeProduit: ITypeProduitPOST,
  ): Promise<AxiosResponse<ITypeProduit>> {
    return await this.postRequest("/types/produit", typeProduit);
  }

  async updateTypeProduit(
    idreftypeproduit: number,
    typeproduit: ITypeProduitPUT,
  ): Promise<AxiosResponse<ITypeProduit>> {
    return await this.putRequest(
      `/types/produit/${idreftypeproduit}`,
      typeproduit,
    );
  }

  async getProduits(): Promise<IProduit[]> {
    return await this.getRequest("/produit");
  }

  async updateProduit(
    idrefproduit: number,
    produit: IProduitPUT,
  ): Promise<AxiosResponse<IProduit>> {
    return await this.putRequest(`/produit/${idrefproduit}`, produit);
  }

  async insertProduit(produit: IProduitPOST): Promise<AxiosResponse<IProduit>> {
    return await this.postRequest("/produit", produit);
  }

  async getRefCotisation(): Promise<ICotisation[]> {
    return await this.getRequest("/cotisation");
  }

  async getTypesVariete(): Promise<ITypeVariete[]> {
    return await this.getRequest("/types/variete");
  }

  async insertTypeVariete(
    typeVariete: ITypeVarietePOST,
  ): Promise<AxiosResponse<ITypeVariete>> {
    return await this.postRequest("/types/variete", typeVariete);
  }

  async updateTypeVariete(
    idreftypevariete: number,
    typeVariete: ITypeVarietePUT,
  ): Promise<AxiosResponse<ITypeVariete>> {
    return await this.putRequest(
      `/types/variete/${idreftypevariete}`,
      typeVariete,
    );
  }

  async getVarietes(estutilisee = false): Promise<IVariete[]> {
    return await this.getRequest(`/variete?estutilisee=${estutilisee}`);
  }

  async insertVariete(variete: IVarietePOST): Promise<AxiosResponse<IVariete>> {
    return await this.postRequest("/variete", variete);
  }

  async updateVariete(
    idrefvariete: number,
    variete: IVarietePUT,
  ): Promise<AxiosResponse<IVariete>> {
    return await this.putRequest(`/variete/${idrefvariete}`, variete);
  }

  async getTypesFormations(): Promise<ITypeFormation[]> {
    return await this.getRequest("/types/formation");
  }

  async insertTypeFormation(
    typeformation: ITypeFormationPOST,
  ): Promise<AxiosResponse<ITypeFormation>> {
    return await this.postRequest("/types/formation", typeformation);
  }

  async updateTypeFormation(
    idreftypeformation: number,
    typeformation: ITypeFormationPUT,
  ): Promise<AxiosResponse<ITypeFormation>> {
    return await this.putRequest(
      `/types/formation/${idreftypeformation}`,
      typeformation,
    );
  }

  async updateRefCotisation(
    idrefcotisation: number,
    cotisation: ICotisationPUT,
  ): Promise<AxiosResponse<ICotisation>> {
    return await this.putRequest(`/cotisation/${idrefcotisation}`, cotisation);
  }

  async getTypesMatieresActives(): Promise<IMatiereActive[]> {
    return await this.getRequest("/types/matiereactive");
  }

  async insertTypeMatiereActive(
    typematiereactive: IMatiereActivePOST,
  ): Promise<AxiosResponse<IMatiereActive>> {
    return await this.postRequest("/types/matiereactive", typematiereactive);
  }

  async updateTypeMatiereActive(
    idreftypematiereactive: number,
    typematiereactive: IMatiereActivePUT,
  ): Promise<AxiosResponse<IMatiereActive>> {
    return await this.putRequest(
      `/types/matiereactive/${idreftypematiereactive}`,
      typematiereactive,
    );
  }

  async getTypesSol(): Promise<ITypeSol[]> {
    return await this.getRequest("/types/sol");
  }

  async insertTypeSol(typesol: ITypeSolPOST): Promise<AxiosResponse<ITypeSol>> {
    return await this.postRequest("/types/sol", typesol);
  }

  async updateTypeSol(
    idreftypesol: number,
    typesol: ITypeSolPUT,
  ): Promise<AxiosResponse<ITypeSol>> {
    return await this.putRequest(`/types/sol/${idreftypesol}`, typesol);
  }

  async getTypesDocument(): Promise<ITypeDocument[]> {
    return await this.getRequest("/types/document");
  }

  async insertTypeDocument(
    typedocument: ITypeDocumentPOST,
  ): Promise<AxiosResponse<ITypeDocument>> {
    return await this.postRequest("/types/document", typedocument);
  }

  async updateTypeDocument(
    idreftypedocument: number,
    typedocument: ITypeDocumentPUT,
  ): Promise<AxiosResponse<ITypeDocument>> {
    return await this.putRequest(
      `/types/document/${idreftypedocument}`,
      typedocument,
    );
  }

  async getTypesSilo(): Promise<ITypeSilo[]> {
    return await this.getRequest("/types/silo");
  }

  async insertTypeSilo(
    typesilo: ITypeSiloPOST,
  ): Promise<AxiosResponse<ITypeSilo>> {
    return await this.postRequest("/types/silo", typesilo);
  }

  async updateTypeSilo(
    idreftypesilo: number,
    typesilo: ITypeSiloPUT,
  ): Promise<AxiosResponse<ITypeSilo>> {
    return await this.putRequest(`/types/silo/${idreftypesilo}`, typesilo);
  }

  async getCultures(): Promise<ICulture[]> {
    return await this.getRequest("/culture");
  }
}

export default new ReferentielController();
