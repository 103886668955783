import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Producteur",
    field: "nomproducteur",
  },
  {
    headerName: "Type de formation",
    field: "libelletypeformation",
  },
  {
    headerName: "Date de formation",
    field: "dateformation",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Formation suivie",
    field: "suivi",
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Commentaire",
    field: "commentaire",
    flex: 2,
  },
];
