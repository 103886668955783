export const columns = [
  {
    headerName: "Nom de la parcelle",
    field: "nomparcelle",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    minWidth: 150,
    flex: 1,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Surface",
    field: "surface",
    minWidth: 170,
    flex: 1,
  },
  {
    headerName: "Type de sol",
    field: "libelletypesol",
    minWidth: 130,
    flex: 1,
  },
];
