import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import OrganismeStockeurController from "../config/apiUtils/OrganismeStockeurController";
import {
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
  GoToTopPage,
  RenderIf,
} from "../config/utils";
import StoreContext from "../context/StoreContext";
import BilanParOganismeStockeur from "./planproduction/BilanParOganismeStockeur";
import ListeParOrganismeStockeur from "./planproduction/ListeParOrganismeStockeur";
import ListeParParcelle from "./planproduction/ListeParParcelle";
import RecapCommandeIndustrielle from "./planproduction/RecapCommandeIndustrielle";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import PlanProductionController from "../config/apiUtils/PlanProductionController";
import droits from "../config/CodesDroits";

export default function PlanProduction(): React.JSX.Element {
  const context = useContext(StoreContext);
  const [isListParParcelleOpen, setIsListParParcelleOpen] =
    useState<boolean>(false);
  const [isListParOrganismeStockeurOpen, setIsListParOrganismeStockeurOpen] =
    useState<boolean>(false);
  const [isBilanParOrganismeStockeurOpen, setIsBilanParOrganismeStockeurOpen] =
    useState<boolean>(false);
  const [loadingPlanProduction, setLoadingPlanProduction] =
    useState<boolean>(true);

  const loadData = async () => {
    setLoadingPlanProduction(true);
    const resPlanProduction =
      await PlanProductionController.getPlanProductionMillesime(
        context.millesime.idmillesime,
      );
    context.planProduction.setPlanProductionParcelle(resPlanProduction);
    const resOrganismesStockeur =
      await OrganismeStockeurController.getOrganismesStockeur();
    context.setOrganismesStockeur(resOrganismesStockeur);
    setLoadingPlanProduction(false);
  };

  useEffect(() => {
    loadData();

    if (getQueryParam("bilan") === "true") {
      setIsBilanParOrganismeStockeurOpen(true);
    }

    if (getQueryParam("parcelle") === "true") {
      setIsListParParcelleOpen(true);
    }

    if (getQueryParam("paros") === "true") {
      setIsListParOrganismeStockeurOpen(true);
    }
  }, []);

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Plan de production</h2>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginLeft: 10,
          }}
        >
          {!loadingPlanProduction && (
            <>
              <Button
                style={{ marginLeft: 10 }}
                color="primary"
                onClick={() => {
                  setQueryParam("bilan", "true");
                  setIsBilanParOrganismeStockeurOpen(true);
                }}
              >
                Bilan par organismes stockeurs
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                color="primary"
                onClick={() => {
                  setQueryParam("paros", "true");
                  setIsListParOrganismeStockeurOpen(true);
                }}
              >
                Liste par organismes stockeurs
              </Button>
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  setQueryParam("parcelle", "true");
                  setIsListParParcelleOpen(true);
                }}
              >
                Liste par parcelles
              </Button>
            </>
          )}
        </Col>
      </Row>

      {loadingPlanProduction && <SpinLoadingAnimation />}

      {!loadingPlanProduction && (
        <RenderIf
          isTrue={context.utilisateur.hasDroits(
            droits.typesdroits.GESTION_COMMANDE,
            droits.modalite.creation,
          )}
        >
          <Row style={{ marginBottom: 30 }}>
            <Col md={8}>
              <RecapCommandeIndustrielle />
            </Col>
          </Row>
        </RenderIf>
      )}

      <Collapse isOpen={isListParParcelleOpen} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col>
            {!loadingPlanProduction && (
              <ListeParParcelle
                close={() => {
                  deleteQueryParam("parcelle");
                  setIsListParParcelleOpen(false);
                  GoToTopPage();
                }}
              />
            )}
          </Col>
        </Row>
      </Collapse>
      <Collapse
        isOpen={isListParOrganismeStockeurOpen}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <RenderIf isTrue={context.organismesStockeur.length > 0}>
              <ListeParOrganismeStockeur
                close={() => {
                  deleteQueryParam("paros");
                  deleteQueryParam("idos");
                  setIsListParOrganismeStockeurOpen(false);
                  GoToTopPage();
                }}
              />
            </RenderIf>
          </Col>
        </Row>
      </Collapse>
      <Collapse
        isOpen={isBilanParOrganismeStockeurOpen}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <BilanParOganismeStockeur
              close={() => {
                deleteQueryParam("bilan");
                setIsBilanParOrganismeStockeurOpen(false);
                GoToTopPage();
              }}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
