import AuthService from "../../../config/AuthService";
import droits from "../../../config/CodesDroits";
import {
  cellStyleDefault,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterSurface,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Numéro du contrat",
    field: "numerocontrat",
    minWidth: 170,
    flex: 1,
  },
  {
    headerName: "Exploitation",
    children: [
      {
        headerName: "SIRET",
        field: "siret",
        minWidth: 180,
        flex: 1,
      },
      {
        headerName: "Raison sociale",
        field: "raisonsociale",
        minWidth: 300,
        flex: 1,
      },
      {
        headerName: "Statut juridique",
        field: "libellestatutjuridique",
        minWidth: 150,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Code DC",
        field: "codedc",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Code postal",
        field: "codepostal",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
      {
        headerName: "Commune",
        field: "nomcommune",
        minWidth: 200,
        flex: 1,
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Variété",
    field: "libellevariete",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface réelle printemps (ha)",
        field: "surfacereelleprintemps",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface réelle automne (ha)",
        field: "surfacereelleautomne",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface réelle totale (ha)",
        field: "surfacereelletotale",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
      },
    ],
  },
  {
    headerName: "Silo définitif",
    field: "idsilodefinitif",
    flex: 1,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 26),
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [],
    },
    valueGetter: (params) => params.data.idsilodefinitif,
    cellRenderer: (params) => params.data.nomsilodefinitif ?? "PAS DE SILO",
    filterValueGetter: (params) =>
      params.data.nomsilodefinitif ?? "PAS DE SILO",
    cellStyle: (params) => {
      if (params.data.siloreprismillesimeprecedent) {
        return cellStyleWarning;
      }
      return cellStyleDefault;
    },
    columnGroupShow: "open",
  },
  {
    headerName: "Quantité livrée",
    field: "quantitelivree",
    minWidth: 200,
    flex: 1,
    editable:
      AuthService.isLoggedIn() &&
      (AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30) ||
        AuthService.hasProfil(droits.profils.RESPO_OS)),
    cellStyle: (params) => {
      if (params.value != null) {
        return cellStyleDefault;
      }
      return cellStyleWarning;
    },
    cellRenderer: (params) => params.value ?? "Non renseigné",
  },
];
