import AuthService from "../../../config/AuthService";
import droits from "../../../config/CodesDroits";
import {
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleSuccess,
  cellStyleSwitch,
} from "../../../config/gridUtils";

export const columnsLivraison = [
  {
    headerName: "Silo",
    field: "nomsilo",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
  },
  {
    headerName: "Actif",
    field: "actif",
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(droits.typesdroits.SILOS_LIVRAISON, 30),
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          value: true,
          label: "OUI",
        },
        {
          value: false,
          label: "NON",
        },
      ],
    },
    cellStyle: function (params: any) {
      return cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: cellStyleSuccess,
        },
        {
          value: false,
          cellStyle: cellStyleDanger,
        },
        {
          value: null,
          cellStyle: cellStyleDanger,
        },
      ]);
    },
    cellRenderer: (params: any) => {
      return cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: "NON",
        },
      ]);
    },
  },
];

export const columnsDefinitif = [
  {
    headerName: "Silo",
    field: "nomsilo",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
  },
  {
    headerName: "Actif",
    field: "actif",
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(droits.typesdroits.SILOS_DEFINITIFS, 30),
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          value: true,
          label: "OUI",
        },
        {
          value: false,
          label: "NON",
        },
      ],
    },
    cellStyle: function (params: any) {
      return cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: cellStyleSuccess,
        },
        {
          value: false,
          cellStyle: cellStyleDanger,
        },
        {
          value: null,
          cellStyle: cellStyleDanger,
        },
      ]);
    },
    cellRenderer: (params: any) => {
      return cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: "NON",
        },
      ]);
    },
  },
];
