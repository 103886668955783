import { Table } from "reactstrap";
import Fichier from "./Fichier";
import { downloadFile, RenderIf } from "../../config/utils";
import FichierController from "../../config/apiUtils/FichierController";
import { useState } from "react";
import SpinLoadingAnimation from "../SpinLoadingAnimation";

export default function TableDocuments(props) {
  const [loading, setLoading] = useState(false);
  const renderFichiers = () => {
    return props.documents.map((fichier, key) => {
      return (
        <Fichier
          key={key}
          filename={fichier.nomdocument}
          filesize={fichier.taille}
          lastupdate="12/03/2022"
          libelletypedocument={fichier.libelletypedocument}
          iddocument={fichier.iddocument}
          onClickDownload={async () => {
            setLoading(true);
            const res = await FichierController.downloadDocument(
              fichier.iddocument,
            );
            downloadFile(res, fichier.nomdocument, "application/octet-stream");
            setLoading(false);
          }}
          onClickDeleteDocument={props.onClickDeleteDocument}
        />
      );
    });
  };

  return (
    <>
      <RenderIf isTrue={loading}>
        <SpinLoadingAnimation />
      </RenderIf>
      <RenderIf isTrue={!loading}>
        <Table style={{ color: "#FFFFFF" }}>
          <thead>
            <tr>
              <th></th>
              <th>Type de document</th>
              <th>Nom du fichier</th>
              <th>Taille du fichier</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderFichiers()}</tbody>
        </Table>
      </RenderIf>
    </>
  );
}
