import APIController from "../APIController";

class DocumentController extends APIController {
  constructor() {
    super("/document");
  }

  async getFicheIdentificationRecolte(
    idexploitation: number,
    millesime: number,
  ) {
    return await this.getRequest(
      `/ficheidentificationrecolte?idexploitation=${idexploitation}&millesime=${millesime}`,
      {
        responseType: "blob",
      },
    );
  }

  async getAttestationLivraisonSemences(
    idexploitation: number,
    millesime: number,
  ) {
    return await this.getRequest(
      `/attestationlivraisonsemence?idexploitation=${idexploitation}&millesime=${millesime}`,
      {
        responseType: "blob",
      },
    );
  }

  async getAttestationPrix(idexploitation: number, millesime: number) {
    return await this.getRequest(
      `/attestationprix?idexploitation=${idexploitation}&millesime=${millesime}`,
      {
        responseType: "blob",
      },
    );
  }

  async getContrat(idcontrat: number) {
    return await this.getRequest(`/contrat?idcontrat=${idcontrat}`, {
      responseType: "blob",
    });
  }

  async getAnnexe(idannexe: number) {
    return await this.getRequest(`/annexe?idannexe=${idannexe}`, {
      responseType: "blob",
    });
  }

  async getContrats(idcontrats: number[]) {
    let params = "";
    idcontrats.forEach((idcontrat) => (params += `idcontrats=${idcontrat}&`));
    params.slice(0, -1);
    return await this.getRequest(`/contrats?${params}`, {
      responseType: "blob",
    });
  }

  async getConvention(idconvention: number) {
    return await this.getRequest(`/convention?idconvention=${idconvention}`, {
      responseType: "blob",
    });
  }

  async getConventions(idconventions: number[]) {
    let params = "";
    idconventions.forEach(
      (idconvention) => (params += `idconventions=${idconvention}&`),
    );
    params.slice(0, -1);
    return await this.getRequest(`/conventions?${params}`, {
      responseType: "blob",
    });
  }
}

export default new DocumentController();
