import APIController from "../APIController";
import { ICommune } from "src/config/types/commune";
import { IGeoJson } from "src/config/types/geojson";

class CommuneController extends APIController {
  constructor() {
    super("/commune");
  }

  async getCommunes(): Promise<ICommune[]> {
    return await this.getRequest("/");
  }

  async getCommuneByIdCommune(idcommune: number): Promise<ICommune> {
    return await this.getRequest(`/${idcommune}`);
  }

  async getGeometrieCommune(idcommune: number): Promise<IGeoJson> {
    return await this.getRequest(`/${idcommune}/geometrie`);
  }

  async getCommunesByCodePostal(codePostal: string): Promise<ICommune[]> {
    return await this.getRequest(`/codepostal/${codePostal}`);
  }

  async getCommunesByNomcommune(nomcommune: string): Promise<ICommune[]> {
    return await this.getRequest(`/nomcommune/${nomcommune}`);
  }

  async getCommuneByCodeinsee(codeinsee: number): Promise<ICommune> {
    return await this.getRequest(`/codeinsee/${codeinsee}`);
  }
}

export default new CommuneController();
