import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useContext, useState } from "react";
import useConstructor from "../config/hooks/useConstructor";
import { columns } from "../components/Grids/ChangeExploitation/changeexploitation";
import ExploitationController from "../config/apiUtils/ExploitationController";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import { useNavigate } from "react-router-dom";
import StoreContext from "../context/StoreContext";
import { createNotification } from "../config/utils";
import AgGrid from "../components/AgGrid";
import { IExploitation } from "../config/types/exploitation";
import { RowClickedEvent } from "ag-grid-community";

export default function ChangeExploitation(): React.JSX.Element {
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const [exploitations, setExploitations] = useState<IExploitation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useConstructor(async () => {
    setLoading(true);
    const resExploitations = await ExploitationController.getExploitations();
    setExploitations(resExploitations);
    setLoading(false);
  });

  const onRowClick = async (params: RowClickedEvent) => {
    setLoading(true);
    await ExploitationController.changeExploitation(params.data.idexploitation);
    await store.loadData();
    createNotification(
      "success",
      "Changement de dossier effectué",
      "Vous travaillez maintenant sur le dossier " + params.data.raisonsociale,
    );
    setTimeout(() => {
      navigate("/app/exploitation");
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Changer d'exploitation</h2>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <Card>
            <CardBody style={{ padding: 0 }}>
              {loading && <SpinLoadingAnimation />}
              {exploitations !== null &&
                exploitations !== undefined &&
                !loading && (
                  <AgGrid
                    onRowClicked={onRowClick}
                    columnDefs={columns}
                    rowData={exploitations}
                    showFilterLine
                  />
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
