import { ReactComponent as AdminIcon } from "src/assets/img/sidebar/admin.svg?react";
import { ReactComponent as CheckListIcon } from "src/assets/img/sidebar/check-list.svg?react";
import { ReactComponent as ContractIcon } from "src/assets/img/sidebar/contract.svg?react";
import { ReactComponent as CultureIcon } from "src/assets/img/sidebar/culture.svg?react";
import { ReactComponent as FarmIcon } from "src/assets/img/sidebar/farm.svg?react";
import { ReactComponent as FarmerIcon } from "src/assets/img/sidebar/farmer.svg?react";
import { ReactComponent as IntentionSemisIcon } from "src/assets/img/sidebar/intentionsemis.svg?react";
import { ReactComponent as MapIcon } from "src/assets/img/sidebar/map.svg?react";
import { ReactComponent as MoneyIcon } from "src/assets/img/icons/money.svg?react";
import { ReactComponent as MustardIcon } from "src/assets/img/sidebar/mustard.svg?react";
import { ReactComponent as SelectionMapIcon } from "src/assets/img/sidebar/selection-map.svg?react";
import { ReactComponent as SheetIcon } from "src/assets/img/sidebar/sheet.svg?react";
import { ReactComponent as TreeCityIcon } from "src/assets/img/sidebar/tree-city.svg?react";
import { ReactComponent as TruckIcon } from "src/assets/img/sidebar/truck.svg?react";
import { ReactComponent as UserIcon } from "src/assets/img/sidebar/user.svg?react";

const icons = {
  AdminIcon,
  CheckListIcon,
  ContractIcon,
  CultureIcon,
  FarmIcon,
  FarmerIcon,
  IntentionSemisIcon,
  MapIcon,
  MoneyIcon,
  MustardIcon,
  SelectionMapIcon,
  SheetIcon,
  TreeCityIcon,
  TruckIcon,
  UserIcon,
};

export default icons;
