import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import CommandeController from "../../config/apiUtils/CommandeController";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import { RenderIf } from "../../config/utils";
import { ICommande } from "src/config/types/commande";

export default function RecapCommandeIndustrielle(): React.JSX.Element {
  const store = useContext(StoreContext);
  const [commande, setCommande] = useState<ICommande | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadCommandes = async () => {
    const resCommandes = await CommandeController.getCommandes(
      store.millesime.idmillesime,
    );
    setCommande(resCommandes[0]);
    setIsLoading(false);
  };

  const renderLoading = () => {
    return <SpinLoadingAnimation />;
  };

  useConstructor(async () => {
    setIsLoading(true);
    await loadCommandes();
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Commande des industriels</CardTitle>
      </CardHeader>
      <CardBody>
        {isLoading && renderLoading()}
        {!isLoading && (
          <>
            <RenderIf isTrue={commande == null}>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  Pas de commande en {store.millesime.idmillesime}
                </Col>
              </Row>
            </RenderIf>
            <RenderIf isTrue={commande != null}>
              <h5>Pour la récolte {store.millesime.idmillesime} : </h5>
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>
                  <tr>
                    <th scope="row">Total commandé</th>
                    <td>{commande?.tonnage} t</td>
                  </tr>
                  <tr>
                    <th scope="row">Total rendement estimé</th>
                    <td>{commande?.estimationrendement} t/ha</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface à implanter</th>
                    <td>{commande?.surface} ha</td>
                  </tr>
                  {commande?.surfacerestante && (
                    <tr>
                      <th scope="row">
                        {commande?.surfacerestante < 0
                          ? "Surface contractualisée en surplus"
                          : "Surface restante à contractualiser"}
                      </th>
                      <td>{Math.abs(commande?.surfacerestante)} ha</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </RenderIf>
          </>
        )}
      </CardBody>
    </Card>
  );
}
