//On importe les photos directement du dossier background
import background12 from "../assets/img/background/background-12.webp";
import background13 from "../assets/img/background/background-13.webp";
import background14 from "../assets/img/background/background-14.webp";
import background15 from "../assets/img/background/background-15.webp";
import background16 from "../assets/img/background/background-16.webp";
import background17 from "../assets/img/background/background-17.webp";

//On forme un tableau avec ces images, qu'on exporte pour obtenir un background aléatoire

const backgrounds = [
  background12,
  background13,
  background14,
  background15,
  background16,
  background17,
];

export default backgrounds;
