import { valueFormatterSurface } from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Nom de la parcelle",
    field: "nomparcelle",
  },
  {
    headerName: "Type de sol",
    field: "libelletypesol",
  },
  {
    headerName: "Numéro parcelle",
    field: "numeroparcelle",
  },
  {
    headerName: "Commune",
    children: [
      {
        headerName: "Commune",
        field: "nomcommune",
        valueGetter: (params) =>
          `${params.data.codepostal} ${params.data.nomcommune}`,
        columnGroupShow: "closed",
      },
      {
        headerName: "Code postal",
        field: "codepostal",
        columnGroupShow: "open",
      },
      {
        headerName: "Commune",
        field: "nomcommune",
        columnGroupShow: "open",
      },
    ],
  },

  {
    headerName: "Informations",
    field: "informationparcelle",
  },
  {
    headerName: "Surface",
    field: "surface",
    valueFormatter: valueFormatterSurface,
  },
];
