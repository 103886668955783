import React, { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import AnalysesController from "../../config/apiUtils/AnalysesController";
import moment from "moment";
import { columns } from "../../components/Grids/Analyses/livraisonsnonsaisies";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import Analyses from "../Analyses";
import CsvButton from "../../components/Buttons/CsvButton";
import AgGrid from "../../components/AgGrid";
import { getColumnsMapForCsv } from "../../config/gridUtils";
import { ILivraisonsNonSaisies } from "../../config/types/analyses";

export default function LivraisonsNonSaisies(): React.JSX.Element {
  const store = useContext(StoreContext);

  const [data, setData] = useState<ILivraisonsNonSaisies[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useConstructor(async () => {
    setLoading(true);
    const res = await AnalysesController.getLivraisonsNonSaisies(
      store.millesime.idmillesime,
    );
    setData(res);
    setLoading(false);
  });

  return (
    <Analyses>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>Livraisons non saisies</CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={data.length > 0}>
                    <CsvButton
                      color="primary"
                      onClick={() => {
                        const name =
                          "analyses_livraisons_non_saisies_" +
                          moment().format("YYYY-MM-DD");
                        downloadCsv(
                          convertToCSV(data, ";", getColumnsMapForCsv(columns)),
                          name,
                        );
                        createNotification(
                          "info",
                          "Information",
                          "Export téléchargé dans le fichier : " + name,
                        );
                      }}
                    />
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <AgGrid rowData={data} columnDefs={columns} showFilterLine />
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Analyses>
  );
}
