import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import SiloController from "../../config/apiUtils/SiloController";
import CloseButton from "../../components/Buttons/CloseButton";
import CommuneInput from "../../components/CommuneInput";
import StoreContext from "../../context/StoreContext";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf } from "../../config/utils";
import useConstructor from "../../config/hooks/useConstructor";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";

export default function FormUpdateSilo(props) {
  const store = useContext(StoreContext);
  const [silo, setSilo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (props.idsilo === "-1") {
      return;
    }
    SiloController.getSiloById(props.idsilo, store.millesime.idmillesime).then(
      (res) => {
        setSilo(res);
        setLoading(false);
      },
    );
  }, [props.idsilo]);

  useConstructor(async () => {
    setLoading(true);
    const res = await SiloController.getSiloById(
      props.idsilo,
      store.millesime.idmillesime,
    );
    setSilo(res);
    setLoading(false);
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    const form = {
      idcommune: event.target.idcommune.value,
      nomsilo: event.target.nomsilo.value,
      actif: event.target.actif.value,
      millesime: store.millesime.idmillesime,
    };
    const res = await SiloController.updateSilo(props.idsilo, form);
    if (res.status === 200) {
      props.onClickClose();
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Modification du silo</CardTitle>
              <CloseButton onClick={props.onClickClose} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Form onSubmit={onSubmit}>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <CommuneInput
                    codepostal={silo?.codepostal}
                    idcommune={silo?.idcommune}
                    setIdCommune={() => {}}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col md="6">
                  <Label for="nomsilo">Nom du silo</Label>
                  <Input
                    name="nomsilo"
                    type="text"
                    placeholder="Nom du silo"
                    defaultValue={silo?.nomsilo}
                  />
                </Col>
                <Col md="6">
                  <Label for="actif">Actif ?</Label>
                  <Input
                    name="actif"
                    type="select"
                    className="select-input"
                    defaultValue={silo?.actif}
                  >
                    <option value={true}>OUI</option>
                    <option value={false}>NON</option>
                  </Input>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <ValidateButton style={{ marginRight: 15 }} />
                  <CancelButton
                    style={{ marginRight: 15 }}
                    onClick={props.onClickClose}
                  />
                </Col>
              </Row>
            </Form>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
