import APIController from "../APIController";
import {
  IExploitation,
  IExploitationPOST,
  IExploitationPUT,
  IFicheExploitation,
} from "../types/exploitation";
import { AxiosResponse } from "axios";
import {
  IListIdParcellesPOST,
  IParcelle,
  IParcelleGeometriePUT,
  IParcellePOST,
  IParcellePUT,
} from "../types/parcelle";
import { IGeoJson } from "../types/geojson";
import { ICertificationIgpPOST } from "../types/certificationigp";
import { IContrat } from "../types/contrat";

class ExploitationController extends APIController {
  constructor() {
    super("/exploitation");
  }

  async getDernierDossierUtilisateur(): Promise<IExploitation> {
    return await this.getRequest("/last");
  }

  async getExploitations(): Promise<IExploitation[]> {
    return await this.getRequest("/");
  }

  async getExploitationByIdexploitation(
    idexploitation: number,
  ): Promise<IExploitation> {
    return await this.getRequest(`/${idexploitation}`);
  }

  async createExploitation(
    exploitation: IExploitationPOST,
  ): Promise<AxiosResponse<IExploitation>> {
    return await this.postRequest("/", exploitation);
  }

  async getFicheExploitation(millesime: number): Promise<IFicheExploitation> {
    return await this.getRequest(`/fiche?millesime=${millesime}`);
  }

  async updateExploitation(
    idexploitation: number,
    exploitation: IExploitationPUT,
    millesime: number,
  ) {
    return await this.putRequest(
      `/${idexploitation}?millesime=${millesime}`,
      exploitation,
    );
  }

  async changeExploitation(idexploitation: number) {
    const res = await this.putRequest(`/change/${idexploitation}`);

    return await res.data;
  }

  async desactivateExploitation(idexploitation: number) {
    return await this.deleteRequest(`/${idexploitation}`);
  }

  async getAllExploitations(
    raisonsociale: string,
    siret: string,
    idorganismestockeur: number,
  ) {
    return await this.getRequest("/all", {
      params: {
        raisonsociale: raisonsociale,
        siret: siret,
        idorganismestockeur: idorganismestockeur,
      },
    });
  }

  async getConventionExploitation(millesime: number, idexploitation: number) {
    return await this.getRequest(
      `/${idexploitation}/conventions?millesime=${millesime}`,
    );
  }

  async getContratExploitation(
    millesime: number,
    idexploitation: number,
  ): Promise<IContrat[]> {
    return await this.getRequest(
      `/${idexploitation}/contrats?millesime=${millesime}`,
    );
  }

  async updateRendementRefExploitation(
    rendement: number,
    idexploitation: number,
  ) {
    return await this.putRequest(`/${idexploitation}/rendementref`, rendement);
  }

  async insertRendementRefExploitation(
    rendement: number,
    idexploitation: number,
  ) {
    return await this.postRequest(`/${idexploitation}/rendementref`, rendement);
  }

  async deleteRendementRefExploitation(
    idexploitation: number,
    idrendementrefexploitation: number,
  ) {
    return await this.deleteRequest(
      `/${idexploitation}/rendementref/${idrendementrefexploitation}`,
    );
  }

  async getZonesProductionByIdexploitation(idexploitation: number) {
    return await this.getRequest(`/${idexploitation}/zoneproduction`);
  }

  async getParcellesByIdexploitation(
    idexploitation: number,
    millesime: number,
  ): Promise<IParcelle[]> {
    return await this.getRequest(
      `/${idexploitation}/parcelles?millesime=${millesime}`,
    );
  }

  async getParcelleToImportFromLastMillesime(
    idexploitation: number,
    millesime: number,
  ) {
    return await this.getRequest(
      `/${idexploitation}/parcelles/importlastmillesime?millesime=${millesime}`,
    );
  }

  async importParcellesToMillesime(
    idexploitation: number,
    millesime: number,
    idparcelles: IListIdParcellesPOST,
  ) {
    return await this.postRequest(
      `/${idexploitation}/parcelles/importlastmillesime?millesime=${millesime}`,
      idparcelles,
    );
  }

  async insertParcelleExploitation(
    idexploitation: number,
    millesime: number,
    parcelle: IParcellePOST,
  ) {
    return await this.postRequest(
      `/${idexploitation}/parcelles?millesime=${millesime}`,
      parcelle,
    );
  }

  async updateParcelleExploitation(
    idexploitation: number,
    idparcelle: number,
    parcelle: IParcellePUT,
  ) {
    return await this.putRequest(
      `/${idexploitation}/parcelles/${idparcelle}`,
      parcelle,
    );
  }

  async updateGeometrieParcelle(
    idexploitation: number,
    idparcelle: number,
    parcelle: IParcelleGeometriePUT,
  ) {
    return await this.putRequest(
      `/${idexploitation}/parcelles/${idparcelle}/geometrie`,
      parcelle,
    );
  }

  async appropriateParcelleExploitation(
    idexploitation: number,
    millesime: number,
    idparcelle: number,
  ) {
    return await this.postRequest(
      `/${idexploitation}/parcelles/${idparcelle}?millesime=${millesime}`,
    );
  }

  async associerParcelleExploitation(
    idexploitation: number,
    millesime: number,
    idparcelle: number,
    feature: IGeoJson,
  ) {
    return await this.postRequest(
      `/${idexploitation}/parcelles/${idparcelle}/associer?millesime=${millesime}`,
      feature,
    );
  }

  async habilitationIgp(
    idexploitation: number,
    certification: ICertificationIgpPOST,
  ) {
    return await this.postRequest(
      `/${idexploitation}/certificationsigp/habilitation`,
      certification,
    );
  }
}

export default new ExploitationController();
