import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { Mailto } from "../../config/utils";
import React from "react";

export default function MentionsLegalesCard(): React.JSX.Element {
  const renderLink = (href: string, libelle: string | null = null) => {
    return (
      <a
        style={{ textDecoration: "none", color: "#f9aa33" }}
        href={href ?? window.location.origin}
        target="_blank"
        rel="noreferrer"
      >
        {libelle ?? href}
      </a>
    );
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Mentions légales</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
        </Row>
      </CardHeader>
      <CardBody>
        <h2>1 - Présentation du site</h2>
        <p>
          Conformément aux dispositions des articles 6-III et 19 de la Loi n°
          2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique,
          dite L.C.E.N., nous portons à la connaissance des utilisateurs et
          visiteurs du site : {renderLink(window.location.origin)} les
          informations suivantes :
        </p>
        <h4>Informations légales</h4>
        Nom de l'organisme : APGMB
        <br />
        Adresse : 1, Rue des Coulots - 21110 BRETENIÈRE
        <br />
        Téléphone : 03 80 68 66 77
        <br />
        SIRET : 815 243 282 00028 immatriculée au R.C.S. de Dijon
        <br />
        APE : 9499Z Autres organisations fonctionnant par adhésion volontaire
        <br />
        Numéro de TVA intercommunautaire : FR 85 815243282
        <br />
        Adresse de courrier électronique : contact@apgmb.fr <br />
        <br />
        Le Créateur du site est : Alexandre BILLON
        <br />
        Le Responsable de la publication est : Laure OHLEYER
        <br />
        Contactez le responsable de la publication :
        laure.ohleyer@cote-dor.chambagri.fr <br />
        <br />
        Le Webmaster est : Alexandre BILLON
        <br />
        Contactez le Webmaster :{" "}
        {renderLink("http://www.billonprestations.fr", "Billon Prestations")}
        <br />
        L'hébergeur du site est : OVH.com - 2, Rue Kellermann - 59100 Roubaix
        <br />
        <br />
        <h2>2 - Description des services fournis</h2>
        <p>
          Le site {renderLink(window.location.origin)} a pour objet de fournir
          la gestion documentaire de la filière moutarde gérée par l'APGMB. Le
          propriétaire du site s’efforce de fournir sur le site{" "}
          {renderLink(window.location.origin)} des informations aussi précises
          que possible. Toutefois, il ne pourra être tenue responsable des
          omissions, des inexactitudes et des carences dans la mise à jour,
          qu’elles soient de son fait ou du fait des tiers partenaires qui lui
          fournissent ces informations. Tous les informations proposées sur le
          site {renderLink(window.location.origin)} sont données à titre
          indicatif, sont non exhaustives, et sont susceptibles d’évoluer. Elles
          sont données sous réserve de modifications ayant été apportées depuis
          leur mise en ligne.
        </p>
        <br />
        <h2>3 - Propriété intellectuelle et contrefaçons</h2>
        <p>
          Le propriétaire du site est propriétaire des droits de propriété
          intellectuelle ou détient les droits d’usage sur tous les éléments
          accessibles sur le site, notamment les textes, images, graphismes,
          logo, icônes, sons, logiciels… Toute reproduction, représentation,
          modification, publication, adaptation totale ou partielle des éléments
          du site, quel que soit le moyen ou le procédé utilisé, est interdite,
          sauf autorisation écrite préalable à l'email :{" "}
          <Mailto
            style={{ textDecoration: "none", color: "#f9aa33" }}
            email="contact@apgmb.fr"
            subject="Ecodim - Mentions légales"
          >
            contact@apgmb.fr
          </Mailto>
          . Toute exploitation non autorisée du site ou de l’un des éléments
          qu’il contient sera considérée comme constitutive d’une contrefaçon et
          poursuivie conformément aux dispositions des articles L.335-2 et
          suivants du Code de Propriété Intellectuelle.
        </p>
        <br />
        <h2>4 - Liens hypertextes et cookies</h2>
        <p>
          Le site {renderLink(window.location.origin)} contient un certain
          nombre de liens hypertextes vers d’autres sites (partenaires,
          informations…) mis en place avec l’autorisation du propriétaire du
          site. Cependant, le propriétaire du site n’a pas la possibilité de
          vérifier le contenu des sites ainsi visités et décline donc toute
          responsabilité de ce fait quant aux risques éventuels de contenus
          illicites. L’utilisateur est informé que lors de ses visites sur le
          site {renderLink(window.location.origin)}, un ou des cookies sont
          susceptible de s’installer automatiquement sur son ordinateur. Un
          cookie est un fichier de petite taille, qui ne permet pas
          l’identification de l’utilisateur, mais qui enregistre des
          informations relatives à la navigation d’un ordinateur sur un site.{" "}
          <br />
          <br />
          Les données ainsi obtenues visent à faciliter la navigation ultérieure
          sur le site, et ont également vocation à permettre diverses mesures de
          fréquentation. Le paramétrage du logiciel de navigation permet
          d’informer de la présence de cookie et éventuellement, de refuser de
          la manière décrite à l’adresse suivante : www.cnil.fr. Le refus
          d’installation d’un cookie peut entraîner l’impossibilité d’accéder à
          certains services. L’utilisateur peut toutefois configurer son
          ordinateur de la manière suivante, pour refuser l’installation des
          cookies : Sous Internet Explorer : onglet outil / options internet.
          Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.
          Validez sur OK.
        </p>
        <br />
        <h2>
          5 - Protection des biens et des personnes - Gestion des données
          personnelles
        </h2>
        <p>
          Utilisateur : Internaute se connectant, utilisant le site susnommé :{" "}
          {renderLink(window.location.origin)}. En France, les données
          personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier
          1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code
          pénal et la Directive Européenne du 24 octobre 1995. Sur le site{" "}
          {renderLink(window.location.origin)}, le propriétaire du site ne
          collecte des informations personnelles relatives à l'utilisateur que
          pour le besoin de certains services proposés par le site{" "}
          {renderLink(window.location.origin)}. L'utilisateur fournit ces
          informations en toute connaissance de cause, notamment lorsqu'il
          procède par lui-même à leur saisie. Il est alors précisé à
          l'utilisateur du site {renderLink(window.location.origin)}{" "}
          l’obligation ou non de fournir ces informations. <br />
          <br />
          Conformément aux dispositions des articles 38 et suivants de la loi
          78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
          libertés, tout utilisateur dispose d’un droit d’accès, de
          rectification, de suppression et d’opposition aux données personnelles
          le concernant. Pour l’exercer, adressez votre demande à{" "}
          {renderLink(window.location.origin)} par email : email du webmaster ou
          en effectuant sa demande écrite et signée, accompagnée d’une copie du
          titre d’identité avec signature du titulaire de la pièce, en précisant
          l’adresse à laquelle la réponse doit être envoyée. Aucune information
          personnelle de l'utilisateur du site{" "}
          {renderLink(window.location.origin)} n'est publiée à l'insu de
          l'utilisateur, échangée, transférée, cédée ou vendue sur un support
          quelconque à des tiers. <br />
          <br />
          Seule l'hypothèse du rachat du site{" "}
          {renderLink(window.location.origin)} à le propriétaire du site et de
          ses droits permettrait la transmission des dites informations à
          l'éventuel acquéreur qui serait à son tour tenu de la même obligation
          de conservation et de modification des données vis à vis de
          l'utilisateur du site {renderLink(window.location.origin)}. Le site{" "}
          {renderLink(window.location.origin)} est en conformité avec le RGPD.
        </p>
        <br />
        <p>
          Les bases de données sont protégées par les dispositions de la loi du
          1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
          relative à la protection juridique des bases de données.
        </p>
      </CardBody>
    </Card>
  );
}
