import { useState, useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import ProfilController from "../../config/apiUtils/ProfilController";
import UtilisateurController from "../../config/apiUtils/UtilisateurController";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import CancelButton from "../../components/Buttons/CancelButton";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CommuneInput from "../../components/CommuneInput";
import { createNotification, RenderIf } from "../../config/utils";
import DeleteButton from "../../components/Buttons/DeleteButton";

export default function FormUpdateUtilisateur(props) {
  const store = useContext(StoreContext);
  const [idcommune, setIdCommune] = useState(0);
  const [idprofil, setIdprofil] = useState(props.utilisateur.idprofil);
  const [idorganismestockeur, setIdorganismestockeur] = useState(-1);
  const [organismes, setOrganismes] = useState([]);
  const [estorganismestockeur, setEstorganismestockeur] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);

  useConstructor(async () => {
    const resProfils = await ProfilController.getProfils();
    store.profils.setProfils(resProfils);
    if (
      props.utilisateur.idorganismestockeurs != null &&
      props.utilisateur.idorganismestockeurs.length > 0
    ) {
      setEstorganismestockeur(true);
      setIdorganismestockeur(props.utilisateur.idorganismestockeurs[0]);
    }

    // On set le profil initial, puis vérification si c'est un profil organisme stockeur
    const profil = resProfils.find(
      (profil) => profil.idprofil == props.utilisateur.idprofil,
    );
    if (profil) {
      setEstorganismestockeur(profil.estorganismestockeur);
    }
    setOrganismes(await OrganismeStockeurController.getOrganismesStockeur());
  });

  const onSubmitUtilisateur = async (event) => {
    event.preventDefault();
    const utilisateur = {
      nom: event.target.nom.value,
      prenom: event.target.prenom.value,
      mail: event.target.mail.value,
      username: event.target.mail.value,
      telephone: event.target.telephone.value,
      profils: [event.target.profil.value],
      adresse: event.target.adresse.value,
      idorganismestockeurs: estorganismestockeur
        ? [event.target.idorganismestockeur.value]
        : [],
      idcommune: event.target.idcommune.value,
      pays: "France",
    };

    await props.onClickUpdateUtilisateur(utilisateur);
    props.hideFormUpdateUtilisateur();
    props.onSuccessNewUtilisateur();
  };

  const renderProfilsOptions = () => {
    return store.profils.profils.map((profil, key) => {
      return (
        <option value={profil.idprofil} key={key}>
          {profil.libelleprofil}
        </option>
      );
    });
  };

  const renderOrganismesStockeursOptions = () => {
    return organismes.map((organisme, key) => {
      return (
        <option value={organisme.idorganismestockeur} key={key}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  const onClickConfirmReinitPassword = async () => {
    createNotification("info", "Information", "Réinitialisation en cours...");
    const res = await UtilisateurController.reinitPasswordUtilisateur(
      props.utilisateur.idutilisateur,
    );

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      createNotification(
        "success",
        "Succès",
        `L'utilisateur ${props.utilisateur.prenom} ${props.utilisateur.nom} peut maintenant consulter ses mails pour continuer la procédure de réinitialissation du mot de passe.`,
      );
      props.hideFormUpdateUtilisateur();
    } else {
      createNotification("error", "Erreur", "Une erreur est survenue");
    }
    setShowModalPassword(!showModalPassword);
  };

  const onClickConfirmDeleteUser = async () => {
    createNotification("info", "Information", "Suppression en cours...");
    const res = await UtilisateurController.deleteUtilisateur(
      props.utilisateur.idutilisateur,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Utilisateur supprimé avec succès",
      );
      await props.reload();
      props.close();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la suppression de l'utilisateur...",
      );
    }
    setShowModalPassword(!showModalPassword);
  };

  const renderModalConfirmationReinitPassword = () => {
    return (
      <Modal
        isOpen={showModalPassword}
        toggle={() => setShowModalPassword(!showModalPassword)}
        autoFocus={false}
      >
        <ModalHeader
          className="modal-header-form"
          toggle={() => setShowModalPassword(!showModalPassword)}
        >
          Réinitialiser le mot de passe
        </ModalHeader>
        <ModalBody className="modal-body-form">
          Vous demandez la réinitialisation du mot de passe de l'utilisateur{" "}
          <b>
            {props.utilisateur.prenom} {props.utilisateur.nom}
          </b>
          .<br />
          <br />
          Confirmez-vous cette demande de réinitialisation ?
        </ModalBody>
        <ModalFooter className="modal-footer-form">
          <Button
            color="primary"
            autoFocus={true}
            onClick={onClickConfirmReinitPassword}
          >
            Confirmer
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => setShowModalPassword(!showModalPassword)}
          >
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderModalConfirmationDeleteUser = () => {
    return (
      <Modal
        isOpen={showModalDeleteUser}
        toggle={() => setShowModalDeleteUser(!showModalDeleteUser)}
        autoFocus={false}
      >
        <ModalHeader
          className="modal-header-form"
          toggle={() => setShowModalDeleteUser(!showModalDeleteUser)}
        >
          Suppression d'utilisateur
        </ModalHeader>
        <ModalBody className="modal-body-form">
          Vous demandez la suppression de l'utilisateur{" "}
          <b>
            {props.utilisateur.prenom} {props.utilisateur.nom}
          </b>
          .<br />
          <br />
          Confirmez-vous cette demande de suppression ?
        </ModalBody>
        <ModalFooter className="modal-footer-form">
          <Button
            color="primary"
            autoFocus={true}
            onClick={onClickConfirmDeleteUser}
          >
            Confirmer
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => setShowModalDeleteUser(!showModalDeleteUser)}
          >
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  useEffect(() => {
    const profil = store.profils.profils.find(
      (profil) => profil.idprofil == idprofil,
    );
    if (profil) {
      setEstorganismestockeur(profil.estorganismestockeur);
    }
  }, [idprofil]);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>
              Modification de l'utilisateur - {props.utilisateur.prenom}{" "}
              {props.utilisateur.nom}
            </CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseButton onClick={props.close} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmitUtilisateur}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="nom">Nom</Label>
                <Input
                  type="text"
                  name="nom"
                  placeholder="Nom"
                  defaultValue={props.utilisateur.nom}
                  required
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="nom">Prénom</Label>
                <Input
                  type="text"
                  name="prenom"
                  placeholder="Prénom"
                  defaultValue={props.utilisateur.prenom}
                  required
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="nom">Adresse</Label>
                <Input
                  type="text"
                  name="adresse"
                  placeholder="Adresse"
                  defaultValue={props.utilisateur.adresse}
                  required
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <CommuneInput
                codepostal={props?.utilisateur?.codepostal}
                idcommune={props?.utilisateur?.idcommune}
                setIdCommune={() => {}}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="mail">Adresse mail</Label>
                <Input
                  type="mail"
                  name="mail"
                  placeholder="Adresse mail"
                  defaultValue={props.utilisateur.mail}
                  disabled={
                    props.utilisateur.idutilisateur ===
                    store.utilisateur.informations.idutilisateur
                  }
                  required
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="telephone">Téléphone</Label>
                <Input
                  type="text"
                  name="telephone"
                  placeholder="Téléphone"
                  defaultValue={props.utilisateur.telephone}
                  required
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={estorganismestockeur ? "6" : "12"}>
              <FormGroup>
                <Label for="profil">Profil utilisateur *</Label>
                <Input
                  type="select"
                  className="select-input"
                  defaultValue={idprofil}
                  value={idprofil}
                  onChange={(event) => setIdprofil(event.target.value)}
                  name="profil"
                  disabled={
                    props.utilisateur.idutilisateur ===
                    store.utilisateur.informations.idutilisateur
                  }
                >
                  {renderProfilsOptions()}
                </Input>
              </FormGroup>
            </Col>
            <RenderIf isTrue={estorganismestockeur}>
              <Col md={"6"}>
                <FormGroup>
                  <Label for="idorganismestockeur">Organisme stockeur</Label>
                  <Input
                    type="select"
                    className="select-input"
                    value={idorganismestockeur}
                    onChange={(event) =>
                      setIdorganismestockeur(event.target.value)
                    }
                    name="idorganismestockeur"
                  >
                    {renderOrganismesStockeursOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </RenderIf>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginRight: 15 }}
                color="danger"
                onClick={(event) => {
                  event.preventDefault();
                  props.close();
                }}
              />
              <Button
                style={{ marginTop: 15, marginRight: 15 }}
                color="info"
                onClick={(event) => {
                  event.preventDefault();
                  props.onClickAddDossier();
                }}
              >
                Affecter des dossiers
              </Button>
              <Button
                style={{
                  marginTop: 15,
                  marginRight: 15,
                }}
                color="warning"
                onClick={(event) => {
                  event.preventDefault();
                  setShowModalPassword(true);
                }}
              >
                Réinitialiser le mot de passe
              </Button>
              {renderModalConfirmationReinitPassword()}
            </Col>
            <Col md="6" style={{ display: "flex", justifyContent: "end" }}>
              <RenderIf
                isTrue={
                  props.utilisateur.idutilisateur !==
                  store.utilisateur.informations.idutilisateur
                }
              >
                {props.utilisateur.disabled && (
                  <Button
                    color="primary"
                    style={{ margin: 10 }}
                    onClick={async () => {
                      const res =
                        await UtilisateurController.activerUtilisateur(
                          props.utilisateur.idutilisateur,
                        );
                      if (res.status === 200) {
                        createNotification(
                          "success",
                          "Succès",
                          "Utilisateur activé avec succès",
                        );
                        await props.reload();
                        props.close();
                      } else {
                        createNotification(
                          "error",
                          "Erreur",
                          "Erreur lors de l'activation de l'utilisateur...",
                        );
                      }
                    }}
                  >
                    Activer l'utilisateur
                  </Button>
                )}
                {!props.utilisateur.disabled && (
                  <Button
                    color="danger"
                    style={{ margin: 10 }}
                    onClick={async () => {
                      const res =
                        await UtilisateurController.desactiverUtilisateur(
                          props.utilisateur.idutilisateur,
                        );
                      if (res.status === 200) {
                        createNotification(
                          "success",
                          "Succès",
                          "Utilisateur désactivé avec succès",
                        );
                        await props.reload();
                        props.close();
                      } else {
                        createNotification(
                          "error",
                          "Erreur",
                          "Erreur lors de la désactivation de l'utilisateur...",
                        );
                      }
                    }}
                  >
                    Désactiver l'utilisateur
                  </Button>
                )}
              </RenderIf>
              <RenderIf
                isTrue={
                  props.utilisateur.idutilisateur !==
                  store.utilisateur.informations.idutilisateur
                }
              >
                <DeleteButton
                  style={{ margin: 10 }}
                  onClick={() => {
                    setShowModalDeleteUser(true);
                  }}
                />
              </RenderIf>
              {renderModalConfirmationDeleteUser()}
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
