import Analyses from "../Analyses";
import React, { useCallback, useContext, useState } from "react";
import StoreContext from "../../context/StoreContext";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment/moment";
import useConstructor from "../../config/hooks/useConstructor";
import AnalysesController from "../../config/apiUtils/AnalysesController";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { columns } from "../../components/Grids/Analyses/tracabilitecontrat";
import AgGrid from "../../components/AgGrid";
import { getColumnsMapForCsv } from "../../config/gridUtils";
import { ITracabiliteContrats } from "../../config/types/analyses";
import { CellEditingStoppedEvent } from "ag-grid-community";

export default function TracabiliteContrats(): React.JSX.Element {
  const context = useContext(StoreContext);
  const [data, setData] = useState<ITracabiliteContrats[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = async (silent = false) => {
    if (!silent) {
      setLoading(true);
    }

    setData(
      await AnalysesController.getTracabiliteContrats(
        context.millesime.idmillesime,
      ),
    );

    if (!silent) {
      setLoading(false);
    }
  };

  useConstructor(loadData);

  const onCellEditingStopped = useCallback(
    async (event: CellEditingStoppedEvent) => {
      if (event.valueChanged && event.oldValue != event.newValue) {
        if (event.colDef.field === "datereceptiontracabilite") {
          const res = await AnalysesController.updateDateReceptionTracabilite(
            event.data.idcontrat,
            event.newValue != null
              ? moment(event.newValue).format("YYYY-MM-DD")
              : null,
          );

          if (res.status === 200) {
            createNotification(
              "success",
              "Succès",
              "Date de réception de la traçabilité modifiée avec succès",
            );
          }
        } else if (event.colDef.field === "dateverificationtracabilite") {
          const res =
            await AnalysesController.updateDateVerificationTracabilite(
              event.data.idcontrat,
              event.newValue != null
                ? moment(event.newValue).format("YYYY-MM-DD")
                : null,
            );

          if (res.status === 200) {
            createNotification(
              "success",
              "Succès",
              "Date de vérification de la traçabilité modifiée avec succès",
            );
          }
        }

        await loadData(true);
      }
    },
    [],
  );

  return (
    <Analyses>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>
                    Suivi des cultures - cotisations
                  </CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={data.length > 0}>
                    <CsvButton
                      color="primary"
                      onClick={() => {
                        const name =
                          "analyses_tracabilite_contrats_" +
                          context.millesime.idmillesime +
                          "_" +
                          moment().format("YYYY-MM-DD");
                        downloadCsv(
                          convertToCSV(data, ";", getColumnsMapForCsv(columns)),
                          name,
                        );
                        createNotification(
                          "info",
                          "Information",
                          "Export téléchargé dans le fichier : " + name,
                        );
                      }}
                    />
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <AgGrid
                  rowData={data}
                  columnDefs={columns}
                  onCellEditingStopped={onCellEditingStopped}
                  showFilterLine={true}
                  height={600}
                />
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Analyses>
  );
}
