import { atom } from "recoil";
import {
  ICulture,
  IProduit,
  ITypeProduction,
  ITypeProduit,
  ITypeSol,
} from "../types/referentiel";

export const typesProductionState = atom<ITypeProduction[]>({
  key: "types-production-state",
  default: [],
});

export const typesProduitState = atom<ITypeProduit[]>({
  key: "types-produit-state",
  default: [],
});

export const produitsState = atom<IProduit[]>({
  key: "produits-state",
  default: [],
});

export const culturesState = atom<ICulture[]>({
  key: "cultures-state",
  default: [],
});

export const typesSolState = atom<ITypeSol[]>({
  key: "types-sol-state",
  default: [],
});
