import AuthService from "../../../config/AuthService";
import droits from "../../../config/CodesDroits";
import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleSuccess,
  cellStyleWarning,
  valueFormatterDate,
} from "../../../config/gridUtils";
import moment from "moment/moment";

export const columns = [
  {
    headerName: "Exploitation",
    children: [
      {
        headerName: "Exploitation",
        field: "raisonsociale",
        flex: 3,
      },
      {
        headerName: "Numéro SIRET",
        field: "siret",
        columnGroupShow: "open",
      },
      {
        headerName: "Code OS",
        field: "codeos",
        columnGroupShow: "open",
      },
      {
        headerName: "Code DC",
        field: "codedc",
        columnGroupShow: "open",
      },
      {
        headerName: "Nom",
        field: "nom",
        valueGetter: (params) => {
          return `${params.data.nom} ${params.data.prenom}`;
        },
        flex: 2,
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Localisation",
    children: [
      {
        headerName: "Commune",
        valueGetter: (params) =>
          `${params.data.codepostal} ${params.data.nomcommune}`,
        flex: 2,
        columnGroupShow: "closed",
      },
      {
        headerName: "Adresse",
        field: "adresse",
        flex: 3,
        columnGroupShow: "open",
      },
      {
        headerName: "Commune",
        field: "nomcommune",
        flex: 3,
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Traçabilité reçue",
    field: "tracabiliterecue",
    cellDataType: "boolean",
    cellStyle: function (params) {
      if (params.value == true) {
        return cellStyleSuccess;
      } else if (params.value == false) {
        return cellStyleDanger;
      } else {
        return cellStyleDefault;
      }
    },
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Dates",
    children: [
      {
        headerName: "Date de réception de la traçabilité",
        field: "datereceptiontracabilite",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.TRACABILITE_CONTRATS,
            droits.modalite.modification,
          ),
        cellEditor: "agDateCellEditor",
        cellEditorParams: (params) => {
          return {
            value: new Date(params.value ?? moment().format("YYYY-MM-DD")),
            max: params.data.dateverificationtracabilite,
          };
        },
      },
      {
        headerName: "Date de vérification de la traçabilité",
        field: "dateverificationtracabilite",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.TRACABILITE_CONTRATS,
            droits.modalite.modification,
          ),
        cellEditor: "agDateCellEditor",
        cellEditorParams: (params) => {
          return {
            value: new Date(params.value ?? moment().format("YYYY-MM-DD")),
            min: params.data.datereceptiontracabilite,
          };
        },
      },
    ],
  },
  {
    headerName: "Cotisation payée",
    field: "cotisationpayee",
    cellDataType: "boolean",
    cellStyle: function (params) {
      if (params.value == true) {
        return cellStyleSuccess;
      } else if (params.value == false) {
        return cellStyleDanger;
      } else {
        return cellStyleDefault;
      }
    },
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Pénalité payée",
    field: "penalitepayee",
    cellDataType: "boolean",
    cellStyle: function (params) {
      if (params.value == true) {
        return cellStyleSuccess;
      } else if (params.value == false) {
        return cellStyleDanger;
      } else {
        return cellStyleWarning;
      }
    },
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "Payée",
        },
        {
          value: false,
          renderer: "Non payée",
        },
        {
          value: null,
          renderer: "Pas de pénalité",
        },
      ]),
  },
];
