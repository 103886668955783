import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { columns } from "../../components/Grids/Formation/formations";
import AgGrid from "../../components/AgGrid";

export default function TableFormation(props) {
  const onRowClick = async (params) => {
    props.onSelectFormation(params.data);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des formations</CardTitle>
            <CardSubtitle tag={"h6"}>
              Pour modifier une formation, cliquez sur la ligne associée.
            </CardSubtitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            {props.formations !== null &&
            props.formations !== [] &&
            props.formations !== undefined ? (
              <AgGrid
                columnDefs={columns}
                rowData={props.formations}
                onRowClicked={onRowClick}
                showFilterLine
              />
            ) : (
              <Alert fade={false} color="danger">
                Aucun formation renseignée
              </Alert>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
