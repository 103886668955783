import APIController from "../APIController";
import { IVersion } from "../types/version";

class VersionController extends APIController {
  constructor() {
    super("/version");
  }

  async getVersions(): Promise<IVersion[]> {
    return await this.getRequest("/");
  }
}

export default new VersionController();
