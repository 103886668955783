import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Date de demande",
    field: "datedemande",
    minWidth: 150,
    flex: 1,
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Date d'envoi",
    field: "dateenvoicertif",
    minWidth: 150,
    flex: 1,
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Date d'habilitation",
    field: "datehabilitation",
    minWidth: 150,
    flex: 1,
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Habilité",
    field: "habilite",
    minWidth: 150,
    flex: 1,
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Actif",
    field: "actif",
    minWidth: 150,
    flex: 1,
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
];
