import { Col, Row } from "reactstrap";
import React from "react";

export default function Documentation(): React.JSX.Element {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Documentation</h2>
        </Col>
      </Row>
    </>
  );
}
