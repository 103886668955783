import { NavLink } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import ListeParcelles from "./parcelles/ListeParcelles";
import layoutRoutes from "../config/layoutRoutes";
import React from "react";

export default function Parcelles(): React.JSX.Element {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Parcelles</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button color="warning" style={{ marginRight: 10 }}>
            <NavLink
              style={{ textDecoration: "none", color: "#000000" }}
              to={`${layoutRoutes.userLayout}/carto/parcellaire-exploitation`}
            >
              Carte
            </NavLink>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <ListeParcelles />
        </Col>
      </Row>
    </>
  );
}
