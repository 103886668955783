import { RenderIf } from "../../../../config/utils";
import { Input } from "reactstrap";

export default function ElementParcelle({
  parcelle,
  index,
  isParcelleSelected,
  toggleSelectParcelle,
  onClickPreviewParcelle,
  isModal,
}) {
  const getStyleTd = () =>
    isModal
      ? {
          cursor: "pointer",
          textAlign: "center",
          verticalAlign: "middle",
        }
      : { textAlign: "center", verticalAlign: "middle" };
  return (
    <tr
      key={index}
      style={isParcelleSelected() ? { backgroundColor: "#3c5e70" } : {}}
    >
      <RenderIf isTrue={isModal}>
        <td style={getStyleTd()}>
          <i
            className="fa-solid fa-map fa-lg"
            onClick={onClickPreviewParcelle}
          ></i>
        </td>
      </RenderIf>
      <td style={getStyleTd()} onClick={isModal ? toggleSelectParcelle : null}>
        {parcelle.numeroparcelle ?? "-"}
      </td>
      <td style={getStyleTd()} onClick={isModal ? toggleSelectParcelle : null}>
        {parcelle.nomparcelle}
      </td>
      <td style={getStyleTd()} onClick={isModal ? toggleSelectParcelle : null}>
        {parcelle.libelletypesol}
      </td>
      <td style={getStyleTd()} onClick={isModal ? toggleSelectParcelle : null}>
        {parcelle.libelleculture}
      </td>
      <td
        style={getStyleTd()}
        onClick={isModal ? toggleSelectParcelle : null}
      >{`${parcelle.codepostal} - ${parcelle.nomcommune}`}</td>
      <td style={getStyleTd()} onClick={isModal ? toggleSelectParcelle : null}>
        {parcelle.surfacesaisie
          ? parcelle.surfacesaisie + " Ha"
          : parcelle.surface
            ? parcelle.surface + " Ha"
            : "-"}
      </td>
      <RenderIf isTrue={isModal}>
        <td
          style={getStyleTd()}
          onClick={isModal ? toggleSelectParcelle : null}
        >
          <Input
            type={"checkbox"}
            id={`parcelle-${parcelle.idparcelle}`}
            name={`parcelle-${parcelle.idparcelle}`}
            checked={isParcelleSelected()}
            onChange={toggleSelectParcelle}
          />
        </td>
      </RenderIf>
    </tr>
  );
}
