import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import { RenderIf, createNotification } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { IContratPOST } from "../../config/types/contrat";
import { IExploitation } from "../../config/types/exploitation";
import { IOrganismeStockeur } from "../../config/types/organismestockeur";
import { ITypeProduction } from "../../config/types/referentiel";

interface IProps {
  exploitation?: IExploitation;
  idexploitation?: number;
  hideFormNewContrat: () => void;
  millesime: number;
  onClickSaveNewContrat: (contrat: IContratPOST) => void;
}

export default function FormNewContrat(props: IProps): React.JSX.Element {
  const store = useContext(StoreContext);
  const [idexploitation, setIdexploitation] = useState(
    store.exploitation.informations.idexploitation,
  );
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const totalSurface =
      event.target.surfaceprintemps.value + event.target.surfaceautomne.value;
    let hasError = false;

    if (totalSurface == null || totalSurface <= 0) {
      createNotification(
        "error",
        "Erreur - Formulaire incomplet",
        "Merci de renseigner les champs de surfaces printemps et automne.",
      );
      hasError = true;
    }

    if (event.target?.nbparcelles?.value <= 0) {
      createNotification(
        "error",
        "Erreur - Formulaire incomplet",
        "Merci d'indiquer un nombre de parcelles supérieur à 0.",
      );
      hasError = true;
    }

    if (event.target?.idorganismestockeur?.value == -1) {
      createNotification(
        "error",
        "Erreur - Formulaire incomplet",
        "Merci de sélectionner un Organisme Stockeur dans la liste déroulante.",
      );
      hasError = true;
    }

    if (!event.target.validationpriseconnaissance.checked) {
      createNotification(
        "error",
        "Erreur",
        "Merci de prendre connaissance des engagements vis-à-vis de la filière.",
      );
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    const contratForm: IContratPOST = {
      numerocontrat: event.target?.numerocontrat?.value ?? null,
      idorganismestockeur: event.target?.idorganismestockeur?.value ?? null,
      idreftypeproduction: event.target.idreftypeproduction.value,
      surfaceprintemps: event.target.surfaceprintemps.value,
      surfaceautomne: event.target.surfaceautomne.value,
      nbparcelles: event.target.nbparcelles.value,
      idexploitation: idexploitation,
      millesime: store.millesime.idmillesime,
      datecontrat: event.target.datecontrat.value,
      commentaire: event.target.commentaire.value,
      idetatvalidationadministrative:
        event.target.validationadministrative != null &&
        event.target.validationadministrative.checked
          ? store.utilisateur.profilsUtilisateur[0].estorganismestockeur
            ? 3
            : !store.utilisateur.informations.isProducteur
              ? 2
              : 1
          : 1,
    };
    await props.onClickSaveNewContrat(contratForm);
    setLoading(false);
  };

  useConstructor(async () => {
    if (store.utilisateur.informations.isProducteur) {
      createNotification(
        "info",
        "Information",
        "En remplissant ce formulaire, vous vous engagez à respectez les éléments du contrat accessibles dans la page : Exploitations / Producteurs > Mes documents",
        15000,
      );
    }
    setLoading(true);
    store.referentiels.setTypesProduction(
      await ReferentielController.getTypesProduction(true),
    );
    store.setOrganismesStockeur(
      await OrganismeStockeurController.getOrganismesStockeur(),
    );
    if (!store.utilisateur.informations.isProducteur) {
      ExploitationController.getExploitations().then((res) => {
        store.setExploitations(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  });

  const renderExploitationOptions = () => {
    if (!store.utilisateur.informations.isProducteur) {
      return store.exploitations.map(
        (exploitation: IExploitation, key: number) => {
          return (
            <option key={key} value={exploitation.idexploitation}>
              {exploitation.raisonsociale}
            </option>
          );
        },
      );
    }
    return (
      <option value={store.exploitation.informations.idexploitation}>
        {store.exploitation.informations.raisonsociale}
      </option>
    );
  };

  const renderOrganismesStockeurOption = () => {
    const organismes: IOrganismeStockeur[] = [...store.organismesStockeur];
    organismes.unshift({ idorganismestockeur: -1, nomorganismestockeur: "-" });
    return organismes.map((organisme, key) => {
      return (
        <option key={key} value={organisme.idorganismestockeur}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  const renderTypesProductionOption = () => {
    return store.referentiels.typesProduction.map(
      (typeProduction: ITypeProduction, key: number) => {
        return (
          <option key={key} value={typeProduction.idreftypeproduction}>
            {typeProduction.libelle}
          </option>
        );
      },
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouveau contrat</CardTitle>
        <CloseButton onClick={props.hideFormNewContrat} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md={store.utilisateur.informations.isProducteur ? 6 : 4}>
                <FormGroup>
                  <Label for="datecontrat">Date du contrat</Label>
                  <Input
                    type="date"
                    name="datecontrat"
                    defaultValue={moment().format("YYYY-MM-DD")}
                    required
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={store.utilisateur.informations.isProducteur ? 6 : 4}>
                <FormGroup>
                  <Label for="idexploitation">Exploitation</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idexploitation"
                    disabled={
                      props.idexploitation !== null &&
                      props.idexploitation !== undefined
                    }
                    value={props.idexploitation ?? idexploitation}
                    onChange={(event) => {
                      setIdexploitation(event.target.value);
                    }}
                    required
                  >
                    {renderExploitationOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col md="4">
                <FormGroup>
                  <Label for="idorganismestockeur">Organisme stockeur</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idorganismestockeur"
                    defaultValue={-1}
                    required
                  >
                    {renderOrganismesStockeurOption()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="idreftypeproduction">Type de production</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idreftypeproduction"
                    required
                  >
                    {renderTypesProductionOption()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="nbparcelles">Nombres de parcelles</Label>
                  <Input
                    type="number"
                    name="nbparcelles"
                    placeholder="3"
                    required
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col md="6">
                <FormGroup>
                  <Label for="surfaceprintemps">
                    Surface engagée au printemps (ha)
                  </Label>
                  <Input
                    type="text"
                    name="surfaceprintemps"
                    placeholder="12.3"
                    pattern="^\d{1,4}(?:\.\d{1,2})?$"
                    defaultValue={0}
                    required
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="surfaceautomne">
                    Surface engagée en automne (ha)
                  </Label>
                  <Input
                    type="text"
                    name="surfaceautomne"
                    placeholder="12.3"
                    pattern="^\d{1,4}(?:\.\d{1,2})?$"
                    defaultValue={0}
                    required
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col>
                <FormGroup>
                  <Label for={"commentaire"}>Commentaire</Label>
                  <Input
                    name="commentaire"
                    type="textarea"
                    placeholder="Commentaire"
                  />
                </FormGroup>
              </Col>
            </Row>
            <RenderIf isTrue={!store.utilisateur.informations.isProducteur}>
              <Row>
                <Col md={12}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="validationadministrative"
                        name="validationadministrative"
                      />{" "}
                      Validé administrativement
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </RenderIf>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                Mes engagements :{" "}
                <a
                  style={{
                    textDecoration: "none",
                    color: "#f9aa33",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  href="/app/exploitation-document"
                  title="Page des documents"
                  id="mesdocuments"
                >
                  Cliquez ici
                </a>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <Label check>
                  <Input type="checkbox" name="validationpriseconnaissance" />{" "}
                  J'ai pris connaissance de mes engagements vis-à-vis de la
                  filière, et m'engager à les respecter
                </Label>
              </Col>
            </Row>
            <ValidateButton style={{ marginTop: 15 }} />
            <CancelButton
              style={{ marginTop: 15, marginLeft: 15 }}
              onClick={(event: any) => {
                event.preventDefault();
                props.hideFormNewContrat();
              }}
            />
          </Form>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
