import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDisabled,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterSurface,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Validé",
    field: "idetatvalidationadministrative",
    minWidth: 170,
    flex: 1,
    cellStyle: function (params) {
      if (params.data.valide === true) {
        return cellStyleSuccess;
      }
      if (params.value === 0) {
        return cellStyleDisabled;
      }
      if (params.value === 1) {
        return cellStyleDanger;
      }
      return cellStyleWarning;
    },
    cellRenderer: (params) => params.data.libellevalidationadministrative,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Surface printemps",
    field: "surfacepotentielleprintemps",
    minWidth: 130,
    flex: 1,
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Surface automne",
    field: "surfacepotentielleautomne",
    minWidth: 150,
    flex: 1,
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Type de production",
    field: "libelletypeproduction",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Abandonné",
    field: "abandon",
    minWidth: 170,
    flex: 1,
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleSuccess,
      ),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Commentaire",
    field: "commentaire",
    minWidth: 150,
    flex: 1,
  },
];
