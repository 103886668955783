import APIController from "../APIController";
import {
  IBilanOS,
  IBilanRecolte,
  IPlanProduction,
} from "../types/planproduction";

class PlanProductionController extends APIController {
  constructor() {
    super("/planproduction");
  }

  async getPlanProductionMillesime(
    millesime: number,
  ): Promise<IPlanProduction[]> {
    return await this.getRequest(`/?millesime=${millesime}`);
  }

  async getPlanProductionPrevisionnelMillesime(
    millesime: number,
  ): Promise<IPlanProduction[]> {
    return await this.getRequest(`/previsionnel?millesime=${millesime}`);
  }

  async getPlanProductionPrevisionnelOrganismeStockeur(
    idorganismestockeur: number,
    millesime: number,
  ): Promise<IPlanProduction[]> {
    return await this.getRequest(
      `/previsionnel/organismestockeur/${idorganismestockeur}?millesime=${millesime}`,
    );
  }

  async getPlanProductionOrganismeStockeur(
    idorganismestockeur: number,
    millesime: number,
  ): Promise<IPlanProduction[]> {
    return await this.getRequest(
      `/organismestockeur/${idorganismestockeur}?millesime=${millesime}`,
    );
  }

  async getDetailsContrats(idcontrats: number[]): Promise<IPlanProduction[]> {
    return await this.getRequest(
      `/detail?${idcontrats
        .map((idcontrat) => "idcontrats=" + idcontrat)
        .join("&")}`,
    );
  }

  async getBilanOrganismeStockeur(millesime: number): Promise<IBilanOS[]> {
    return await this.getRequest("/bilan/organismestockeur", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getBilanRecolte(millesime: number): Promise<IBilanRecolte[]> {
    return await this.getRequest("/bilanrecolte", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getBilanRecolteByIdorganismestockeur(
    millesime: number,
    idorganismestockeur: number,
  ): Promise<IBilanRecolte[]> {
    return await this.getRequest(`/bilanrecolte/${idorganismestockeur}`, {
      params: {
        millesime: millesime,
      },
    });
  }
}

export default new PlanProductionController();
