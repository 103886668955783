import React, { useContext, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import useConstructor from "../config/hooks/useConstructor";
import {
  deleteQueryParam,
  getQueryParam,
  setQueryParam,
} from "../config/utils";
import StoreContext from "../context/StoreContext";
import TableBilanAnnuelExploitation from "./bilanannuel/TableBilanAnnuelExploitation";
import TableBilanAnnuelOrganismeStockeur from "./bilanannuel/TableBilanAnnuelOrganismeStockeur";

export default function BilanAnnuel(): React.JSX.Element {
  const store = useContext(StoreContext);

  document.title = `Bilan annuel ${store.millesime.idmillesime} | Ecodim`;

  const [showBilanOrganismeStockeur, setShowBilanOrganismeStockeur] =
    useState<boolean>(false);
  const [showBilanExploitation, setShowBilanExploitation] =
    useState<boolean>(false);

  useConstructor(() => {
    if (getQueryParam("exploitation") === "true") {
      setShowBilanExploitation(true);
    }
    if (getQueryParam("paros") === "true") {
      setShowBilanOrganismeStockeur(true);
    }
  });

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Bilan annuel {store.millesime.idmillesime}</h2>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginLeft: 10,
          }}
        >
          <Button
            style={{ marginLeft: 10 }}
            color="primary"
            onClick={() => {
              setQueryParam("paros", "true");
              setShowBilanOrganismeStockeur(true);
            }}
          >
            Bilan par organisme stockeur
          </Button>
          <Button
            color="primary"
            style={{ marginLeft: 10 }}
            onClick={() => {
              setQueryParam("exploitation", "true");
              setShowBilanExploitation(true);
            }}
          >
            Bilan par exploitation
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={showBilanExploitation} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <TableBilanAnnuelExploitation
              onClickClose={() => {
                setShowBilanExploitation(false);
                deleteQueryParam("exploitation");
              }}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={showBilanOrganismeStockeur} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <TableBilanAnnuelOrganismeStockeur
              onClickClose={() => {
                setShowBilanOrganismeStockeur(false);
                deleteQueryParam("paros");
                deleteQueryParam("idos");
              }}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
