import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleSuccess,
  cellStyleSwitch,
  valueFormatterDate,
  valueFormatterSurface,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Intention de semis",
    children: [
      {
        headerName: "Intention de semis printemps",
        field: "surfacepotentielleprintemps",
        columnGroupShow: "open",
        valueFormatter: valueFormatterSurface,
      },
      {
        headerName: "Intention de semis automne",
        field: "surfacepotentielleautomne",
        columnGroupShow: "open",
        valueFormatter: valueFormatterSurface,
      },
      {
        headerName: "Intention de semis totale",
        field: "surfacepotentielletotale",
        columnGroupShow: "closed",
        valueFormatter: valueFormatterSurface,
      },
    ],
  },
  {
    headerName: "Surface engagée",
    children: [
      {
        headerName: "Surface engagée printemps",
        field: "surfaceprintemps",
        columnGroupShow: "open",
        valueFormatter: valueFormatterSurface,
      },
      {
        headerName: "Surface engagée automne",
        field: "surfaceautomne",
        columnGroupShow: "open",
        valueFormatter: valueFormatterSurface,
      },
      {
        headerName: "Surface engagée totale",
        field: "surfacetotale",
        columnGroupShow: "closed",
        valueFormatter: valueFormatterSurface,
      },
    ],
  },
  {
    headerName: "Numéro de contrat",
    field: "numerocontrat",
  },
  {
    headerName: "Convention signée",
    field: "signee",
    cellStyle: (params) =>
      cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: cellStyleSuccess,
        },
        {
          value: false,
          cellStyle: cellStyleDanger,
        },
        {
          value: null,
          cellStyle: cellStyleDefault,
        },
      ]),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Dates",
    children: [
      {
        headerName: "Date d'envoi",
        field: "dateenvoi",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
      },
      {
        headerName: "Date de retour",
        field: "dateretour",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
      },
    ],
  },
];
