import APIController from "../APIController";
import { ICivilite } from "src/config/types/civilite";

class CiviliteController extends APIController {
  constructor() {
    super("/civilite");
  }

  async getCivilites(): Promise<ICivilite[]> {
    return await this.getRequest("/");
  }
}

export default new CiviliteController();
