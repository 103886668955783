import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";
import {
  RenderIf,
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
  createNotification,
} from "../config/utils";
import useConstructor from "../config/hooks/useConstructor";
import DroitController from "../config/apiUtils/DroitController";
import ProfilController from "../config/apiUtils/ProfilController";
import AgGrid from "../components/AgGrid";

export default function Profils(props) {
  const [profilToShow, setProfilToShow] = useState("-1");
  const [profils, setProfils] = useState([]);
  const [profil, setProfil] = useState({});
  const [modalites, setModalites] = useState([]);

  const columns = [
    {
      headerName: "Famille",
      field: "libellefamilledroit",
      flex: 2,
    },
    {
      headerName: "Libellé",
      field: "libelletypedroit",
      flex: 2,
    },
    {
      headerName: "Code",
      field: "codetypedroit",
      flex: 2,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 2,
    },
    {
      headerName: "Modalité",
      field: "iddroitmodalite",
      flex: 1,
      editable: true,
      cellEditor: "customSelectCellEditor",
      cellEditorParams: {
        values: modalites,
      },
      cellRenderer: (params) => params.data.libelledroitmodalite,
    },
  ];

  const loadProfils = async () => {
    const res = await ProfilController.getProfils();
    res.unshift({ idprofil: -1, libelleprofil: "--" });
    setProfils(res);
  };

  useConstructor(async () => {
    await loadProfils();

    const resModalites = await DroitController.getDroitModalites();

    setModalites(
      resModalites.map((modalite) => {
        return {
          value: modalite.iddroitmodalite,
          label: modalite.libelledroitmodalite,
        };
      }),
    );

    if (getQueryParam("profil")) {
      setProfilToShow(getQueryParam("profil"));
    }
  });

  useEffect(() => {
    setProfil(profils.find((p) => p.idprofil == profilToShow));
  }, [profilToShow]);

  const renderProfilsOptions = () => {
    return profils.map((profil) => {
      return (
        <option key={profil.idprofil} value={profil.idprofil}>
          {profil.libelleprofil}
        </option>
      );
    });
  };

  const renderEmptyOption = () => {
    return (
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <CardBody>
            Sélectionnez le profil que vous souhaitez consulter.
          </CardBody>
        </Card>
      </Col>
    );
  };

  const onEditComplete = async (params) => {
    const droit = params.data;
    const idtypedroit = params.data.idtypedroit;
    const modalite = params.newValue;

    const res = await ProfilController.updateModaliteTypeDroitProfil(
      profil.idprofil,
      idtypedroit,
      modalite,
    );

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Droit " + droit.libelletypedroit + " modifié avec succès",
      );

      const res = await ProfilController.getProfils();
      res.unshift({ idprofil: -1, libelleprofil: "--" });
      setProfils(res);
      setProfil(res.find((p) => p.idprofil == profilToShow));
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification du droit " +
          droit.libelletypedroit +
          "...",
      );
    }
  };

  const renderGridDroits = () => {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>{profil?.libelleprofil}</CardTitle>
            </Col>
            <Col style={{ display: "none", flexDirection: "row-reverse" }}>
              <Button color="primary" onClick={props.onClickNewMateriel}>
                <i className="fa-solid fa-plus" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <AgGrid
            columnDefs={columns}
            rowData={profil?.droits}
            showFilterLine
            onCellEditingStopped={onEditComplete}
          />
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des profils</h2>
        </Col>
        <Col md="3" style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Input
            type="select"
            className="select-input"
            field="profil"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            }}
            value={profilToShow}
            onChange={(event) => {
              if (event.target.value === "-1") {
                deleteQueryParam("profil");
              } else {
                setQueryParam("profil", event.target.value);
              }
              setProfilToShow(event.target.value);
            }}
          >
            {renderProfilsOptions()}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col md="12" style={{ marginBottom: 30 }}>
          <RenderIf isTrue={profilToShow == -1}>{renderEmptyOption()}</RenderIf>
          <RenderIf isTrue={profilToShow != -1}>{renderGridDroits()}</RenderIf>
        </Col>
      </Row>
    </>
  );
}
