import React from "react";
import { Col, Row } from "reactstrap";
import ListeParcelles from "./visiteparcelles/ListeParcelles";

export default function VisiteParcelles(): React.JSX.Element {
  return (
    <>
      <Row>
        <Col>
          <h2>Visites de parcelles</h2>
        </Col>
      </Row>
      <Row style={{ marginTop: 30 }}>
        <Col>
          <ListeParcelles />
        </Col>
      </Row>
    </>
  );
}
