import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import React from "react";

export default function LoadingPage(): React.JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
        }}
      >
        <SpinLoadingAnimation style={{ width: 70, height: 70 }} />
        Chargement en cours...
      </div>
    </div>
  );
}
