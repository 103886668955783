import APIController from "../APIController";
import { IFichier } from "../types/fichier";

class FichierController extends APIController {
  constructor() {
    super("/fichier");
  }

  async getListDocuments(): Promise<IFichier[]> {
    return await this.getRequest("/liste/");
  }

  async getListDocumentsExploitation(
    idexploitation: number,
  ): Promise<IFichier[]> {
    return await this.getRequest(`/exploitation/${idexploitation}/liste/`);
  }

  async uploadDocument(idreftypedocument: number, fichier: Blob) {
    return await this.postRequest("/upload/", fichier, {
      params: {
        idreftypedocument: idreftypedocument,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async uploadDocumentExploitation(
    idreftypedocument: number,
    idexploitation: number,
    fichier: Blob,
  ) {
    return await this.postRequest(
      `/upload/exploitation/${idexploitation}`,
      fichier,
      {
        params: {
          idreftypedocument: idreftypedocument,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }

  async downloadDocument(iddocument: number): Promise<Blob> {
    return (await this.getRequest(`/download/${iddocument}`, {
      responseType: "blob",
    })) as Blob;
  }

  async removeDocuments(iddocument: number) {
    return await this.deleteRequest(`/${iddocument}`);
  }
}

export default new FichierController();
