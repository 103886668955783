import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";
import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
} from "../../../config/gridUtils";
import AgGrid from "../../../components/AgGrid";

const columns = [
  {
    headerName: "Type de production",
    field: "libelle",
    flex: 3,
  },
  {
    headerName: "Actif",
    field: "actif",
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
];

export default function ListeTypeProduction(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setTypesProduction(
      await ReferentielController.getTypesProduction(),
    );
    setLoading(false);
  });

  const onRowClick = async (params) => {
    props.showFormUpdateTypeProduction(params.data.idreftypeproduction);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Types de production</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton onClick={props.onClickNewType} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {store.referentiels.typesProduction !== null &&
              store.referentiels.typesProduction !== [] &&
              store.referentiels.typesProduction !== undefined &&
              !loading && (
                <AgGrid
                  columnDefs={columns}
                  rowData={store.referentiels.typesProduction}
                  onRowClicked={onRowClick}
                  showFilterLine
                />
              )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
