import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { columns } from "../../components/Grids/Producteur/producteurs";
import AgGrid from "../../components/AgGrid";

export default function TableProducteur(props) {
  const onRowClick = async (params) => {
    props.onSelectProducteur(params.data);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des producteurs</CardTitle>
            <CardSubtitle tag={"h6"}>
              Pour voir la fiche d'un producteur, cliquez sur la ligne associée.
            </CardSubtitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            <AgGrid
              onRowClicked={onRowClick}
              columnDefs={columns}
              rowData={props.producteurs}
              showFilterLine
              height={350}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
