import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
  valueFormatterDate,
  valueFormatterSurface,
  valueFormatterUnite,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Contrat",
    field: "numerocontrat",
  },
  {
    headerName: "Surface initiale (Ha)",
    field: "surfaceinitiale",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Montant TTC",
    field: "prixcotisation",
    valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
    valueGetter: (params) => {
      return (params.data.prixcotisation * 1.2).toFixed(2);
    },
  },
  {
    headerName: "Montant payé",
    field: "montantpaye",
    valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
    valueGetter: (params) => {
      return (params.data.montantpaye * 1.2).toFixed(2);
    },
  },
  {
    headerName: "Date d'envoi de la facture",
    field: "dateenvoifacturecotisation",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Date de facture de pénalité",
    field: "dateenvoifacturerelance",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Payée",
    field: "paye",
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Date de paiement",
    field: "datepaye",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Surface finale (Ha)",
    field: "surfacefinale",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Différentiel",
    field: "diffsurface",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Montant différentiel TTC",
    field: "montantdiff",
    valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
  },
];
