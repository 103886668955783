import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import { RenderIf, unflattenList } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import PlanProductionController from "../../config/apiUtils/PlanProductionController";
import AgGrid from "../../components/AgGrid";
import {
  getColumnsByField,
  valueFormatterSurface,
} from "../../config/gridUtils";

const columns = [
  {
    headerName: "Organisme",
    field: "nomorganismestockeur",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface printemps",
        field: "surfaceprintemps",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface automne",
        field: "surfaceautomne",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
        columnGroupShow: "open",
      },
      {
        headerName: "Surface totale",
        field: "surfacetotale",
        minWidth: 200,
        flex: 1,
        valueFormatter: valueFormatterSurface,
      },
    ],
  },
  {
    headerName: "Quantités",
    field: "quantite",
    children: [],
  },
];

export default function BilanParOganismeStockeur(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getVarieteColumnName = (idrefvariete) => {
    return "variete." + idrefvariete;
  };

  const loadGrid = () => {
    //Pour chaque organisme stockeur
    data.forEach((organisme) => {
      //Pour chaque variété, on vérifie si la variété est présente dans les colonnes du tableau
      organisme.varietes.forEach((variete) => {
        // On met à plat les données pour l'affichage dans le tableau
        organisme[getVarieteColumnName(variete.idrefvariete)] =
          variete.quantite;

        //Si la colonne de la variété n'a pas encore été insérée
        if (
          !getColumnsByField(
            columns,
            getVarieteColumnName(variete.idrefvariete),
          )
        ) {
          // On vérifie si toutes les valeurs de la colonne sont à 0 ou null => Si au moins une valeur, alors on affiche la colonne
          let columnEmpty = !data.some((org) =>
            org.varietes.some(
              (v) =>
                v.idrefvariete === variete.idrefvariete &&
                v.quantite !== null &&
                v.quantite !== 0,
            ),
          );

          if (!columnEmpty) {
            getColumnsByField(columns, "quantite").children.push({
              headerName: variete.libelle,
              field: getVarieteColumnName(variete.idrefvariete),
              minWidth: 170,
              flex: 1,
            });
          }
        }
      });
    });
  };

  useConstructor(async () => {
    setLoading(true);
    setData(
      await PlanProductionController.getBilanOrganismeStockeur(
        store.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  loadGrid();

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Bilan Par Oganisme Stockeur</CardTitle>
        <CloseButton onClick={props.close} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <AgGrid
            columnDefs={columns}
            rowData={unflattenList(data)}
            height={350}
            showFilterLine
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
