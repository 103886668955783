import { Col, Collapse, Row, Table } from "reactstrap";
import React, { useContext, useState } from "react";
import ButtonCartoAction from "./buttons/ButtonCartoAction";
import { useRecoilValue } from "recoil";
import { parcelleAssocieeSelectedState } from "../../config/atoms/parcellaireexploitation";
import { createNotification, RenderIf } from "../../config/utils";
import StoreContext from "../../context/StoreContext";
import { getGeoJsonFeature } from "../../config/carto/utils";
import ExploitationController from "../../config/apiUtils/ExploitationController";

export default function ParcelleInfo({
  showInfosParcelle,
  forceReloadMap,
  setShowModalUpdateParcelle,
  setShowInfosParcelle,
}) {
  const parcelleAssocieeSelected = useRecoilValue(
    parcelleAssocieeSelectedState,
  );
  const [isModifying, setIsModifying] = useState(false);
  const [idparcelleModifying, setIdparcelleModifying] = useState(null);
  const [modifying, setModifying] = useState(null);
  const context = useContext(StoreContext);

  const clearModify = () => {
    context.carto.getParcellaireLayer().stopModify();
    setIsModifying(false);
    setModifying(null);
    setIdparcelleModifying(null);
  };

  const handleValidateModifyFeature = async () => {
    const featureModified = context.carto
      .getParcellaireLayer()
      .getFeatures()
      .find((feature) => feature.get("idparcelle") === idparcelleModifying)
      .clone();

    const form = {
      geojson: getGeoJsonFeature(featureModified),
    };
    const res = await ExploitationController.updateGeometrieParcelle(
      context.exploitation.informations.idexploitation,
      idparcelleModifying,
      form,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Contour de parcelle modifiée avec succès",
      );
      clearModify();
      await forceReloadMap();
    }
  };

  const handleCancelModifyFeature = () => {
    const features = [
      ...context.carto
        .getParcellaireLayer()
        .getFeatures()
        .filter((feature) => feature.get("idparcelle") != idparcelleModifying),
      modifying,
    ];
    context.carto.getParcellaireLayer().getSource().clear();
    context.carto.getParcellaireLayer().getSource().addFeatures(features);
    context.carto.getParcellaireLayer().getSource().changed();
    clearModify();
  };

  const handleModifyFeature = () => {
    if (!isModifying) {
      context.carto.zoomMap(
        [
          context.carto
            .getParcellaireLayer()
            .getFeatures()
            .find(
              (feature) =>
                feature.get("idparcelle") ===
                context.carto.parcelleSelected.idparcelle,
            ),
        ],
        {
          duration: 1,
          padding: [10, 10, 10, 10],
        },
      );
      context.carto.getParcellaireLayer().modifyFeature(
        context.carto
          .getParcellaireLayer()
          .getFeatures()
          .find(
            (feature) =>
              feature.get("idparcelle") ===
              context.carto.parcelleSelected.idparcelle,
          ),
      );
    } else {
      clearModify();
    }

    setIsModifying(!isModifying);
    setIdparcelleModifying(parcelleAssocieeSelected.idparcelle);
    setModifying(
      context.carto
        .getParcellaireLayer()
        .getFeatures()
        .find(
          (feature) =>
            feature.get("idparcelle") === parcelleAssocieeSelected.idparcelle,
        )
        .clone(),
    );
  };

  const handleCutFeature = () => {
    if (!isModifying) {
      context.carto.zoomMap(
        [
          context.carto
            .getParcellaireLayer()
            .getFeatures()
            .find(
              (feature) =>
                feature.get("idparcelle") ===
                context.carto.parcelleSelected.idparcelle,
            ),
        ],
        {
          duration: 1,
          padding: [10, 10, 10, 10],
        },
      );
      context.carto.getParcellaireLayer().startCuttingFeature(
        context.carto
          .getParcellaireLayer()
          .getFeatures()
          .find(
            (feature) =>
              feature.get("idparcelle") ===
              context.carto.parcelleSelected.idparcelle,
          ),
      );
    } else {
      clearModify();
    }

    setIsModifying(!isModifying);
    setIdparcelleModifying(parcelleAssocieeSelected.idparcelle);
    setModifying(
      context.carto
        .getParcellaireLayer()
        .getFeatures()
        .find(
          (feature) =>
            feature.get("idparcelle") === parcelleAssocieeSelected.idparcelle,
        )
        .clone(),
    );
  };

  return (
    <>
      <hr />
      <div style={{ marginTop: 10 }}>
        <Row>
          <ButtonCartoAction
            onClick={setShowModalUpdateParcelle}
            libelle={"Modifier"}
          />
          <ButtonCartoAction onClick={setShowInfosParcelle} libelle={"Info"} />
        </Row>
        <Row>
          <RenderIf isTrue={isModifying}>
            <ButtonCartoAction
              onClick={handleValidateModifyFeature}
              libelle={"Valider"}
            />
            <ButtonCartoAction
              onClick={handleCancelModifyFeature}
              libelle={"Annuler"}
            />
          </RenderIf>
          <RenderIf isTrue={!isModifying}>
            <ButtonCartoAction
              onClick={handleModifyFeature}
              libelle={"Modifier contour"}
            />
            {/* <ButtonCartoAction
              onClick={handleCutFeature}
              libelle={"Découpe"}
            /> */}
          </RenderIf>
        </Row>
      </div>
      <Collapse isOpen={showInfosParcelle} mountOnEnter unmountOnExit>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <h5>{parcelleAssocieeSelected.nomparcelle}</h5>
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: 0 }}>
            <Table style={{ color: "#FFFFFF" }}>
              <tbody>
                <tr>
                  <th scope={"row"}>Numéro</th>
                  <td>{parcelleAssocieeSelected.numeroparcelle ?? "-"}</td>
                </tr>
                <tr>
                  <th scope={"row"}>Type de sol</th>
                  <td>{parcelleAssocieeSelected.libelletypesol}</td>
                </tr>
                <tr>
                  <th scope={"row"}>Surface</th>
                  <td>{parcelleAssocieeSelected.surface} Ha</td>
                </tr>
                <tr>
                  <th scope={"row"}>Culture</th>
                  <td>{parcelleAssocieeSelected.libelleculture}</td>
                </tr>
                <tr>
                  <th scope={"row"}>Commune</th>
                  <td>
                    {parcelleAssocieeSelected.codepostal}{" "}
                    {parcelleAssocieeSelected.nomcommune}
                  </td>
                </tr>
                <tr>
                  <th scope={"row"}>Informations</th>
                  <td>{parcelleAssocieeSelected.informationparcelle}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
