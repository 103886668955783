import APIController from "../APIController";
import {
  IContratPOST,
  IContratPUT,
  IContratValidationPUT,
} from "../types/contrat";

class ContratController extends APIController {
  constructor() {
    super("/contrat");
  }

  async getContratMillesime(millesime: number) {
    return await this.getRequest(`/?millesime=${millesime}`);
  }

  async getContratDetail(idcontrat: number) {
    return await this.getRequest(`/${idcontrat}`);
  }

  async insertContrat(contrat: IContratPOST) {
    return await this.postRequest("/", contrat);
  }

  async updateContrat(idcontrat: number, contrat: IContratPUT) {
    return await this.putRequest(`/${idcontrat}`, contrat);
  }

  async addNewAnnexeOld(idcontrat: number, nbparcelles: number) {
    return await this.postRequest(
      `/${idcontrat}/annexeold?nbparcelles=${nbparcelles}`,
    );
  }

  async addNewAnnexe(idcontrat: number, formulaire: any) {
    return await this.postRequest(`/${idcontrat}/annexe`, formulaire);
  }

  async validerAnnexe(idcontrat: number, idannexe: number) {
    return await this.putRequest(`/${idcontrat}/annexe/${idannexe}/validation`);
  }

  async getAnnexes(idcontrat: number) {
    return await this.getRequest(`/${idcontrat}/annexe`);
  }

  async getAnnexeById(idcontrat: number, idannexe: number) {
    return await this.getRequest(`/${idcontrat}/annexe/${idannexe}`);
  }

  async completerContrat(idcontrat: number, formulaire: any) {
    return await this.postRequest(`/${idcontrat}/completer`, formulaire);
  }

  async deleteParcelleContrat(idcontrat: number, idparcelle: number) {
    return await this.deleteRequest(`/${idcontrat}/parcelle/${idparcelle}`);
  }

  async signerContrat(idcontrat: number) {
    return await this.putRequest(`/${idcontrat}/signer`);
  }

  async updateValidationContrats(idcontrats: IContratValidationPUT) {
    return await this.putRequest("/validation/contrat", idcontrats);
  }
}

export default new ContratController();
