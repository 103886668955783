import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidateButton from "../../../components/Buttons/ValidateButton";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

export default function ModalContratConfirmationAppelCotisation(
  props: IProps,
): React.JSX.Element {
  return (
    <Modal
      isOpen={props.isOpen}
      size={"lg"}
      centered
      scrollable={false}
      unmountOnClose
    >
      <ModalHeader className="modal-header-form">
        Contrat créé - Appel de cotisation par mail
      </ModalHeader>
      <ModalBody className="modal-body-form">
        <p>
          Vous venez de renseigner votre contrat de production, nous vous en
          remercions.
        </p>
        <p>
          Une fois le contrat validé par l'organisme stockeur, vous recevrez
          votre appel de cotisation par mail.
        </p>
      </ModalBody>
      <ModalFooter
        className="modal-footer-form"
        style={{ justifyContent: "left" }}
      >
        <ValidateButton style={{ marginTop: 15 }} onClick={props.toggle} />
      </ModalFooter>
    </Modal>
  );
}
