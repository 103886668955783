import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";
import AgGrid from "../../../components/AgGrid";

const columns = [
  {
    headerName: "Statut",
    field: "libellestatut",
  },
];

export default function ListeStatutJuridique(props) {
  const store = useContext(StoreContext);

  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setStatutsJuridique(
      await ReferentielController.getStatutsJuridique(),
    );
    setLoading(false);
  });

  const onRowClick = async (params) => {
    props.showFormUpdateStatutJuridique(params.data.idstatutjuridique);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Statuts juridiques</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton onClick={props.onClickNewType} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {store.referentiels.statutsJuridique !== null &&
              store.referentiels.statutsJuridique !== [] &&
              store.referentiels.statutsJuridique !== undefined &&
              !loading && (
                <AgGrid
                  columnDefs={columns}
                  rowData={store.referentiels.statutsJuridique}
                  onRowClicked={onRowClick}
                  showFilterLine
                />
              )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
