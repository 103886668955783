import { Alert, Col, Row } from "reactstrap";
import React from "react";

export default function MaintenancePage(): React.JSX.Element {
  return (
    <Row>
      <Col>
        <Alert color={"warning"} fade={false}>
          <h3 className="alert-heading">
            {window.env.MESSAGE_MAINTENANCE_TITLE}
          </h3>
          {window.env.MESSAGE_MAINTENANCE.map(
            (message: string, key: number) => (
              <p key={key}>{message}</p>
            ),
          )}
        </Alert>
      </Col>
    </Row>
  );
}
