import React, { useState } from "react";
import {
  Alert,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from "moment/moment";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import { IVisite, IVisitePOST } from "../../../config/types/visiteparcelles";
import { IParcelle } from "../../../config/types/parcelle";
import VisiteParcellesController from "../../../config/apiUtils/visiteParcellesController";
import { createNotification, RenderIf } from "../../../config/utils";
import RecapVisitePrecedente from "./RecapVisitePrecedente";

interface IProps {
  parcelle: IParcelle;
  premiereVisite: boolean;
  visitePrecedente: IVisite | null;
  numeroVisite: number;
  close: (newVisite: IVisite[] | null | undefined) => void;
}

export default function FormNewVisite(props: IProps): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const [presenceColza, setPresenceColza] = useState<boolean>(false);
  const [absenceColza, setAbsenceColza] = useState<boolean>(false);
  const [nbColza, setNbColza] = useState<number | null>(null);
  const [nbColzaInput, setNbColzaInput] = useState<string>("");

  const [presenceSanves, setPresenceSanve] = useState<boolean>(false);
  const [absenceSanves, setAbsenceSanve] = useState<boolean>(false);
  const [nbSanves, setNbSanves] = useState<number | null>(null);
  const [nbSanvesInput, setNbSanvesInput] = useState<string>("");

  const [actionCorrectiveMiseEnPlace, setActionCorrectiveMiseEnPlace] =
    useState<string>("");
  const [actionCorrectiveProposee, setActionCorrectiveProposee] =
    useState<string>("");

  const [dateVisiteSupplementaire, setDateVisiteSupplementaire] =
    useState<string>(moment().format("YYYY-MM-DD"));

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    let hasError = false;
    if (!presenceColza && !absenceColza) {
      hasError = true;
      createNotification(
        "error",
        "Erreur",
        "Information de présence de colza manquante",
      );
    }
    if (!presenceSanves && !absenceSanves) {
      hasError = true;
      createNotification(
        "error",
        "Erreur",
        "Information de présence de sanves ravenelles manquante",
      );
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    const data: IVisitePOST = {
      idparcelle: props.parcelle.idparcelle,
      actioncorrectivemiseplace:
        actionCorrectiveMiseEnPlace !== "" ? actionCorrectiveMiseEnPlace : null,
      actioncorrectiveproposee:
        actionCorrectiveProposee !== "" ? actionCorrectiveProposee : null,
      autresanomalies:
        event.target.autresanomalies.value !== ""
          ? event.target.autresanomalies.value
          : null,
      commentaire:
        event.target.commentaire.value !== ""
          ? event.target.commentaire.value
          : null,
      conforme:
        (!presenceColza && !presenceSanves) || (absenceColza && absenceSanves),
      datevisite:
        event.target.datevisite.value !== ""
          ? event.target.datevisite.value
          : null,
      datevisitesupplementaire:
        dateVisiteSupplementaire !== "" ? dateVisiteSupplementaire : null,
      nbcolza: nbColza,
      nbsanvesravenelles: nbSanves,
      presencecolza: presenceColza,
      presencesanvesravenelles: presenceSanves,
      conformeapresaction:
        (!presenceColza && !presenceSanves) || (absenceColza && absenceSanves),
    };

    const res = await VisiteParcellesController.insertVisiteParcelle(data);

    if (res.status === 200) {
      createNotification("success", "Succès", "Visite renseignée avec succès");
      props.close(res.data);
    }

    setLoading(false);
  };

  const handleNumericInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    setInputValue: (value: string) => void,
    setNumericValue: (value: number | null) => void,
  ) => {
    const value = e.target.value;

    // Autorise uniquement les chiffres avec un point ou une virgule
    if (/^[0-9]*[.,]?[0-9]*$/.test(value) || value === "") {
      setInputValue(value);

      // Si le champ est vide, on remet la valeur numérique à null
      if (value === "") {
        setNumericValue(null);
      } else {
        // Conversion : remplace la virgule par un point
        const normalizedValue = value.replace(",", ".");

        // Vérifie que la valeur n'est pas juste un séparateur
        if (
          !isNaN(Number(normalizedValue)) &&
          !normalizedValue.endsWith(".") &&
          !normalizedValue.endsWith(",")
        ) {
          setNumericValue(Number(normalizedValue));
        } else {
          setNumericValue(null); // Si la saisie est incomplète, on garde null
        }
      }
    }
  };

  const getAlertConformeConfig = () => {
    if (absenceColza && absenceSanves) {
      return { color: "success", message: "Conforme" };
    }

    if (presenceColza || presenceSanves) {
      return { color: "danger", message: "Non conforme" };
    }

    return { color: "warning", message: "Non renseigné" };
  };

  const getVisitLabel = () => {
    if (props.numeroVisite === 1) return `${props.numeroVisite}ère visite`;
    return `${props.numeroVisite}ème visite`;
  };

  return (
    <>
      <RenderIf isTrue={!props.premiereVisite}>
        <RecapVisitePrecedente visite={props.visitePrecedente} />
      </RenderIf>
      <div id={"form-new-visite"}>
        <h3>{getVisitLabel()}</h3>
        <Form onSubmit={onSubmit}>
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <FormGroup>
                <Label for="datevisite">Date de la visite</Label>
                <Input
                  type="date"
                  name="datevisite"
                  defaultValue={moment().format("YYYY-MM-DD")}
                  required
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <h5>Conformité de la parcelle</h5>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md={6}>
              <Label for="nbcolza">
                <FormGroup>
                  <Label check style={{ display: "flex" }}>
                    <Input
                      type="checkbox"
                      checked={presenceColza}
                      onChange={(event: any) => {
                        const isChecked = event.target.checked;
                        setPresenceColza(isChecked);
                        if (isChecked) {
                          setAbsenceColza(false);
                        } else {
                          setNbColza(null);
                          setNbColzaInput("");
                        }
                      }}
                    />
                    &nbsp;Présence de colza
                  </Label>
                  <Label check style={{ display: "flex" }}>
                    <Input
                      type="checkbox"
                      checked={absenceColza}
                      onChange={(event: any) => {
                        const isChecked = event.target.checked;
                        setAbsenceColza(isChecked);
                        if (isChecked) {
                          setPresenceColza(false);
                        }
                      }}
                    />
                    &nbsp;Absence de colza
                  </Label>
                </FormGroup>
              </Label>
              <Collapse isOpen={presenceColza}>
                <Input
                  type={"text"}
                  name="nbcolza"
                  style={{ marginBottom: 20 }}
                  placeholder={"plants de colza / m²"}
                  disabled={!presenceColza}
                  required={presenceColza}
                  value={nbColzaInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleNumericInput(e, setNbColzaInput, setNbColza)
                  }
                />
              </Collapse>
            </Col>
            <Col md={6}>
              <Label for="nbsanvesravenelles">
                <FormGroup>
                  <Label check style={{ display: "flex" }}>
                    <Input
                      type="checkbox"
                      checked={presenceSanves}
                      onChange={(event: any) => {
                        const isChecked = event.target.checked;
                        setPresenceSanve(isChecked);
                        if (isChecked) {
                          setAbsenceSanve(false);
                        } else {
                          setNbSanves(null);
                          setNbSanvesInput("");
                        }
                      }}
                    />
                    &nbsp;Présence de sanves ravenelles
                  </Label>
                  <Label check style={{ display: "flex" }}>
                    <Input
                      type="checkbox"
                      checked={absenceSanves}
                      onChange={(event: any) => {
                        const isChecked = event.target.checked;
                        setAbsenceSanve(isChecked);
                        if (isChecked) {
                          setPresenceSanve(false);
                        }
                      }}
                    />
                    &nbsp;Absence de sanves ravenelles
                  </Label>
                </FormGroup>
              </Label>
              <Collapse isOpen={presenceSanves}>
                <Input
                  type={"text"}
                  name="nbsanvesravenelles"
                  style={{ marginBottom: 20 }}
                  placeholder={"Plants de sanves ravenelles / m²"}
                  disabled={!presenceSanves}
                  required={presenceSanves}
                  value={nbSanvesInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleNumericInput(e, setNbSanvesInput, setNbSanves)
                  }
                />
              </Collapse>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md={!presenceColza && !presenceSanves ? 12 : 6}>
              <FormGroup>
                <Label for="conforme">
                  Conforme {!props.premiereVisite ? "après action" : ""}
                </Label>
                <RenderIf isTrue={!!getAlertConformeConfig()}>
                  <Alert
                    fade={false}
                    color={getAlertConformeConfig()?.color}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 5,
                    }}
                  >
                    {getAlertConformeConfig()?.message}
                  </Alert>
                </RenderIf>
              </FormGroup>
            </Col>
            <Col md={6}>
              <Collapse
                horizontal
                isOpen={presenceColza || presenceSanves}
                unmountOnExit
                mountOnEnter
              >
                <FormGroup>
                  <Label for="actioncorrectiveproposee">
                    Action corrective proposée
                  </Label>
                  <Input
                    type={"text"}
                    name={"actioncorrectiveproposee"}
                    placeholder={"Action corrective proposée"}
                    value={actionCorrectiveProposee}
                    onChange={(e) =>
                      setActionCorrectiveProposee(e.target.value)
                    }
                    required
                  ></Input>
                </FormGroup>
              </Collapse>
            </Col>
          </Row>
          <RenderIf isTrue={!props.premiereVisite}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="actioncorrectivemiseplace">
                    Action corrective mise en place
                  </Label>
                  <Input
                    type={"text"}
                    name={"actioncorrectivemiseplace"}
                    placeholder={"Action corrective mise en place"}
                    value={actionCorrectiveMiseEnPlace}
                    onChange={(e) =>
                      setActionCorrectiveMiseEnPlace(e.target.value)
                    }
                    required={!props.premiereVisite}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </RenderIf>
          <Collapse isOpen={presenceColza || presenceSanves}>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <FormGroup>
                  <Label for="datevisitesupplementaire">
                    Date de la prochaine visite
                  </Label>
                  <Input
                    type="date"
                    name="datevisitesupplementaire"
                    value={dateVisiteSupplementaire}
                    onChange={(e) =>
                      setDateVisiteSupplementaire(e.target.value)
                    }
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
          <Row style={{ marginBottom: 10 }}>
            <Col md={6}>
              <FormGroup>
                <Label for={"autresanomalies"}>Autres anomalies</Label>
                <Input
                  name="autresanomalies"
                  type="textarea"
                  placeholder="Autres anomalies..."
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for={"commentaire"}>Commentaire</Label>
                <Input
                  name="commentaire"
                  type="textarea"
                  placeholder="Commentaire..."
                />
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <ValidateButton style={{ marginRight: 10 }} />
              <CancelButton
                onClick={(event: any) => {
                  event.preventDefault();
                  props.close(null);
                }}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
