import React, { Fragment, useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import { newColumns } from "../../components/Grids/planproduction/listeparparcelle";
import StoreContext from "../../context/StoreContext";
import {
  RenderIf,
  convertToCSV,
  createNotification,
  downloadCsv,
  flattenList,
} from "../../config/utils";
import moment from "moment";
import CsvButton from "../../components/Buttons/CsvButton";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import useConstructor from "../../config/hooks/useConstructor";
import PlanProductionEditionController from "../../config/apiUtils/PlanProductionEditionController";
import SurfacePotentielleEditionController from "../../config/apiUtils/SurfacePotentielleEditionController";
import AgGrid from "../../components/AgGrid";
import { getColumnsByField, getColumnsMapForCsv } from "../../config/gridUtils";

export default function ListeParParcelle(props) {
  const store = useContext(StoreContext);

  const columns = [...newColumns];
  const [gridLoaded, setGridLoaded] = useState(false);

  const [dataSource, setDataSource] = useState(
    store.planProduction.planProductionParcelle,
  );
  const [totalAutomne, setTotalAutomne] = useState(0);
  const [totalPrintemps, setTotalPrintemps] = useState(0);
  const [varietes, setVarietes] = useState({});
  const [totalBio, setTotalBio] = useState(0);
  const [totalNonBio, setTotalNonBio] = useState(0);

  const loadDataSource = () => {
    let totPrintemps = 0;
    let totAutomne = 0;
    let totBio = 0;
    let totNonBio = 0;
    let surfacesVarietes = {};
    store.planProduction.planProductionPrevisionnel.forEach((element) => {
      totPrintemps += element.surfaceprintemps;
      totAutomne += element.surfaceautomne;
      if (element.idreftypeproduction === 4) {
        totBio +=
          parseFloat(element.surfaceprintemps ?? 0) +
          parseFloat(element.surfaceautomne ?? 0);
      } else {
        totNonBio +=
          parseFloat(element.surfaceprintemps ?? 0) +
          parseFloat(element.surfaceautomne ?? 0);
      }
      if (
        Object.prototype.hasOwnProperty.call(
          surfacesVarietes,
          element.libellevariete,
        )
      ) {
        surfacesVarietes[element.libellevariete] +=
          parseFloat(element.surfaceprintemps ?? 0) +
          parseFloat(element.surfaceautomne ?? 0);
      } else {
        surfacesVarietes[element.libellevariete] =
          parseFloat(element.surfaceprintemps ?? 0) +
          parseFloat(element.surfaceautomne ?? 0);
      }
    });
    setTotalPrintemps(totPrintemps.toFixed(2));
    setTotalAutomne(totAutomne.toFixed(2));
    setTotalBio(totBio.toFixed(2));
    setTotalNonBio(totNonBio.toFixed(2));
    setVarietes(surfacesVarietes);
  };

  useEffect(() => {
    setDataSource(store.planProduction.planProductionPrevisionnel);
    loadDataSource();
  }, [store.planProduction.planProductionPrevisionnel, props]);

  const onCellClick = (params) => {
    const includedColumns = [
      "numerocontrat",
      "exploitation.codedc",
      "exploitation.numerounilever",
      "exploitation.raisonsociale",
      "parcelle.nomparcelle",
    ];
    if (includedColumns.includes(params.colDef.field)) {
      navigator.clipboard.writeText(params.value);
      createNotification(
        "info",
        "Information",
        params.colDef.headerName + " copié dans le presse-papier",
        1000,
      );
    }
  };

  const renderSurfacesVarietes = () => {
    const libelles = Object.keys(varietes);
    return libelles.map((libellevariete, i) => {
      return (
        <Fragment key={i}>
          Total {libellevariete != "null" ? libellevariete : "sans variété"} :{" "}
          {varietes[libellevariete] !== null
            ? varietes[libellevariete].toFixed(2)
            : 0}{" "}
          Ha {i + 1 == libelles.length ? "" : "| "}
        </Fragment>
      );
    });
  };

  const renderFooter = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
          }}
        >
          Total printemps : {totalPrintemps} Ha | Total automne : {totalAutomne}{" "}
          Ha | Total :{" "}
          {(parseFloat(totalAutomne) + parseFloat(totalPrintemps)).toFixed(2)}{" "}
          Ha
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
          }}
        >
          Total bio : {totalBio} Ha | Total non bio : {totalNonBio} Ha
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
            marginTop: 10,
          }}
        >
          {renderSurfacesVarietes()}
        </div>
      </>
    );
  };

  const onEditComplete = async (params) => {
    const column = getColumnsByField(columns, params.colDef.field);
    const fieldToUpdate = column.field.split(".").at(-1);

    const res = params.data.isintentionsemis
      ? await SurfacePotentielleEditionController.updateField(
          params.data.idplanproduction,
          fieldToUpdate,
          params.newValue,
        )
      : await PlanProductionEditionController.updateField(
          params.data.idplanproduction,
          fieldToUpdate,
          params.newValue,
        );

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      // On récupère la ligne de manière temporaire pour une modification en live de la grid
      const tmp = dataSource;
      const rowToUpdate = tmp.find(
        (e) => e.idplanproduction === params.data.idplanproduction,
      );
      if (rowToUpdate !== undefined) {
        switch (fieldToUpdate) {
          case "idetatvalidationadministrative":
            tmp
              .filter((e) => e.idcontrat === params.data.idcontrat)
              .forEach((e) => {
                e.idetatvalidationadministrative =
                  column.cellEditorParams.values.find(
                    (e) => e.value === params.newValue,
                  ).value;
                e.valide = params.newValue === 4;
                e.libellevalidationadministrative =
                  column.cellEditorParams.values.find(
                    (e) => e.value === params.newValue,
                  ).label;
              });
            break;
          case "idsilo":
            rowToUpdate.nomsilo = column.cellEditorParams.values.find(
              (e) => e.value === params.newValue,
            ).label;
            break;
          case "idsilodefinitif":
            rowToUpdate.nomsilodefinitif = column.cellEditorParams.values.find(
              (e) => e.value === params.newValue,
            ).label;
            break;
          case "idrefvariete":
            rowToUpdate.libellevariete = column.cellEditorParams.values.find(
              (e) => e.value === params.newValue,
            ).label;
            break;
          case "rendementrefmoyen":
            tmp
              .filter(
                (e) =>
                  e.exploitation.idexploitation ===
                  params.data.exploitation.idexploitation,
              )
              .forEach((e) => {
                e.exploitation.rendementrefmoyen = params.newValue;
                e.tonnage =
                  params.newValue && e.surfacetotale
                    ? parseFloat((params.newValue * e.surfacetotale).toFixed(2))
                    : null;
              });
            break;
          case "rendementrefmillesime":
            tmp
              .filter(
                (e) =>
                  e.exploitation.idexploitation ===
                  params.data.exploitation.idexploitation,
              )
              .forEach((e) => {
                e.exploitation.rendementrefmillesime = params.newValue;
                e.tonnageestime =
                  params.newValue && e.surfacetotale
                    ? parseFloat((params.newValue * e.surfacetotale).toFixed(2))
                    : null;
              });
            break;
          case "codedc":
            tmp
              .filter(
                (e) =>
                  e.exploitation.idexploitation ===
                  params.data.exploitation.idexploitation,
              )
              .forEach((e) => {
                e.exploitation.codedc = params.newValue;
              });
            break;
          default:
            break;
        }

        // @ts-ignore
        rowToUpdate[column.field] = params.newValue;

        if (["idsilo", "idsilodefinitif"].includes(fieldToUpdate)) {
          rowToUpdate.siloreprismillesimeprecedent = false;
        }
      }
      loadDataSource();
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  useConstructor(async () => {
    setGridLoaded(false);
    getColumnsByField(columns, "idrefvariete").cellEditorParams.values = (
      await ReferentielController.getVarietes(true)
    ).map((variete) => {
      return {
        value: variete.idrefvariete,
        label: variete.libelle,
      };
    });
    setGridLoaded(true);
  });

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Plan de production par parcelles</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={dataSource.length > 0}>
              <CsvButton
                color="primary"
                style={{ marginRight: 30 }}
                onClick={() => {
                  downloadCsv(
                    convertToCSV(
                      flattenList(dataSource),
                      ";",
                      getColumnsMapForCsv([...columns]),
                    ),
                    "plan_prod_previsionnel_parcelles_" +
                      store.millesime.idmillesime +
                      "_" +
                      moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
            <CloseButton onClick={props.close} />
          </Col>
        </Row>
        <CloseButton onClick={props.close} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={gridLoaded}>
          <>
            <AgGrid
              columnDefs={[...columns]}
              rowData={dataSource}
              showFilterLine
              onCellDoubleClicked={onCellClick}
              onCellEditingStopped={onEditComplete}
              height={700}
            />
            {renderFooter()}
          </>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
