import { useState } from "react";

const useConstructor = (callBack) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  if (callBack) callBack();
  setHasBeenCalled(true);
};

export default useConstructor;
