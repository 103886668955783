import React from "react";

export const Row = ({
  children,
  className,
  style,
}: {
  children?: any;
  className?: string;
  style?: any;
}): React.JSX.Element => (
  <div className={`row ${className ?? ""}`} style={style}>
    {children}
  </div>
);

export const Col = ({
  children,
  className,
  style,
}: {
  children?: any;
  className?: string;
  style?: any;
}): React.JSX.Element => (
  <div className={`col ${className ?? ""}`} style={style}>
    {children}
  </div>
);
