import React, { Component } from "react";
import ControlPanel from "../../components/carto/ControlPanel";
import { createNotification, RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import StoreContext from "../../context/StoreContext";
import { Button, Col, Row } from "reactstrap";
import { NavLink } from "react-router-dom";
import SpaceImage from "../../assets/img/carto/space.png";
import layoutRoutes from "../../config/layoutRoutes";
import Icons from "../../config/Icons";
import CommuneController from "../../config/apiUtils/CommuneController";
import GeoJSON from "ol/format/GeoJSON";
import CommuneInput from "../../components/CommuneInput";

export default class ControlPanelLayers extends Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      controlPanelOpen: false,
    };
    this.handleDrop = this.handleDrop.bind(this);
  }

  handleDrop(droppedItem) {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const itemBase = [
      ...this.context.carto.layers.filter(
        (layer) => layer.getCodeCouche() !== droppedItem.draggableId,
      ),
    ];
    let updatedList = [
      ...itemBase.slice(0, itemBase.length - droppedItem.destination.index),
      this.context.carto.layers.find(
        (layer) => layer.getCodeCouche() === droppedItem.draggableId,
      ),
      ...itemBase.slice(itemBase.length - droppedItem.destination.index),
    ];

    let i = 0;
    updatedList.forEach((layer) => (layer.info.ordre = ++i));
    this.context.carto.setLayers(updatedList);
  }

  renderButtonsLayer() {
    return (
      <DragDropContext onDragEnd={this.handleDrop}>
        <Droppable
          droppableId="buttons"
          ignoreContainerClipping={true}
          isCombineEnabled={true}
          type="COLUMN"
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              style={{ marginBottom: 60 }}
              {...provided.droppableProps}
            >
              {[...this.context.carto.layers]
                .sort((layer1, layer2) => layer2.info.ordre - layer1.info.ordre)
                .map((layer, key) => {
                  return (
                    <Draggable
                      key={layer.info.codecouche}
                      draggableId={layer.info.codecouche}
                      index={key}
                      ignoreContainerClipping={true}
                      isCombineEnabled={true}
                    >
                      {(provided) => (
                        <div
                          key={layer.info.codecouche}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {layer.getButtonLayer()}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  render() {
    return (
      <ControlPanel
        isOpen={this.state.controlPanelOpen}
        close={() => this.setState({ controlPanelOpen: false })}
        toggleSidebar={() => {
          if (!this.state.controlPanelOpen) {
            //fondCartoSelectRef.current.focus();
          }
          this.setState({ controlPanelOpen: !this.state.controlPanelOpen });
        }}
        height={this.props.height}
        buttons={[
          {
            icon: <Icons.FarmerIcon style={{ height: 25 }} />,
            tooltip: "Zoomer sur ma position",
            show: this.context.carto.geolocationEnabled,
            onClick: () => {
              this.context.carto.zoomLocation();
            },
          },
          {
            icon: <Icons.TreeCityIcon style={{ height: 25 }} />,
            tooltip: `Zoomer sur la commune ${this.context.exploitation.informations.codepostal} ${this.context.exploitation.informations.ville}`,
            show: true,
            onClick: async () => {
              const geoJsonCommune =
                await CommuneController.getGeometrieCommune(
                  this.context.exploitation.informations.idcommune,
                );
              if (!geoJsonCommune) {
                createNotification(
                  "error",
                  "Commune non retrouvée en base...",
                  "",
                );
                return;
              }
              this.context.carto.zoomMap(
                new GeoJSON().readFeatures(geoJsonCommune),
              );
            },
          },
          {
            icon: <Icons.MapIcon style={{ height: 25 }} />,
            tooltip: "Zoomer sur mes parcelles",
            show:
              this.context.carto.getParcellaireLayer() &&
              this.context.carto.getParcellaireLayer().getFeatures().length > 0,
            onClick: this.context.carto.zoomEmprise,
          },
          {
            icon: <Icons.SelectionMapIcon style={{ height: 25 }} />,
            tooltip: "Zoomer sur la sélection",
            show: this.context.carto.parcelleSelected != null,
            onClick: () => {
              this.context.carto.zoomMap([
                new GeoJSON().readFeature(
                  this.context.carto.parcelleSelected.geometrie,
                ),
              ]);
            },
          },
        ]}
      >
        <div
          id={"panel-layers"}
          style={{
            height: this.props.height,
            overflowX: "clip",
            overflowY: "auto",
            marginBottom: 30,
          }}
        >
          <RenderIf isTrue={this.props.loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!this.props.loading}>
            <div
              style={{
                marginBottom: 10,
                marginTop: 10,
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <CommuneInput
                hideCodePostalInput
                label={"Centrer sur la commune : "}
                onSelect={async (idcommune) => {
                  const geoJsonCommune =
                    await CommuneController.getGeometrieCommune(idcommune);

                  if (!geoJsonCommune) {
                    createNotification(
                      "error",
                      "Commune non retrouvée en base...",
                      "",
                    );
                    return;
                  }
                  this.context.carto.zoomMap(
                    new GeoJSON().readFeatures(geoJsonCommune),
                  );
                }}
              />
            </div>
            <div
              style={{
                marginBottom: 30,
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  width: "70%",
                  backgroundImage: `url(${SpaceImage})`,
                  opacity: this.context.carto.geolocationEnabled ? 1 : 0.5,
                  minHeight: 100,
                  borderRadius: 20,
                  cursor: "pointer",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  WebkitTextStroke: "1px #F8F8F8",
                  textShadow: "0px 1px 4px #23430C",
                }}
                title={"Ma position"}
                onClick={() => {
                  this.context.carto.toggleGeolocation(
                    !this.context.carto.geolocationEnabled,
                  );
                }}
              >
                <h5>Ma position</h5>
              </Button>
            </div>
            {this.renderButtonsLayer()}
          </RenderIf>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 10,
            width: "100%",
          }}
        >
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <NavLink
                style={{ textDecoration: "none", color: "#000000" }}
                to={`${layoutRoutes.userLayout}/parcelles`}
              >
                <Button color="warning">Tableau des parcelles</Button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </ControlPanel>
    );
  }
}
