import { valueFormatterUnite } from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Millésime",
    field: "millesime",
  },
  {
    headerName: "Montant HT",
    field: "prixcotisation",
    valueFormatter: (params) => valueFormatterUnite(params, "€ HT"),
  },
  {
    headerName: "Montant TTC",
    field: "cotisationttc",
    valueFormatter: (params) =>
      params.data.prixcotisation > 0
        ? (params.data.prixcotisation * 1.2).toFixed(2) + "€ TTC"
        : null,
  },
  {
    headerName: "Différence de cotisation",
    field: "diffcotisation",
    valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
  },
];
