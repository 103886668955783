import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { columns } from "../../components/Grids/ListeExploitation/tableexploitation";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import { useContext, useState } from "react";
import useConstructor from "../../config/hooks/useConstructor";
import {
  RenderIf,
  convertToCSV,
  createNotification,
  downloadCsv,
} from "../../config/utils";
import StoreContext from "../../context/StoreContext";
import { useNavigate } from "react-router-dom";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment";
import AgGrid from "../../components/AgGrid";

export default function TableExploitations(props) {
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const [acces, setAcces] = useState([]);
  const [
    showModalDesactivateExploitation,
    setShowModalDesactivateExploitation,
  ] = useState(false);
  const [idexploitationToDesactivate, setIdexploitationToDesactivate] =
    useState(-1);

  useConstructor(async () => {
    setAcces(await ExploitationController.getExploitations());
  });

  const onCellClick = async (params) => {
    if (params.colDef.field === "desactiver") {
      // On demande la confirmation de la désactivation de l'exploitation
      setIdexploitationToDesactivate(params.data.idexploitation);
      setShowModalDesactivateExploitation(true);
    } else {
      // Changement d'exploitation
      const exploitation = acces.find(
        (a) => a.idexploitation === params.data.idexploitation,
      );
      if (exploitation != null) {
        await ExploitationController.changeExploitation(
          params.data.idexploitation,
        );
        await store.loadData();
        createNotification(
          "success",
          "Changement de dossier effectué",
          "Vous travaillez maintenant sur le dossier " +
            params.data.raisonsociale,
        );
        setTimeout(() => {
          navigate("/app/exploitation");
        }, 2000);
      }
    }
  };

  const desactivateExploitation = async () => {
    const res = await ExploitationController.desactivateExploitation(
      idexploitationToDesactivate,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Exploitation " +
          acces.find((a) => a.idexploitation === idexploitationToDesactivate)
            ?.raisonsociale +
          " désactivée avec succès",
      );
      const tmp = [
        ...acces.filter(
          (a) => a.idexploitation === idexploitationToDesactivate,
        ),
      ];
      setAcces(tmp);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la désactivation de l'exploitation " +
          acces.find((a) => a.idexploitation === idexploitationToDesactivate)
            ?.raisonsociale +
          "...",
      );
    }
    setIdexploitationToDesactivate(-1);
    setShowModalDesactivateExploitation(false);
  };

  const renderModalDesactivateExploitation = () => {
    return (
      <Modal isOpen={showModalDesactivateExploitation} autoFocus={false}>
        <ModalHeader className="modal-header-form">
          Désactivation de l'exploitation{" "}
          {
            acces.find((a) => a.idexploitation === idexploitationToDesactivate)
              ?.raisonsociale
          }
        </ModalHeader>
        <ModalBody className="modal-body-form">
          Confirmez-vous la désactivation de l'exploitation{" "}
          {
            acces.find((a) => a.idexploitation === idexploitationToDesactivate)
              ?.raisonsociale
          }{" "}
          ? <br />
          <br />
          <b>L'exploitation n'apparaitra plus dans l'application Ecodim</b>
        </ModalBody>
        <ModalFooter className="modal-footer-form">
          <Button
            autoFocus={true}
            color="primary"
            onClick={async () => await desactivateExploitation()}
          >
            Confirmer
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setShowModalDesactivateExploitation(false);
              setIdexploitationToDesactivate(-1);
            }}
          >
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    delete tmp["desactiver"];
    return tmp;
  };

  return (
    <>
      {renderModalDesactivateExploitation()}
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Pool exploitations</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf isTrue={props.exploitations.length > 0}>
                <CsvButton
                  color="primary"
                  onClick={() => {
                    downloadCsv(
                      convertToCSV(props.exploitations, ";", getColumns()),
                      "export_exploitations_" + moment().format("YYYY-MM-DD"),
                    );
                  }}
                />
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {props.loading && <SpinLoadingAnimation />}
          {!props.loading && (
            <AgGrid
              columnDefs={columns}
              rowData={props.exploitations}
              onCellClicked={onCellClick}
              showFilterLine
            />
          )}
        </CardBody>
      </Card>
    </>
  );
}
