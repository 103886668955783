import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleSuccess,
  cellStyleSwitch,
  cellStyleSwitchDefaultBoolean,
  valueFormatterDate,
  valueFormatterUnite,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    flex: 2,
  },
  {
    headerName: "Numéro de contrat",
    field: "numerocontrat",
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
  },
  {
    headerName: "Montant",
    children: [
      {
        headerName: "Montant de la cotisation HT",
        field: "prixcotisation",
        valueFormatter: (params) => valueFormatterUnite(params, "€ HT"),
        flex: 2,
      },
      {
        headerName: "Montant de la cotisation TTC",
        field: "prixcotisationttc",
        valueGetter: (params) =>
          parseFloat((params.data.prixcotisation * 1.2).toFixed(2)),
        valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
        flex: 2,
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Date d'envoi de la facture",
    field: "dateenvoifacturecotisation",
    flex: 1,
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Abandon de contrat",
    field: "abandon",
    flex: 2,
    cellStyle: (params) =>
      cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: cellStyleDanger,
        },
        {
          value: false,
          cellStyle: cellStyleSuccess,
        },
        {
          value: null,
          cellStyle: cellStyleDefault,
        },
      ]),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Pénalité payée",
    field: "penalitepayee",
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) => {
      switch (params.value) {
        case true:
          return "Payée";
        case false:
          return "Non payée";
        default:
          return "Pas de pénalité";
      }
    },
    flex: 2,
  },
  {
    headerName: "Commentaire",
    field: "commentaire",
    flex: 2,
  },
];
