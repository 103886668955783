import React, { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ZoneProductionController from "../../../config/apiUtils/ZoneProductionController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";
import AgGrid from "../../../components/AgGrid";
import { RowClickedEvent } from "ag-grid-community";

interface IProps {
  showFormUpdateZoneProduction: (idrefzoneproduction: number) => void;
  onClickNewZoneProduction: () => void;
}

const columns = [
  {
    headerName: "Zone de production",
    field: "libelle",
  },
  {
    headerName: "Nom",
    field: "libellelong",
  },
];

export default function ListeZoneProduction(props: IProps): React.JSX.Element {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.setZonesProduction(
      await ZoneProductionController.getZonesProduction(),
    );
    setLoading(false);
  });

  const onRowClick = async (params: RowClickedEvent) => {
    props.showFormUpdateZoneProduction(params.data.idrefzoneproduction);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Zones de production</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton onClick={props.onClickNewZoneProduction} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {store.zonesProduction !== null &&
              store.zonesProduction !== undefined &&
              !loading && (
                <AgGrid
                  columnDefs={columns}
                  rowData={store.zonesProduction}
                  onRowClicked={onRowClick}
                  showFilterLine
                />
              )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
