export const columns = [
  {
    headerName: "Civilité",
    field: "civilite",
    minWidth: 100,
  },
  {
    headerName: "Nom",
    field: "nom",
    minWidth: 100,
  },
  {
    headerName: "Prénom",
    field: "prenom",
    minWidth: 100,
  },
  {
    headerName: "Contact",
    children: [
      {
        headerName: "Adresse mail",
        field: "mail",
        minWidth: 200,
      },
      {
        headerName: "Téléphone fixe",
        field: "telephonefixe",
        minWidth: 150,
        flex: 2,
      },
      {
        headerName: "Téléphone portable",
        field: "telephoneportable",
        minWidth: 150,
        flex: 2,
      },
    ],
  },
];
