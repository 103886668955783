import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    children: [
      {
        headerName: "Raison sociale",
        field: "exploitation.raisonsociale",
      },
      {
        headerName: "SIRET",
        field: "exploitation.siret",
      },
      {
        headerName: "Statut juridique",
        field: "exploitation.libellestatutjuridique",
        columnGroupShow: "open",
      },
      {
        headerName: "Localisation",
        field: "exploitation.ville",
        valueGetter: (params: any) =>
          params.data.exploitation.codepostal
            ? `${params.data.exploitation.codepostal} ${params.data.exploitation.ville}`
            : "",
        columnGroupShow: "open",
      },
      {
        headerName: "Code OS",
        field: "exploitation.codeos",
        editable: false,
        columnGroupShow: "open",
      },
      {
        headerName: "Zones de production",
        field: "exploitation.libellezoneproduction",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Parcelle",
    children: [
      {
        headerName: "Nom de la parcelle",
        field: "parcelle.nomparcelle",
      },
      {
        headerName: "Localisation parcelle",
        field: "parcelle.nomcommune",
        valueGetter: (params: any) =>
          params.data.parcelle.codepostal
            ? `${params.data.parcelle.codepostal} ${params.data.parcelle.nomcommune}`
            : "",
        columnGroupShow: "open",
      },
      {
        headerName: "Type de sol",
        field: "parcelle.libelletypesol",
        columnGroupShow: "open",
      },
      {
        headerName: "Culture",
        field: "parcelle.libelleculture",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Visite",
    children: [
      {
        headerName: "Nombre de visites",
        field: "nbvisites",
        cellDataType: "number",
        valueGetter: (params: any) =>
          params.data.visites ? params.data.visites.length : null,
      },
      {
        headerName: "Date de la dernière visite",
        field: "datedernierevisite",
        cellDataType: "date",
        valueGetter: (params: any) => {
          if (!params.data || !params.data.visites) return null;

          return params.data.visites.reduce((latest: any, visite: any) => {
            return !latest ||
              new Date(visite.datevisite) > new Date(latest.datevisite)
              ? visite
              : latest;
          }, null)?.datevisite;
        },
        valueFormatter: valueFormatterDate,
      },
      {
        headerName: "Conforme",
        field: "conforme",
        valueGetter: (params: any) => {
          if (!params.data || !params.data.visites) return null;

          return params.data.visites.reduce((latest: any, visite: any) => {
            return !latest ||
              new Date(visite.datevisite) > new Date(latest.datevisite)
              ? visite
              : latest;
          }, null)?.conforme;
        },
        cellRenderer: (params: any) =>
          cellRendererSwitch(params.value, [
            {
              value: true,
              renderer: "OUI",
            },
            {
              value: false,
              renderer: "NON",
            },
            {
              value: null,
              renderer: "Pas de visite",
            },
            {
              value: undefined,
              renderer: "Pas de visite",
            },
          ]),
        cellStyle: (params: any) =>
          cellStyleSwitchDefaultBoolean(
            params.value,
            cellStyleSuccess,
            cellStyleDanger,
            cellStyleWarning,
          ),
      },
    ],
  },
];
