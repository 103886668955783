import {
  cellStyleDanger,
  cellStyleSuccess,
  cellStyleWarning,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Identité",
    children: [
      {
        headerName: "Nom",
        valueGetter: (params) => `${params.data.nom} ${params.data.prenom}`,
        columnGroupShow: "closed",
      },
      {
        headerName: "Nom",
        field: "nom",
        columnGroupShow: "open",
      },
      {
        headerName: "Prénom",
        field: "prenom",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Localisation",
    children: [
      {
        headerName: "Adresse",
        valueGetter: (params) =>
          `${params.data.adresse} - ${params.data.codepostal} ${params.data.nomcommune}`,
        columnGroupShow: "closed",
      },
      {
        headerName: "Adresse",
        field: "adresse",
        columnGroupShow: "open",
      },
      {
        headerName: "Code postal",
        field: "codepostal",
        columnGroupShow: "open",
      },
      {
        headerName: "Commune",
        field: "nomcommune",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Informations",
    children: [
      {
        headerName: "Adresse mail",
        field: "mail",
      },
      {
        headerName: "Téléphone",
        field: "telephone",
      },
      {
        headerName: "État",
        field: "idetatvalidation",
        cellStyle: function (params) {
          switch (params.value) {
            case 1:
              return cellStyleDanger;
            case 2:
            case 3:
              return cellStyleWarning;
            case 4:
              return cellStyleSuccess;
            default:
              return cellStyleDanger;
          }
        },
        cellRenderer: (params) => {
          switch (params.value) {
            case 1:
              return "Non validé";
            case 2:
              return "En cours de validation";
            case 3:
              return "En attente d'informations supplémentaires";
            case 4:
              return "Validé";
            default:
              return "Erreur";
          }
        },
      },
    ],
  },
];
