import React, { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeIntervention from "./TypeIntervention/ListeTypeIntervention";
import FormNewTypeIntervention from "./TypeIntervention/FormNewTypeIntervention";
import FormUpdateTypeIntervention from "./TypeIntervention/FormUpdateTypeIntervention";
import { createNotification } from "../../config/utils";
import {
  ITypeInterventionPOST,
  ITypeInterventionPUT,
} from "src/config/types/referentiel";

export default function TypeIntervention(): React.JSX.Element {
  const store = useContext(StoreContext);
  const [showFormNewTypeIntervention, setShowFormNewTypeIntervention] =
    useState<boolean>(false);
  const [showFormUpdateTypeIntervention, setShowFormUpdateTypeIntervention] =
    useState<boolean>(false);
  const [idreftypeinterventionToUpdate, setIdreftypeinterventionToUpdate] =
    useState<number | null>(null);

  const handleAddNewTypeIntervention = async (
    typeIntervention: ITypeInterventionPOST,
  ) => {
    const res =
      await ReferentielController.insertTypeIntervention(typeIntervention);
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type d'intervention créé avec succès",
      );
      store.referentiels.setTypesInterventions([
        ...store.referentiels.typesInterventions,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type d'intervention",
      );
    }
  };

  const handleUpdateTypeIntervention = async (
    typeIntervention: ITypeInterventionPUT,
  ) => {
    if (idreftypeinterventionToUpdate == null) {
      return;
    }

    const res = await ReferentielController.updateTypeIntervention(
      idreftypeinterventionToUpdate,
      typeIntervention,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type d'intervention modifié avec succès",
      );
      store.referentiels.setTypesInterventions(
        await ReferentielController.getTypesIntervention(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type d'intervention",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeIntervention}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeIntervention
          onClickAdd={handleAddNewTypeIntervention}
          onClickClose={() => setShowFormNewTypeIntervention(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeIntervention}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeIntervention
          onClickUpdate={handleUpdateTypeIntervention}
          onClickClose={() => setShowFormUpdateTypeIntervention(false)}
          idreftypeintervention={idreftypeinterventionToUpdate}
        />
      </Collapse>
      <ListeTypeIntervention
        showFormUpdateTypeIntervention={(idreftypeintervention: number) => {
          setIdreftypeinterventionToUpdate(idreftypeintervention);
          setShowFormUpdateTypeIntervention(true);
        }}
        onClickNewType={() => setShowFormNewTypeIntervention(true)}
      />
    </>
  );
}
