import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { columns } from "../../components/Grids/Exploitation/contrats";
import StoreContext from "../../context/StoreContext";
import droits from "../../config/CodesDroits";
import { RenderIf } from "../../config/utils";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../components/AgGrid";

export default function ContratsExploitation() {
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  const onRowClick = async (params) => {
    if (
      store.utilisateur.informations.isProducteur &&
      params.data.nbparcelles - params.data.nbparcellesrenseignee > 0
    ) {
      navigate(
        `/app/carto/parcellaire-exploitation?section=contrat&contrat=contrat-${params.data.idcontrat}&completer=true`,
      );
    } else {
      navigate(
        `/app/carto/parcellaire-exploitation?section=contrat&contrat=contrat-${params.data.idcontrat}`,
      );
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Contrats de production</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={store.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_CONTRAT,
                  droits.modalite.creation,
                )}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    navigate(
                      "/app/carto/parcellaire-exploitation?newcontrat=true",
                    );
                  }}
                >
                  <i className="fa-solid fa-plus" />
                </Button>
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <AgGrid
                columnDefs={columns}
                rowData={store.contratsExploitation}
                onRowClicked={onRowClick}
                height={350}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
