import React, { useContext, useState } from "react";
import { Alert, Button, Col, Collapse, Row } from "reactstrap";
import CommandeController from "src/config/apiUtils/CommandeController";
import StoreContext from "src/context/StoreContext";
import FormCommandeIndustriel from "./commandeindustrielle/FormCommandeIndustriel";
import FormUpdateCommande from "./commandeindustrielle/FormUpdateCommande";
import TableCommandeIndustrielle from "./commandeindustrielle/TableCommandeIndustrielle";
import useConstructor from "../config/hooks/useConstructor";
import { createNotification, RenderIf } from "src/config/utils";
import droits from "src/config/CodesDroits";
import {
  ICommande,
  ICommandePOST,
  ICommandePUT,
} from "src/config/types/commande";

export default function CommandeIndustrielle(): React.JSX.Element {
  const store = useContext(StoreContext);

  const [showNewCommandForm, setShowNewCommandForm] = useState<boolean>(false);
  const [showUpdateCommandForm, setShowUpdateCommandForm] =
    useState<boolean>(false);
  const [commandeExisteForMillesime, setCommandeExisteForMillesime] =
    useState<boolean>(false);
  const [alerteVisible, setAlerteVisible] = useState<boolean>(true);
  const [selectedCommande, setSelectedCommande] = useState<ICommande | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);

  useConstructor(async () => {
    setLoading(true);
    store.setCommandes(await CommandeController.getCommandes());
    setLoading(false);
  });

  const hideAlert = () => {
    setAlerteVisible(!alerteVisible);
  };

  const onSelectCommande = (commande: ICommande) => {
    setSelectedCommande(commande);
    setShowUpdateCommandForm(true);
  };

  const reloadCommandeStore = async () => {
    const resCommandes = await CommandeController.getCommandes();
    store.setCommandes(resCommandes);
  };

  const onClickAddCommande = async (commande: ICommandePOST) => {
    const resUpdateCommande = await CommandeController.addCommande(commande);
    if (resUpdateCommande.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Nouvelle commande créée pour l'année " + commande.millesime,
      );
      await reloadCommandeStore();
    }
  };

  const onClickUpdateCommande = async (commande: ICommandePUT) => {
    if (!selectedCommande) {
      return;
    }

    const resUpdateCommande = await CommandeController.updateCommande(
      selectedCommande.idcommande,
      commande,
    );
    if (resUpdateCommande.status === 200) {
      createNotification("success", "Succès", "Commande modifiée avec succès");
      await reloadCommandeStore();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification de la commande...",
      );
    }
  };

  const commandeExistsInMillesime = () => {
    let commandeExiste = false;
    store.commandes.forEach((commande: ICommande) => {
      if (commande.millesime === store.millesime.idmillesime) {
        setCommandeExisteForMillesime(true);
        commandeExiste = true;
      }
    });
    return commandeExiste;
  };

  const hideFormNewCommande = (): void => setShowNewCommandForm(false);

  const renderAlertCommandeExiste = (): React.JSX.Element => {
    return (
      <Alert color="danger" isOpen={alerteVisible} toggle={hideAlert}>
        Une commande pour l'année de récolte {store.millesime.idmillesime} a
        déjà été renseignée.
      </Alert>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Consultation commandes industrielles</h2>
        </Col>
        <RenderIf
          isTrue={store.utilisateur.hasDroits(
            droits.typesdroits.GESTION_COMMANDE,
            droits.modalite.creation,
          )}
        >
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              color="primary"
              onClick={() => {
                if (commandeExistsInMillesime()) {
                  setAlerteVisible(true);
                } else {
                  setShowNewCommandForm(true);
                  setAlerteVisible(true);
                }
              }}
            >
              <i className="fa-solid fa-plus" />
            </Button>
          </Col>
        </RenderIf>
      </Row>
      <RenderIf
        isTrue={store.utilisateur.hasDroits(
          droits.typesdroits.GESTION_COMMANDE,
          droits.modalite.lecture,
        )}
      >
        <>
          <Row style={{ marginTop: 30 }}>
            <RenderIf isTrue={commandeExisteForMillesime}>
              <Col>{renderAlertCommandeExiste()}</Col>
            </RenderIf>
            <Col md="12" style={{ marginBottom: 30 }}>
              <Collapse
                isOpen={showUpdateCommandForm}
                style={{ marginBottom: 30 }}
                mountOnEnter
                unmountOnExit
              >
                <RenderIf
                  isTrue={
                    selectedCommande &&
                    store.utilisateur.hasDroits(
                      droits.typesdroits.GESTION_COMMANDE,
                      droits.modalite.modification,
                    )
                  }
                >
                  <FormUpdateCommande
                    hideFormUpdateCommande={() =>
                      setShowUpdateCommandForm(false)
                    }
                    onClickUpdateCommande={onClickUpdateCommande}
                    commande={selectedCommande}
                  />
                </RenderIf>
              </Collapse>
              <RenderIf isTrue={!commandeExisteForMillesime}>
                <Collapse
                  isOpen={showNewCommandForm}
                  mountOnEnter
                  unmountOnExit
                >
                  <FormCommandeIndustriel
                    onClickAddCommande={onClickAddCommande}
                    hideFormNewCommande={hideFormNewCommande}
                  />
                </Collapse>
              </RenderIf>
              <RenderIf
                isTrue={
                  showNewCommandForm &&
                  commandeExisteForMillesime &&
                  alerteVisible
                }
              >
                {renderAlertCommandeExiste()}
              </RenderIf>
            </Col>
          </Row>
          <Row>
            <Col md="12" style={{ marginBottom: 30 }}>
              <TableCommandeIndustrielle
                loading={loading}
                commandes={store.commandes}
                onSelectCommande={onSelectCommande}
              />
            </Col>
          </Row>
        </>
      </RenderIf>
    </>
  );
}
