import React, { Fragment, useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { newColumns } from "../../components/Grids/planproduction/listeparparcelle";
import StoreContext from "../../context/StoreContext";
import CloseButton from "../../components/Buttons/CloseButton";
import moment from "moment";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  flattenList,
  RenderIf,
} from "../../config/utils";
import CsvButton from "../../components/Buttons/CsvButton";
import PlanProductionEditionController from "../../config/apiUtils/PlanProductionEditionController";
import useConstructor from "../../config/hooks/useConstructor";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import SiloController from "../../config/apiUtils/SiloController";
import AgGrid from "../../components/AgGrid";
import { getColumnsByField, getColumnsMapForCsv } from "../../config/gridUtils";
import { IPlanProduction } from "../../config/types/planproduction";
import { CellEditingStoppedEvent } from "ag-grid-community";
import { ISilo } from "../../config/types/silo";

const fieldsToCopy = [
  "numerocontrat",
  "exploitation.codedc",
  "exploitation.numerounilever",
  "exploitation.raisonsociale",
  "parcelle.nomparcelle",
];

interface IProps {
  close: () => void;
}

export default function ListeParParcelle(props: IProps): React.JSX.Element {
  const store = useContext(StoreContext);

  const columns = [...newColumns];
  const [gridLoaded, setGridLoaded] = useState(false);

  const [dataSource, setDataSource] = useState<IPlanProduction[]>(
    store.planProduction.planProductionParcelle,
  );
  const [totalAutomne, setTotalAutomne] = useState(0);
  const [totalPrintemps, setTotalPrintemps] = useState(0);
  const [varietes, setVarietes] = useState<Record<string, number>>({});
  const [totalBio, setTotalBio] = useState(0);
  const [totalNonBio, setTotalNonBio] = useState(0);

  const loadDataSource = () => {
    let totPrintemps: number = 0;
    let totAutomne: number = 0;
    let totBio: number = 0;
    let totNonBio: number = 0;
    const surfacesVarietes: Record<string, number> = {};
    dataSource.forEach((element: IPlanProduction) => {
      totPrintemps += element.surfaceprintemps ?? 0;
      totAutomne += element.surfaceautomne ?? 0;
      if (element.idreftypeproduction === 4) {
        totBio +=
          (element.surfaceprintemps ?? 0) + (element.surfaceautomne ?? 0);
      } else {
        totNonBio +=
          (element.surfaceprintemps ?? 0) + (element.surfaceautomne ?? 0);
      }

      if (
        Object.prototype.hasOwnProperty.call(
          surfacesVarietes,
          element.libellevariete ?? "null",
        )
      ) {
        surfacesVarietes[element.libellevariete ?? "null"] +=
          (element.surfaceprintemps ?? 0) + (element.surfaceautomne ?? 0);
      } else {
        surfacesVarietes[element.libellevariete ?? "null"] =
          (element.surfaceprintemps ?? 0) + (element.surfaceautomne ?? 0);
      }
    });
    setTotalPrintemps(parseFloat(totPrintemps.toFixed(2)));
    setTotalAutomne(parseFloat(totAutomne.toFixed(2)));
    setTotalBio(parseFloat(totBio.toFixed(2)));
    setTotalNonBio(parseFloat(totNonBio.toFixed(2)));
    setVarietes(surfacesVarietes);
  };

  useEffect(() => {
    setDataSource(store.planProduction.planProductionParcelle);
    loadDataSource();
  }, [store.planProduction.planProductionParcelle, dataSource, props]);

  const renderSurfacesVarietes = () => {
    const libelles = Object.keys(varietes);
    return libelles.map((libellevariete, i) => {
      return (
        <Fragment key={i}>
          Total {libellevariete != "null" ? libellevariete : "sans variété"} :{" "}
          {varietes[libellevariete] !== null
            ? varietes[libellevariete].toFixed(2)
            : 0}{" "}
          Ha {i + 1 == libelles.length ? "" : "| "}
        </Fragment>
      );
    });
  };

  const onEditComplete = async (params: CellEditingStoppedEvent) => {
    const column = getColumnsByField(columns, params.colDef.field);
    const fieldToUpdate = column.field.split(".").at(-1);

    const res = await PlanProductionEditionController.updateField(
      params.data.idplanproduction,
      fieldToUpdate,
      params.newValue,
    );

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      const tmp = dataSource;
      const rowToUpdate: IPlanProduction | undefined = tmp.find(
        (e: IPlanProduction) =>
          e.idplanproduction === params.data.idplanproduction,
      );
      if (rowToUpdate !== undefined) {
        switch (fieldToUpdate) {
          case "idetatvalidationadministrative":
            tmp
              .filter((e) => e.idcontrat === params.data.idcontrat)
              .forEach((e) => {
                e.idetatvalidationadministrative =
                  column.cellEditorParams.values.find(
                    (e: any) => e.value === params.newValue,
                  ).value;
                e.valide = params.newValue === 4;
                e.libellevalidationadministrative =
                  column.cellEditorParams.values.find(
                    (e: any) => e.value === params.newValue,
                  ).label;
              });
            break;
          case "idsilo":
            rowToUpdate.nomsilo = column.cellEditorParams.values.find(
              (e: any) => e.value === params.newValue,
            ).label;
            break;
          case "idsilodefinitif":
            rowToUpdate.nomsilodefinitif = column.cellEditorParams.values.find(
              (e: any) => e.value === params.newValue,
            ).label;
            break;
          case "idrefvariete":
            rowToUpdate.libellevariete = column.cellEditorParams.values.find(
              (e: any) => e.value === params.newValue,
            ).label;
            break;
          case "rendementrefmoyen":
            tmp
              .filter(
                (e) =>
                  e.exploitation.idexploitation ===
                  params.data.exploitation.idexploitation,
              )
              .forEach((e) => {
                e.exploitation.rendementrefmoyen = params.newValue;
                e.tonnage =
                  params.newValue && e.surfacetotale
                    ? parseFloat((params.newValue * e.surfacetotale).toFixed(2))
                    : null;
              });
            break;
          case "rendementrefmillesime":
            tmp
              .filter(
                (e) =>
                  e.exploitation.idexploitation ===
                  params.data.exploitation.idexploitation,
              )
              .forEach((e) => {
                e.exploitation.rendementrefmillesime = params.newValue;
                e.tonnageestime =
                  params.newValue && e.surfacetotale
                    ? parseFloat((params.newValue * e.surfacetotale).toFixed(2))
                    : null;
              });
            break;
          case "codedc":
            tmp
              .filter(
                (e) =>
                  e.exploitation.idexploitation ===
                  params.data.exploitation.idexploitation,
              )
              .forEach((e) => {
                e.exploitation.codedc = params.newValue;
              });
            break;
          default:
            break;
        }

        // @ts-ignore
        rowToUpdate[column.field] = params.newValue;

        if (["idsilo", "idsilodefinitif"].includes(fieldToUpdate)) {
          rowToUpdate.siloreprismillesimeprecedent = false;
        }
      }
      loadDataSource();
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  const onCellDoubleClicked = (params: any) => {
    if (fieldsToCopy.includes(params.colDef.field)) {
      navigator.clipboard.writeText(params.value);
      createNotification(
        "info",
        "Information",
        params.colDef.headerName + " copié dans le presse-papier",
        1000,
      );
    }
  };

  useConstructor(async () => {
    setGridLoaded(false);
    getColumnsByField(columns, "idrefvariete").cellEditorParams.values = (
      await ReferentielController.getVarietes(true)
    ).map((variete) => {
      return {
        value: variete.idrefvariete,
        label: variete.libelle,
      };
    });
    getColumnsByField(columns, "idsilo").cellEditorParams.values = (
      await SiloController.getSilos(1, store.millesime.idmillesime, true)
    ).map((silo: ISilo) => {
      return {
        value: silo.idsilo,
        label: silo.nomsilo,
      };
    });
    getColumnsByField(columns, "idsilodefinitif").cellEditorParams.values = (
      await SiloController.getSilos(2, store.millesime.idmillesime, true)
    ).map((silo: ISilo) => {
      return {
        value: silo.idsilo,
        label: silo.nomsilo,
      };
    });
    getColumnsByField(
      columns,
      "idsilodefinitif",
    ).cellEditorParams.values.unshift({
      value: null,
      label: "PAS DE SILO",
    });

    setGridLoaded(true);
  });

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Plan de production par parcelles</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={dataSource.length > 0}>
              <CsvButton
                color="primary"
                style={{ marginRight: 30 }}
                onClick={() => {
                  downloadCsv(
                    convertToCSV(
                      flattenList(dataSource),
                      ";",
                      getColumnsMapForCsv(columns),
                    ),
                    "plan_prod_parcelles_" +
                      store.millesime.idmillesime +
                      "_" +
                      moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
            <CloseButton onClick={props.close} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={gridLoaded}>
          <>
            <AgGrid
              columnDefs={[...columns]}
              rowData={dataSource}
              showFilterLine
              height={700}
              onCellEditingStopped={onEditComplete}
              onCellDoubleClicked={onCellDoubleClicked}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                fontWeight: "bold",
              }}
            >
              Total printemps : {totalPrintemps} Ha | Total automne :{" "}
              {totalAutomne} Ha | Total :{" "}
              {(totalAutomne + totalPrintemps).toFixed(2)} Ha
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                fontWeight: "bold",
              }}
            >
              Total bio : {totalBio} Ha | Total non bio : {totalNonBio} Ha
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              {renderSurfacesVarietes()}
            </div>
          </>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
