import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import AnalysesController from "../../config/apiUtils/AnalysesController";
import moment from "moment";
import { columns } from "../../components/Grids/Analyses/exploitationszoneproduction";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import Analyses from "../Analyses";
import CsvButton from "../../components/Buttons/CsvButton";
import AgGrid from "../../components/AgGrid";
import { getColumnsMapForCsv } from "../../config/gridUtils";
import { colourStyles } from "../../config/carto/utils";
import Select from "react-select";
import { IExploitationZoneProduction } from "../../config/types/analyses";

export default function ExploitationsZoneProduction(): React.JSX.Element {
  const [data, setData] = useState<IExploitationZoneProduction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [zone, setZone] = useState<number | null>(null);

  useConstructor(async () => {
    setLoading(true);
    setData(await AnalysesController.getExploitationsZoneProduction());
    setLoading(false);
  });

  const renderZone = (
    item: IExploitationZoneProduction | null | undefined,
  ): React.JSX.Element => {
    if (item === null || item === undefined) {
      return <></>;
    }

    return (
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <CardTitle tag={"h5"}>
                {item.zone.libellelong ?? item.zone.libelle}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf isTrue={data.length > 0}>
                <CsvButton
                  color="primary"
                  onClick={() => {
                    const name =
                      "analyses_exploitations_zone_production_" +
                      (item.zone.libellelong ?? item.zone.libelle) +
                      "_" +
                      moment().format("YYYY-MM-DD");
                    downloadCsv(
                      convertToCSV(
                        item.exploitations,
                        ";",
                        getColumnsMapForCsv(columns),
                      ),
                      name,
                    );
                    createNotification(
                      "info",
                      "Information",
                      "Export téléchargé dans le fichier : " + name,
                    );
                  }}
                />
              </RenderIf>
            </Col>
          </Row>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <AgGrid
              rowData={item.exploitations}
              columnDefs={columns}
              showFilterLine
            />
          </RenderIf>
        </Col>
      </Row>
    );
  };

  return (
    <Analyses>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>
                    Exploitations par zone de production
                  </CardTitle>
                </Col>
                <Col
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                ></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <>
                  <Row>
                    <Col md="12" style={{ display: "flex", marginBottom: 20 }}>
                      <div style={{ minWidth: 300 }}>
                        <Select
                          isClearable
                          classNamePrefix="react-select"
                          placeholder="Zone de production..."
                          menuPortalTarget={document.body}
                          menuPlacement={"auto"}
                          menuPosition={"fixed"}
                          styles={colourStyles}
                          options={data.map((item) => ({
                            key: item.zone.idrefzoneproduction,
                            label: item.zone.libellelong ?? item.zone.libelle,
                          }))}
                          onChange={(selection) => {
                            if (selection == null) {
                              setZone(null);
                              return;
                            }
                            setZone(selection.key);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <RenderIf isTrue={zone !== null}>
                    {renderZone(
                      data.find(
                        (item) => item.zone.idrefzoneproduction === zone,
                      ),
                    )}
                  </RenderIf>
                </>
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Analyses>
  );
}
