import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Numero de contrat",
    field: "numerocontrat",
  },
  {
    headerName: "Nombre de parcelles totales",
    field: "nbparcellestotales",
  },
  {
    headerName: "Nombre de parcelles restantes",
    field: "nbparcellesrestantes",
  },
  {
    headerName: "Contrat abandonné",
    field: "abandon",
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleDefault,
      ),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
];
