import { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import StoreContext from "../../context/StoreContext";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf } from "../../config/utils";
import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
} from "../../config/gridUtils";
import AgGrid from "../../components/AgGrid";

const columns = [
  {
    headerName: "Nom du silo",
    field: "nomsilo",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Actif",
    field: "actif",
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
];

export default function TableSilo(props) {
  const store = useContext(StoreContext);
  const [silos, setSilos] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    OrganismeStockeurController.getSiloByIdOrganismeStockeur(
      props.idorganismestockeurSelected,
      store.millesime.idmillesime,
    ).then((res) => {
      setSilos(res);
      setLoading(false);
    });
  }, [props.idorganismestockeurSelected]);

  const onRowClick = (params) => {
    props.onClickSilo(params.data.idsilo);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Silos rattachés à l'organisme</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <AgGrid
            columnDefs={columns}
            rowData={silos}
            onRowClicked={onRowClick}
            height={600}
            showFilterLine
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
