export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Année d'adhésion",
    field: "millesimeadhesion",
  },
  {
    headerName: "Code DC",
    field: "codedc",
  },
  {
    headerName: "Adresse",
    field: "adresse",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Mail",
    field: "mail",
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
  },
  {
    headerName: "Zone de production",
    field: "libellezoneproduction",
  },
  {
    headerName: "Type de production",
    field: "libelletypeproduction",
  },
  {
    headerName: "Variete",
    field: "libellevariete",
  },
  {
    headerName: "Silo",
    field: "nomsilo",
  },
];
