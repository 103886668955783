import { useContext, useState } from "react";
import { Col, Collapse, Row } from "reactstrap";
import useConstructor from "../config/hooks/useConstructor";
import StoreContext from "../context/StoreContext";
import FichierController from "../config/apiUtils/FichierController";
import AddButton from "../components/Buttons/AddButton";
import FormNewFichier from "./documentexploitation/FormNewFichier";
import { RenderIf } from "../config/utils";
import droits from "../config/CodesDroits";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import MesDocuments from "./documentexploitation/MesDocuments";

export default function DocumentExploitation() {
  const store = useContext(StoreContext);
  const [documents, setDocuments] = useState([]);
  const [showFormNewFichier, setShowFormNewFichier] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadDocuments = async () => {
    setLoading(true);
    const resDocuments = await FichierController.getListDocuments();
    setDocuments(resDocuments);
    setLoading(false);
  };

  useConstructor(async () => {
    await loadDocuments();
  });

  return (
    <>
      <Row>
        <Col>
          <h2>Mes documents</h2>
        </Col>
        <RenderIf
          isTrue={store.utilisateur.hasDroits(
            droits.typesdroits.GESTION_DOCUMENT,
            droits.modalite.creation,
          )}
        >
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton onClick={() => setShowFormNewFichier(true)} />
          </Col>
        </RenderIf>
      </Row>
      <Collapse isOpen={showFormNewFichier} mountOnEnter unmountOnExit>
        <Row style={{ marginTop: 30 }}>
          <Col>
            <FormNewFichier
              hideFormNewFichier={() => setShowFormNewFichier(false)}
              loadDocuments={loadDocuments}
            />
          </Col>
        </Row>
      </Collapse>
      <Row style={{ marginTop: 30 }}>
        <Col>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <MesDocuments documents={documents} loadDocuments={loadDocuments} />
          </RenderIf>
        </Col>
      </Row>
    </>
  );
}
