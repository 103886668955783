import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/index.css";
import App from "./App";
import "./assets/css/userLayout.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import CustomSelectEditorModule from "./components/AgGrid/customSelectCellEditor";

ModuleRegistry.registerModules([CustomSelectEditorModule, AllCommunityModule]);

const root = createRoot(document.getElementById("root"));

root.render(
  <>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      style={{
        width: "fit-content",
      }}
    />
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </>,
);
