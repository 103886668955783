import React from "react";
import CotisationAnnuelle from "../../views/CotisationAnnuelle";
import FormulaireUtilisateur from "../../views/FormulaireUtilisateur";
import ListeExploitation from "../../views/ListeExploitation";
import ListeParcelles from "../../views/ListeParcelles";
import ListeProducteur from "../../views/ListeProducteur";
import ListeUtilisateurs from "../../views/ListeUtilisateurs";
import Profils from "../../views/Profils";
import Reglages from "../../views/Reglages";

import droits from "../CodesDroits";
import Layouts from "../layoutRoutes";

const AdminRoutes = [
  {
    path: "/settings",
    name: "Réglages",
    element: <Reglages />,
    restriction: droits.typesdroits.ACCES_ADMINISTRATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Réglages", "Options", "Paramêtres"],
  },
  {
    path: "/utilisateurs",
    name: "Utilisateurs",
    element: null,
    layout: Layouts.adminLayout,
    show: true,
  },
  {
    path: "/producteurs",
    name: "Producteurs",
    element: null,
    layout: Layouts.adminLayout,
    show: true,
  },
  {
    path: "/profils",
    name: "Profils",
    element: <Profils />,
    restriction: droits.typesdroits.ACCES_ADMINISTRATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/liste-utilisateurs",
    name: "Utilisateurs | Administration",
    element: <ListeUtilisateurs />,
    restriction: droits.typesdroits.ACCES_ADMINISTRATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Réglages", "Liste", "utilisateurs"],
  },
  {
    path: "/liste-exploitations",
    name: "Exploitations | Administration",
    element: <ListeExploitation />,
    restriction: droits.typesdroits.ACCES_ADMINISTRATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    pageCarto: false,
    motsCles: ["Réglages", "Liste", "exploitations"],
  },
  {
    path: "/liste-parcelles",
    name: "Parcelles",
    element: <ListeParcelles />,
    restriction: droits.typesdroits.ACCES_ADMINISTRATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Réglages", "Liste", "parcelles"],
  },
  {
    path: "/liste-producteurs",
    name: "Producteurs",
    element: <ListeProducteur />,
    restriction: droits.typesdroits.ACCES_ADMINISTRATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Réglages", "Liste", "producteurs"],
  },
  {
    path: "/cotisations-annuelle",
    name: "Cotisations annuelles | Administration",
    element: <CotisationAnnuelle />,
    restriction: droits.typesdroits.ACCES_ADMINISTRATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Réglages", "cotisations", "annuelles"],
  },
  {
    path: "/creation-utilisateur",
    name: "Formulaires utilisateur",
    element: <FormulaireUtilisateur />,
    restriction: droits.typesdroits.ACCES_ADMINISTRATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    motsCles: ["Réglages", "Formulaire", "utilisateurs"],
  },
];

export default AdminRoutes;
