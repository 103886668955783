import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import { columns } from "../../components/Grids/BilanAnnuel/tablebilanannuelorganismestockeur";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import PlanProductionController from "../../config/apiUtils/PlanProductionController";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  downloadCsv,
  getQueryParam,
  RenderIf,
  setQueryParam,
} from "../../config/utils";
import StoreContext from "../../context/StoreContext";
import moment from "moment";
import CsvButton from "../../components/Buttons/CsvButton";
import AgGrid from "../../components/AgGrid";

export default function TableBilanAnnuelOrganismeStockeur(props) {
  const store = useContext(StoreContext);
  const [idOrganismeStockeur, setIdOrganismeStockeur] = useState("-1");
  const [organisme, setOrganisme] = useState({});
  const [organismesStockeur, setOrganismesStockeur] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const mapKeysNames = {
    numerocontrat: "Contrat",
    idrefvariete: "Code variété",
    libellevariete: "Variété",
    quantitelivree: "Quantité livrée",
    nomsilodefinitif: "Silo définitif",
    nomorganismestockeur: "Organisme stockeur",
    raisonsociale: "Exploitation",
    siret: "Numéro SIRET",
    codepostal: "Code postal",
    nomcommune: "Commune",
    codedc: "Code DC",
  };

  useConstructor(async () => {
    const res = await OrganismeStockeurController.getOrganismesStockeur();
    res.unshift({
      idorganismestockeur: "-1",
      nomorganismestockeur: "--",
    });
    setOrganismesStockeur(res);

    if (getQueryParam("idos") && getQueryParam("idos") !== "") {
      setIdOrganismeStockeur(getQueryParam("idos"));
    }
  });

  const changeBilanOrganisme = async () => {
    setLoading(true);
    if (idOrganismeStockeur === "-1") {
      store.planProduction.setPlanProductionOrganismeStockeur([]);
    } else {
      setOrganisme(
        organismesStockeur.find(
          (organismestockeur) =>
            organismestockeur.idorganismestockeur ===
            parseInt(idOrganismeStockeur),
        ),
      );
      setDataSource(
        await PlanProductionController.getBilanRecolteByIdorganismestockeur(
          store.millesime.idmillesime,
          parseInt(idOrganismeStockeur),
        ),
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    changeBilanOrganisme();
  }, [idOrganismeStockeur]);

  const renderOrganismesStockeurOption = () => {
    return organismesStockeur.map((organisme, key) => {
      return (
        <option value={organisme.idorganismestockeur} key={key}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Bilan par organisme stockeur</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf isTrue={dataSource.length > 0}>
                <CsvButton
                  color="primary"
                  style={{ marginRight: 30 }}
                  onClick={() => {
                    downloadCsv(
                      convertToCSV(dataSource, ";", mapKeysNames),
                      "bilan_recolte_os_" +
                        organisme.nomorganismestockeur.replace(" ", "_") +
                        "_" +
                        store.millesime.idmillesime +
                        "_" +
                        moment().format("YYYY-MM-DD"),
                    );
                  }}
                />
              </RenderIf>
              <CloseButton onClick={props.onClickClose} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <Label for="idorganismestockeur">
                Sélectionnez l'organisme stockeur
              </Label>
              <Input
                type="select"
                className="select-input"
                name="idorganismestockeur"
                value={idOrganismeStockeur}
                onChange={(event) => {
                  setQueryParam("idos", event.target.value);
                  setIdOrganismeStockeur(event.target.value);
                }}
              >
                {renderOrganismesStockeurOption()}
              </Input>
            </Col>
          </Row>
          <hr />
          <Row>
            <h4>
              <b>
                {idOrganismeStockeur !== "-1"
                  ? organisme.nomorganismestockeur
                  : "Veuillez sélectionner un organisme stockeur"}
              </b>
            </h4>
            <RenderIf isTrue={loading}>
              <SpinLoadingAnimation />
            </RenderIf>
            <RenderIf isTrue={!loading && idOrganismeStockeur !== "-1"}>
              <Col>
                <AgGrid
                  columnDefs={columns}
                  rowData={dataSource}
                  height={600}
                  showFilterLine
                />
              </Col>
            </RenderIf>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
