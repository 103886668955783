export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Type de certification",
    field: "libellecertification",
    minWidth: 130,
    flex: 1,
  },
  {
    headerName: "Millesime",
    field: "millesime",
    minWidth: 130,
    flex: 1,
  },
  {
    headerName: "Date de certification",
    field: "datecertification",
    minWidth: 150,
    flex: 1,
  },
];
