import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ProducteurController from "../config/apiUtils/ProducteurController";
import FormationController from "../config/apiUtils/FormationController";
import ReferentielController from "../config/apiUtils/ReferentielController";
import useConstructor from "../config/hooks/useConstructor";
import StoreContext from "../context/StoreContext";
import FormUpdateFormation from "./formation/FormUpdateFormation";
import FicheProducteur from "./producteurs/FicheProducteur";
import FormationProducteur from "./producteurs/FormationProducteur";
import FormFormationProducteur from "./producteurs/FormFormationProducteur";
import FormNewProducteur from "./producteurs/FormNewProducteur";
import FormUpdateProducteur from "./producteurs/FormUpdateProducteur";
import TableProducteur from "./producteurs/TableProducteur";
import { createNotification, RenderIf } from "../config/utils";

export default function Producteurs() {
  const store = useContext(StoreContext);

  const [showNewFormationForm, setShowNewFormationForm] = useState(false);
  const [showUpdateFormationForm, setShowUpdateFormationForm] = useState(false);
  const [showNewProducteurForm, setShowNewProducteurForm] = useState(false);
  const [showUpdateProducteurForm, setShowUpdateProducteurForm] =
    useState(false);
  const [showInfoProducteur, setShowInfoProducteur] = useState(false);
  const [selectedProducteur, setSelectedProducteur] = useState({});
  const [selectedFormation, setSelectedFormation] = useState({});
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    if (selectedProducteur.idproducteur !== undefined) {
      if (store.exploitation.producteurs.length === 1) {
        onSelectProducteur(store.exploitation.producteurs[0]);
      }

      FormationController.getFormationsByIdproducteur(
        selectedProducteur.idproducteur,
      ).then((res) => store.formations.setFormations(res));
    }
  }, [
    selectedProducteur.idproducteur,
    store.millesime.idmillesime,
    store.exploitation.producteurs,
  ]);

  const hideFormUpdateFormation = () => {
    setShowUpdateFormationForm(false);
  };

  const hideUpdateProducteurForm = () => setShowUpdateProducteurForm(false);

  const onClickSaveNewFormation = async (formation) => {
    const resFormation = await FormationController.createFormation(formation);
    if (resFormation.status === 200 && resFormation.data.success) {
      createNotification("success", "Succès", "Formation créée avec succès");
      const resFormations =
        await FormationController.getFormationsByIdproducteur(
          selectedProducteur.idproducteur,
        );
      store.formations.setFormations(resFormations);
      store.formations.updateFormationsMillesime();
      setShowNewFormationForm(false);
    } else {
      createNotification(
        "error",
        "Une erreur est survenue",
        resFormation.data.message,
      );
      //setModal(true);
      //setModalContent({ title: "Erreur", message: resFormation.data.message });
    }
  };

  const onClickUpdateFormation = async (idformationproducteur, formation) => {
    const resFormation = await FormationController.updateFormation(
      idformationproducteur,
      formation,
    );

    if (resFormation.status === 200) {
      createNotification("success", "Succès", "Formation modifiée avec succès");
      const resFormations =
        await FormationController.getFormationsByIdproducteur(
          selectedProducteur.idproducteur,
        );
      store.formations.setFormations(resFormations);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification de la formation...",
      );
      setModal(true);
      setModalContent({
        title: "Erreur de modification",
        message: resFormation.data.message,
      });
    }
  };

  const onClickNewProducteur = async (producteur) => {
    const resProducteur =
      await ProducteurController.insertProducteur(producteur);

    if (resProducteur.status === 200) {
      createNotification("success", "Succès", "Nouveau producteur créé");
      const resProducteurs =
        await ProducteurController.getProducteurByIdexploitation(
          store.exploitation.informations.idexploitation,
        );
      store.exploitation.setProducteurs(resProducteurs);
      resProducteurs.producteurs.forEach((producteur) => {
        if (producteur.idproducteur === selectedProducteur.idproducteur) {
          setSelectedProducteur(producteur);
        }
      });
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la création du producteur...",
      );
      setModal(true);
      setModalContent({
        title: "Erreur d'ajout",
        message: resProducteur.data.message,
      });
    }
  };

  const onClickUpdateProducteur = async (idproducteur, producteur) => {
    const resProducteur = await ProducteurController.updateProducteur(
      idproducteur,
      producteur,
    );

    if (resProducteur.status === 200) {
      createNotification("success", "Succès", "Producteur modifié avec succès");
      const resProducteurs =
        await ProducteurController.getProducteurByIdexploitation(
          store.exploitation.informations.idexploitation,
        );
      store.exploitation.setProducteurs(resProducteurs);
      resProducteurs.producteurs.forEach((producteur) => {
        if (producteur.idproducteur === selectedProducteur.idproducteur) {
          setSelectedProducteur(producteur);
        }
      });
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du producteur",
      );
      setModal(true);
      setModalContent({
        title: "Erreur de modification",
        message: resProducteur.data.message,
      });
    }
  };

  const onClickDeleteFormation = async (idformationproducteur) => {
    const resFormation = await FormationController.deleteFormation(
      idformationproducteur,
    );
    if (resFormation.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Formation supprimée avec succès",
      );
      const resFormations =
        await FormationController.getFormationsByIdproducteur(
          selectedProducteur.idproducteur,
        );
      store.formations.setFormations(resFormations);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la suppression de la formation",
      );
    }
  };

  const onSelectFormation = (formation) => {
    setSelectedFormation(formation);
    setShowUpdateFormationForm(true);
  };

  const onSelectProducteur = (producteur) => {
    setSelectedProducteur(producteur);
    setShowInfoProducteur(true);
  };

  useConstructor(async () => {
    if (store.exploitation.producteurs.length === 1) {
      onSelectProducteur(store.exploitation.producteurs[0]);
    }

    let tmpFormations = [];
    store.exploitation.producteurs.forEach(async (producteur) => {
      const resFormationsProducteur =
        await FormationController.getFormationsByIdproducteur(
          producteur.idproducteur,
        );
      tmpFormations = [...tmpFormations, ...resFormationsProducteur];
      store.formations.setFormations(tmpFormations);
    });

    store.referentiels.setTypesFormations(
      (await ReferentielController.getTypesFormations()).typesformations,
    );
  });

  const renderModal = () => {
    return (
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader className="modal-header-form">
          {modalContent.title}
        </ModalHeader>
        <ModalBody className="modal-body-form">
          {modalContent.message}
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      {renderModal()}
      <Row>
        <Col>
          <h2>
            Producteurs de {store.exploitation.informations.raisonsociale}{" "}
            <RenderIf isTrue={Object.keys(selectedProducteur).length > 0}>
              {" - " + selectedProducteur.nom + " " + selectedProducteur.prenom}
            </RenderIf>
          </h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <RenderIf
            isTrue={store.utilisateur.hasDroits("GESTION_PRODUCTEUR", 40)}
          >
            <Button
              color="primary"
              onClick={() => setShowNewProducteurForm(true)}
            >
              <i className="fa-solid fa-plus" />
            </Button>
          </RenderIf>
        </Col>
      </Row>
      <Collapse isOpen={showNewProducteurForm} mountOnEnter unmountOnExit>
        <RenderIf
          isTrue={store.utilisateur.hasDroits("GESTION_PRODUCTEUR", 40)}
        >
          <Row style={{ marginTop: 30 }}>
            <Col>
              <FormNewProducteur
                exploitation={store.exploitation.informations}
                onClickNewProducteur={onClickNewProducteur}
                hideFormNewProducteur={() => setShowNewProducteurForm(false)}
              />
            </Col>
          </Row>
        </RenderIf>
      </Collapse>
      <RenderIf isTrue={store.utilisateur.hasDroits("GESTION_PRODUCTEUR", 20)}>
        <Row style={{ marginTop: 30 }}>
          <Col xs="12">
            <TableProducteur
              producteurs={store.exploitation.producteurs}
              onSelectProducteur={onSelectProducteur}
            />
          </Col>
        </Row>
      </RenderIf>
      <Row style={{ marginTop: 30 }}>
        <Col md="5" style={{ marginBottom: 30 }}>
          <Collapse isOpen={showInfoProducteur} mountOnEnter unmountOnExit>
            <RenderIf
              isTrue={store.utilisateur.hasDroits("GESTION_PRODUCTEUR", 20)}
            >
              <Collapse
                isOpen={!showUpdateProducteurForm}
                mountOnEnter
                unmountOnExit
              >
                <FicheProducteur
                  producteur={selectedProducteur}
                  onClickUpdateProducteur={() =>
                    setShowUpdateProducteurForm(true)
                  }
                />
              </Collapse>
            </RenderIf>
            <RenderIf
              isTrue={store.utilisateur.hasDroits("GESTION_PRODUCTEUR", 30)}
            >
              <Collapse
                isOpen={showUpdateProducteurForm}
                mountOnEnter
                unmountOnExit
              >
                <FormUpdateProducteur
                  producteur={selectedProducteur}
                  hideUpdateProducteurForm={hideUpdateProducteurForm}
                  onClickUpdateProducteur={onClickUpdateProducteur}
                />
              </Collapse>
            </RenderIf>
          </Collapse>
        </Col>
        <RenderIf isTrue={store.utilisateur.hasDroits("GESTION_FORMATION", 20)}>
          <Col md="7" style={{ marginBottom: 30 }}>
            <Collapse
              isOpen={
                showInfoProducteur &&
                !showNewFormationForm &&
                !showUpdateFormationForm
              }
              mountOnEnter
            >
              <FormationProducteur
                showFormNewFormation={() => setShowNewFormationForm(true)}
                onSelectFormation={onSelectFormation}
              />
            </Collapse>
            <Collapse isOpen={showNewFormationForm} mountOnEnter unmountOnExit>
              <FormFormationProducteur
                producteurs={store.exploitation.producteurs}
                idproducteur={selectedProducteur.idproducteur}
                onClickSaveNewFormation={onClickSaveNewFormation}
                hideFormNewFormation={() => setShowNewFormationForm(false)}
                millesime={store.millesime.idmillesime}
              />
            </Collapse>
            <Collapse
              isOpen={showUpdateFormationForm}
              mountOnEnter
              unmountOnExit
            >
              <FormUpdateFormation
                producteurs={store.exploitation.producteurs}
                producteur={selectedProducteur}
                formation={selectedFormation}
                hideFormUpdateFormation={hideFormUpdateFormation}
                onClickUpdateFormation={onClickUpdateFormation}
                onClickDeleteFormation={onClickDeleteFormation}
              />
            </Collapse>
          </Col>
        </RenderIf>
      </Row>
    </>
  );
}
