import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { columns } from "../../components/Grids/Convention/convention";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf, createNotification, downloadFile } from "../../config/utils";
import React, { useContext } from "react";
import StoreContext from "../../context/StoreContext";
import AgGrid from "../../components/AgGrid";
import { IConvention } from "../../config/types/convention";
import { CellClickedEvent } from "ag-grid-community";
import DocumentController from "src/config/apiUtils/DocumentController";

interface IProps {
  conventions: IConvention[];
  loading: boolean;
  onClickUpdateConvention: (convention: IConvention) => void;
}

export default function ConventionsExploitations(
  props: IProps,
): React.JSX.Element {
  const store = useContext(StoreContext);

  const onCellClick = async (params: CellClickedEvent) => {
    if (params.colDef.field === "telecharger") {
      createNotification("info", "", "Préparation du document en cours...");
      const resDocument = await DocumentController.getConvention(
        params.data.idconvention,
      );
      downloadFile(
        resDocument,
        `convention_${params.data.raisonsociale.replaceAll(" ", "-")}_${
          store.millesime.idmillesime
        }.pdf`,
        "application/octet-stream",
      );
      createNotification("success", "", "Convention générée avec succès.");
    } else {
      props.onClickUpdateConvention(params.data);
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Conventions</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={props.loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={props.conventions.length > 0 && !props.loading}>
            <AgGrid
              columnDefs={columns}
              rowData={props.conventions}
              showFilterLine
              onCellClicked={onCellClick}
              height={600}
            />
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
