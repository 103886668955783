import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Etat",
    field: "validationapgmb",
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "Validé",
        },
        {
          value: false,
          renderer: "Non validé",
        },
        {
          value: null,
          renderer: "Non renseigné",
        },
      ]),
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
  },
  {
    headerName: "Numéro annexe",
    field: "numeroannexe",
  },
  {
    headerName: "Date de l'annexe",
    field: "dateannexe",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Changement d'adresse",
    field: "changementadresse",
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleWarning,
      ),
  },
  {
    headerName: "Changement de statut",
    field: "changementstatut",
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleWarning,
      ),
  },
  {
    headerName: "Perte de Label",
    field: "pertelabel",
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleWarning,
      ),
  },
  {
    headerName: "Perte de récolte",
    field: "perterecolte",
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleWarning,
      ),
  },
  {
    headerName: "Modification de surfaces",
    field: "modificationparcelles",
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleWarning,
      ),
  },
];
