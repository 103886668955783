import React, { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import AddButton from "../../components/Buttons/AddButton";
import { unflatten } from "../../config/utils";
import layoutRoutes from "../../config/layoutRoutes";
import { NavLink, useNavigate } from "react-router-dom";
import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
} from "../../config/gridUtils";
import AgGrid from "../../components/AgGrid";
import { RowClickedEvent } from "ag-grid-community";
import { IParcelle } from "../../config/types/parcelle";

const columns = [
  {
    headerName: "Parcelle",
    field: "nomparcelle",
  },
  {
    headerName: "Type de sol",
    field: "libelletypesol",
  },
  {
    headerName: "Surface",
    field: "surface",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Est cartographié",
    valueGetter: (params: any) => params.data.geometrie != null,
    cellStyle: (params: any) =>
      cellStyleSwitchDefaultBoolean(params.data.geometrie != null),
    cellRenderer: (params: any) =>
      cellRendererSwitch(
        params.data.geometrie != null,
        cellRendererDefaultBoolean,
      ),
  },
];

export default function ListeParcelles(): React.JSX.Element {
  const store = useContext(StoreContext);
  const [parcelles, setParcelles] = useState<IParcelle[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useConstructor(async () => {
    setLoading(true);
    setParcelles(
      await ExploitationController.getParcellesByIdexploitation(
        store.exploitation.informations.idexploitation,
        store.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  const onRowClick = (params: any) => {
    if (unflatten(params.data).geometrie) {
      navigate(
        `${layoutRoutes.userLayout}/carto/parcellaire-exploitation?parcelle=${params.data.idparcelle}`,
      );
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des parcelles</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <NavLink
              to={`${layoutRoutes.userLayout}/carto/parcellaire-exploitation?newparcelle=true`}
            >
              <AddButton />
            </NavLink>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {!loading && (
              <AgGrid
                columnDefs={columns}
                rowData={parcelles}
                onRowClicked={onRowClick}
                showFilterLine
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
