import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
  formatValueUnite,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Numéro du contrat",
    field: "numerocontrat",
    minWidth: 175,
    flex: 1,
  },
  {
    headerName: "Montant",
    field: "prixcotisation",
    minWidth: 130,
    flex: 1,
    valueFormatter: (params) =>
      formatValueUnite(params.data.prixcotisation * 1.2, "€ TTC"),
    valueGetter: (params) => (params.data.prixcotisation * 1.2).toFixed(2),
  },
  {
    headerName: "Payée",
    field: "paye",
    minWidth: 150,
    flex: 1,
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
  },
];
