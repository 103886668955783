import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    flex: 5,
  },
  {
    headerName: "Date d'envoi",
    field: "dateenvoi",
    flex: 4,
  },
  {
    headerName: "Date de retour",
    field: "dateretour",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
    flex: 4,
  },
  {
    headerName: "Convention signée",
    field: "signee",
    flex: 4,
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
  },
  {
    headerName: "",
    field: "telecharger",
    flex: 1,
    filter: null,
    cellStyle: () => cellStyleWarning,
    cellRenderer: () => "PDF",
  },
];
