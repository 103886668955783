import React, { useState } from "react";
import { Button, Col, Tooltip } from "reactstrap";
import { generateRandomString } from "../../../config/utils";

interface IProps {
  style?: object | undefined;
  color?: string | null | undefined;
  onClick: () => void;
  libelle: string;
}

export default function ButtonCartoAction(props: IProps): React.JSX.Element {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [id] = useState(generateRandomString(20, "ABCDEFGHIJKLMNOPQRSTUVWXYZ"));

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Col className="btn-carto-parcelle-action-container" style={props.style}>
      <Tooltip
        placement={"top"}
        isOpen={tooltipOpen}
        target={id}
        toggle={toggle}
      >
        {props.libelle}
      </Tooltip>
      <Button
        color={props.color ?? "warning"}
        className="btn-carto-parcelle-action"
        onClick={props.onClick}
        id={id}
      >
        {props.libelle}
      </Button>
    </Col>
  );
}
