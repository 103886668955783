export const columns = [
  {
    headerName: "Raison sociale",
    field: "raisonsociale",
  },
  {
    headerName: "Numéro SIRET",
    field: "siret",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Ville",
    field: "ville",
  },
  {
    headerName: "Désactiver",
    field: "desactiver",
    filter: null,
    cellRenderer: () => "Désactiver",
  },
];
