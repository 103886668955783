import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleSuccess,
  cellStyleSwitch,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterSurface,
  valueFormatterTonnage,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Validé",
    field: "libellevalidationadministrative",
    cellStyle: function (params) {
      return cellStyleSwitch(params.data.idetatvalidationadministrative, [
        {
          value: 1,
          cellStyle: cellStyleDanger,
        },
        {
          value: 2,
          cellStyle: cellStyleWarning,
        },
        {
          value: 3,
          cellStyle: cellStyleWarning,
        },
        {
          value: 4,
          cellStyle: cellStyleSuccess,
        },
        { value: null, cellStyle: null },
      ]);
    },
  },
  {
    headerName: "Date de saisie",
    field: "datesurfacepotentielle",
  },
  {
    headerName: "Exploitation",
    field: "exploitation",
  },
  {
    headerName: "Code DC",
    field: "codedc",
  },
  {
    headerName: "Année d'adhésion",
    field: "millesimeadhesion",
    hide: true,
  },
  {
    headerName: "Commune",
    field: "commune",
  },
  {
    headerName: "Surface printemps (ha)",
    field: "surfacepotentielleprintemps",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Surface automne (ha)",
    field: "surfacepotentielleautomne",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Surface totale (ha)",
    field: "surfacepotentielletotale",
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Tonnage estimé (t)",
    field: "tonnageestime",
    valueFormatter: valueFormatterTonnage,
  },
  {
    headerName: "Type de production",
    field: "libelletypeproduction",
  },
  {
    headerName: "Quantité de semence",
    field: "quantitesemence",
  },
  {
    headerName: "Variété",
    field: "libellevariete",
  },
  {
    headerName: "Rendement de référence moyen",
    field: "rendementmoyen",
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
  },
  {
    headerName: "Abandonné",
    field: "abandon",
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleDefault,
      ),
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, cellRendererDefaultBoolean);
    },
  },
  {
    headerName: "Commentaires",
    field: "commentaire",
  },
];
