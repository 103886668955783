import { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { columns } from "../../components/Grids/ListeProducteur/tableproducteur";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ProducteurEditionController from "../../config/apiUtils/ProducteurEditionController";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment";
import AgGrid from "../../components/AgGrid";
import { getColumnsByField } from "../../config/gridUtils";

export default function TableProducteurs(props) {
  const store = useContext(StoreContext);

  useConstructor(async () => {
    await props.loadProducteurs();
  });

  const onEditComplete = async (params) => {
    // On récupère le dataSource de la colonne (s'il y en a un)
    let dataSourceColumn = [];
    const column = getColumnsByField(columns, params.colDef.field);

    if (column?.cellEditorParams?.values) {
      if (typeof column.cellEditorParams.values?.then === "function") {
        await column.cellEditorParams.values.then(
          (res) => (dataSourceColumn = res),
        );
      } else {
        dataSourceColumn = column.cellEditorParams.values;
      }
    }

    const res = await ProducteurEditionController.updateField(
      params.data.idproducteur,
      column.field,
      params.newValue,
    );

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      // On récupère la ligne de manière temporaire pour une modification en live de la grid
      const tmp = [...props.producteurs];
      const producteurRow = tmp.find(
        (e) => e.idproducteur === params.data.idproducteur,
      );

      producteurRow[params.colDef.field] = params.newValue;

      if (params.colDef.field === "idcivilite") {
        producteurRow.civilite = dataSourceColumn.find(
          (e) => e.value === params.newValue,
        ).label;
      }

      // On reset le dataSource pour rechargement de la grid
      props.setProducteurs(tmp);
      store.setProducteursMillesime(tmp);
      store.exploitation.setProducteurs(
        tmp.filter(
          (p) =>
            p.idexploitation === store.exploitation.informations.idexploitation,
        ),
      );
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  const onCellDoubleClick = (params) => {
    navigator.clipboard.writeText(params.value);
    createNotification(
      "info",
      "Information",
      params.colDef.headerName + " copié dans le presse-papier",
      3000,
    );
  };

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    return tmp;
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Pool producteurs</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={props.producteurs.length > 0}>
              <CsvButton
                color="primary"
                onClick={() => {
                  downloadCsv(
                    convertToCSV(props.producteurs, ";", getColumns()),
                    "export_producteurs_" + moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!props.loading}>
          <AgGrid
            columnDefs={columns}
            rowData={props.producteurs}
            onCellEditingStopped={onEditComplete}
            onCellDoubleClicked={onCellDoubleClick}
            showFilterLine
            height={600}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
