import { NavLink } from "react-router-dom";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import layoutRoutes from "../../config/layoutRoutes";
import React from "react";

interface IProps {
  closeMentionsLegales: () => void;
}

export default function MentionsLegales(props: IProps): React.JSX.Element {
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <Col
                md={10}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p style={{ display: "contents" }}>
                  En poursuivant votre navigation sur ce site, vous acceptez
                  l'utilisation des cookies et données de session pour vous
                  proposer les services de gestion documentaire de l'APGMB.
                  <br />
                </p>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button color="warning">
                  <NavLink
                    style={{ textDecoration: "none", color: "#000000" }}
                    to={`${layoutRoutes.userLayout}/legal`}
                  >
                    Mentions légales
                  </NavLink>
                </Button>
                <Button
                  color="primary"
                  onClick={props.closeMentionsLegales}
                  style={{ marginLeft: 15 }}
                >
                  Accepter
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
