import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import ActionsGroupees from "../ActionsGroupees";
import React, { useContext, useState } from "react";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import ActionGroupeeController from "../../config/apiUtils/ActionGroupeeController";
import { columns } from "../../components/Grids/ActionsGroupees/attestationlivraisonsemences";
import {
  cellStyleDefault,
  getColumnsMapForCsv,
  valueFormatterUnite,
} from "../../config/gridUtils";
import {
  convertToCSV,
  downloadCsv,
  RenderIf,
  unflattenList,
} from "../../config/utils";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment/moment";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import AgGrid from "../../components/AgGrid";
import { IAttestationLivraisonSemences } from "src/config/types/actionsgroupees";

export default function AttestationLivraisonSemences(): React.JSX.Element {
  const store = useContext(StoreContext);
  const [data, setData] = useState<IAttestationLivraisonSemences[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getVarieteColumnName = (libellevariete, idrefvariete) => {
    return libellevariete + "." + idrefvariete;
  };

  useConstructor(async () => {
    setLoading(true);
    const res = await ActionGroupeeController.getAttestationLivraisonSemences(
      store.millesime.idmillesime,
    );
    const columnVarietesParent = columns.find(
      (c) => c.headerName === "Variétés",
    );

    res.forEach((element) => {
      //Pour chaque variété, on vérifie si la variété est présente dans les colonnes du tableau
      element.varietes.forEach((variete) => {
        element[
          getVarieteColumnName(variete.libellevariete, variete.idrefvariete)
        ] = variete.quantitesemencetotale;

        //Pas présente par défaut
        const varieteInColumns = columnVarietesParent.children.some(
          (column) =>
            column.field ===
            getVarieteColumnName(variete.libellevariete, variete.idrefvariete),
        );

        if (!varieteInColumns) {
          columnVarietesParent.children.push({
            headerName: variete.libellevariete ?? "PAS DE VARIETE",
            field: getVarieteColumnName(
              variete.libellevariete,
              variete.idrefvariete,
            ),
            cellStyle: cellStyleDefault,
            valueFormatter: (params) => valueFormatterUnite(params, " Kg"),
          });
        }
      });
    });

    setData([...res]);
    setLoading(false);
  });

  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>
                    Attestation de livraison des semences (contrats)
                  </CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={data.length > 0}>
                    <CsvButton
                      color="primary"
                      onClick={() => {
                        // On récupère une version non modifiée des data pour traitement tableau croisé dynamique
                        const temp = data;
                        temp.forEach((item) => {
                          item.varietes.forEach((variete) => {
                            item[variete.libellevariete] =
                              variete.quantitesemencetotale;
                          });
                          delete item.varietes;
                        });
                        downloadCsv(
                          convertToCSV(temp, ";", getColumnsMapForCsv(columns)),
                          "export_als_" + moment().format("YYYY-MM-DD"),
                        );
                      }}
                    />
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <AgGrid
                  columnDefs={columns}
                  rowData={unflattenList(data)}
                  showFilterLine
                />
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
