import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import StoreContext from "../../../context/StoreContext";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import { createNotification, RenderIf } from "../../../config/utils";
import ExploitationController from "../../../config/apiUtils/ExploitationController";
import OrganismeStockeurController from "../../../config/apiUtils/OrganismeStockeurController";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import moment from "moment/moment";
import ContratController from "../../../config/apiUtils/ContratController";
import useKeyPress from "../../../config/hooks/useKeyPress";
import Keys from "../../../config/Keys";
import { IExploitation } from "../../../config/types/exploitation";
import { IContratPOST } from "../../../config/types/contrat";
import { ITypeProduction } from "../../../config/types/referentiel";
import { ISurfacePotentielle } from "../../../config/types/surfacepotentielle";
import ModalContratConfirmationAppelCotisation from "./ModalContratConfirmationAppelCotisation";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

export default function ModalNewContrat(props: IProps): React.JSX.Element {
  const context = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [
    showModalContratConfirmationAppelCotisation,
    setShowModalContratConfirmationAppelCotisation,
  ] = useState(false);

  useEffect(() => {
    const hasIntentionValide =
      context.surfacesPotentielles.filter((i: ISurfacePotentielle) => i.valide)
        .length > 0;

    if (!hasIntentionValide) {
      createNotification(
        "error",
        "Erreur",
        "Votre exploitation ne comporte aucune intention de semis validée par un administrateur. Si vous n’avez pas d’intention renseignée, merci de saisir vos intentions de semis. Si votre intention n’a pas encore été validée, un administrateur s’occupera de votre dossier dans les plus brefs délais.",
        15000,
      );
      props.toggle();
    }

    if (context.utilisateur.informations.isProducteur) {
      createNotification(
        "info",
        "Information",
        "En remplissant ce formulaire, vous vous engagez à respectez les éléments du contrat accessibles dans la page : Exploitations / Producteurs > Mes documents",
        15000,
      );
    }
    setLoading(true);
    const loadData = async () => {
      context.referentiels.setTypesProduction(
        await ReferentielController.getTypesProduction(true),
      );
      context.setOrganismesStockeur(
        await OrganismeStockeurController.getOrganismesStockeur(),
      );
      if (!context.utilisateur.informations.isProducteur) {
        ExploitationController.getExploitations().then((res) => {
          context.setExploitations(res);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  useKeyPress(Keys.Escape, () => {
    props.toggle();
  });

  const onSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const totalSurface =
      event.target.surfaceprintemps.value + event.target.surfaceautomne.value;
    let hasError = false;

    if (totalSurface == null || totalSurface <= 0) {
      createNotification(
        "error",
        "Erreur - Formulaire incomplet",
        "Merci de renseigner les champs de surfaces printemps et automne.",
      );
      hasError = true;
    }

    if (event.target?.nbparcelles?.value <= 0) {
      createNotification(
        "error",
        "Erreur - Formulaire incomplet",
        "Merci d'indiquer un nombre de parcelles supérieur à 0.",
      );
      hasError = true;
    }

    if (event.target?.idorganismestockeur?.value == -1) {
      createNotification(
        "error",
        "Erreur - Formulaire incomplet",
        "Merci de sélectionner un Organisme Stockeur dans la liste déroulante.",
      );
      hasError = true;
    }

    if (!event.target.validationpriseconnaissance.checked) {
      createNotification(
        "error",
        "Erreur",
        "Merci de prendre connaissance des engagements vis-à-vis de la filière.",
      );
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    const contratForm: IContratPOST = {
      numerocontrat: event.target?.numerocontrat?.value ?? null,
      idorganismestockeur: event.target?.idorganismestockeur?.value ?? null,
      idreftypeproduction: event.target.idreftypeproduction.value,
      surfaceprintemps: event.target.surfaceprintemps.value,
      surfaceautomne: event.target.surfaceautomne.value,
      nbparcelles: event.target.nbparcelles.value,
      idexploitation: event.target.idexploitation.value,
      millesime: context.millesime.idmillesime,
      datecontrat: event.target.datecontrat.value,
      idetatvalidationadministrative:
        event.target.validationadministrative != null &&
        event.target.validationadministrative.checked
          ? context.utilisateur.profilsUtilisateur[0].estorganismestockeur
            ? 3
            : !context.utilisateur.informations.isProducteur
              ? 2
              : 1
          : 1,
    };
    const resContrat = await ContratController.insertContrat(contratForm);
    if (resContrat.status === 200) {
      context.contratsExploitation.push(resContrat.data);
      createNotification("success", "Succès", "Contrat créé avec succès");
      createNotification(
        "info",
        "Information",
        "Votre contrat a été enregistrée. Pour continuer, merci de le compléter en cliquant sur le bouton Compléter Contrat dans la section de droite.",
        10000,
      );

      setShowModalContratConfirmationAppelCotisation(true);
    } else {
      createNotification("error", "Erreur", resContrat.data.detail);
    }
    setLoading(false);
  };

  const renderExploitationOptions = () => {
    if (!context.utilisateur.informations.isProducteur) {
      return context.exploitations.map(
        (exploitation: IExploitation, key: number) => {
          return (
            <option key={key} value={exploitation.idexploitation}>
              {exploitation.raisonsociale}
            </option>
          );
        },
      );
    }
    return (
      <option value={context.exploitation.informations.idexploitation}>
        {context.exploitation.informations.raisonsociale}
      </option>
    );
  };

  const renderOrganismesStockeurOption = () => {
    const organismes = [...context.organismesStockeur];
    organismes.unshift({ idorganismestockeur: -1, nomorganismestockeur: "-" });
    return organismes.map((organisme, key) => {
      return (
        <option key={key} value={organisme.idorganismestockeur}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  const renderTypesProductionOption = () => {
    return context.referentiels.typesProduction.map(
      (typeProduction: ITypeProduction, key: number) => {
        return (
          <option key={key} value={typeProduction.idreftypeproduction}>
            {typeProduction.libelle}
          </option>
        );
      },
    );
  };

  return (
    <>
      <ModalContratConfirmationAppelCotisation
        isOpen={showModalContratConfirmationAppelCotisation}
        toggle={() => {
          setShowModalContratConfirmationAppelCotisation(false);
          props.toggle();
        }}
      />
      <Modal
        isOpen={props.isOpen}
        size={"lg"}
        centered
        scrollable={false}
        unmountOnClose
      >
        <ModalHeader className="modal-header-form">Nouveau contrat</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody className="modal-body-form">
            <RenderIf isTrue={loading}>
              <SpinLoadingAnimation />
            </RenderIf>
            <RenderIf isTrue={!loading}>
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Col>
                    <FormGroup>
                      <Label for="datecontrat">Date du contrat</Label>
                      <Input
                        type="date"
                        name="datecontrat"
                        defaultValue={moment().format("YYYY-MM-DD")}
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="idexploitation">Exploitation</Label>
                      <Input
                        type="select"
                        className="select-input"
                        name="idexploitation"
                        disabled
                        value={context.exploitation.informations.idexploitation}
                        required
                      >
                        {renderExploitationOptions()}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col md="4">
                    <FormGroup>
                      <Label for="idorganismestockeur">
                        Organisme stockeur
                      </Label>
                      <Input
                        type="select"
                        className="select-input"
                        name="idorganismestockeur"
                        defaultValue={
                          context.surfacesPotentielles.find(
                            (i: ISurfacePotentielle) => !i.abandon,
                          ).idorganismestockeur
                        }
                        required
                      >
                        {renderOrganismesStockeurOption()}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="idreftypeproduction">
                        Type de production
                      </Label>
                      <Input
                        type="select"
                        className="select-input"
                        name="idreftypeproduction"
                        defaultValue={
                          context.surfacesPotentielles.find(
                            (i: ISurfacePotentielle) => !i.abandon,
                          ).idreftypeproduction
                        }
                        required
                      >
                        {renderTypesProductionOption()}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="nbparcelles">Nombres de parcelles</Label>
                      <Input
                        type="number"
                        name="nbparcelles"
                        placeholder="3"
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col md="6">
                    <FormGroup>
                      <Label for="surfaceprintemps">
                        Surface engagée au printemps (ha)
                      </Label>
                      <Input
                        type="text"
                        name="surfaceprintemps"
                        placeholder="12.3"
                        pattern="^\d{1,4}(?:\.\d{1,2})?$"
                        defaultValue={
                          context.surfacesPotentielles.find(
                            (i: ISurfacePotentielle) => !i.abandon,
                          ).surfacepotentielleprintemps
                        }
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="surfaceautomne">
                        Surface engagée en automne (ha)
                      </Label>
                      <Input
                        type="text"
                        name="surfaceautomne"
                        placeholder="12.3"
                        pattern="^\d{1,4}(?:\.\d{1,2})?$"
                        defaultValue={
                          context.surfacesPotentielles.find(
                            (i: ISurfacePotentielle) => !i.abandon,
                          ).surfacepotentielleautomne
                        }
                        required
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <RenderIf
                  isTrue={!context.utilisateur.informations.isProducteur}
                >
                  <Row style={{ marginBottom: 10 }}>
                    <Col md={12}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            id="validationadministrative"
                            name="validationadministrative"
                          />{" "}
                          Validé administrativement
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </RenderIf>
                <Row style={{ marginBottom: 10 }}>
                  <Col>
                    Mes engagements :{" "}
                    <a
                      style={{
                        textDecoration: "none",
                        color: "#f9aa33",
                        cursor: "pointer",
                      }}
                      target="_blank"
                      href="/app/exploitation-document"
                      title="Page des documents"
                      id="mesdocuments"
                    >
                      Cliquez ici
                    </a>
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="validationpriseconnaissance"
                      />{" "}
                      J'ai pris connaissance de mes engagements vis-à-vis de la
                      filière, et m'engager à les respecter
                    </Label>
                  </Col>
                </Row>
              </>
            </RenderIf>
          </ModalBody>
          <ModalFooter
            className="modal-footer-form"
            style={{ justifyContent: "left" }}
          >
            <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
            <CancelButton
              style={{ marginTop: 15 }}
              onClick={(event: any) => {
                event.preventDefault();
                props.toggle();
              }}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
