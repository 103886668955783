import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useKeyPress from "../../../config/hooks/useKeyPress";
import Keys from "../../../config/Keys";
import GeoJSON from "ol/format/GeoJSON";
import MapWrapper from "../../../components/carto/MapWrapper";
import { useContext, useRef } from "react";
import { getTileLayerXYZ } from "../../../config/carto/utils";
import StoreContext from "../../../context/StoreContext";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function ModalPreviewParcelle({ isOpen, toggle, parcelle }) {
  const context = useContext(StoreContext);
  const mapRef = useRef();

  useKeyPress(Keys.Escape, () => {
    toggle();
  });

  const feature = new GeoJSON().readFeature(parcelle.geometrie);

  const layerParcellesExploitation = new VectorLayer({
    source: new VectorSource({
      features: [feature],
    }),
    style: {
      "stroke-width": 0.75,
      "stroke-color": "white",
      "fill-color": "rgba(0, 96, 191, 0.7)",
    },
  });

  context.carto.zoomMap([feature], { padding: [10, 10, 10, 10] }, mapRef);

  return (
    <Modal
      isOpen={isOpen}
      size={"xl"}
      centered
      scrollable={false}
      unmountOnClose
    >
      <ModalHeader className={"modal-header-form"}>
        {`Prévisualisation - N° ${parcelle.numeroparcelle ?? ""} - ${
          parcelle.nomparcelle
        } - ${parcelle.codepostal} - ${parcelle.nomcommune} - ${
          parcelle.surfacesaisie
            ? parcelle.surfacesaisie + " Ha"
            : parcelle.surface
              ? parcelle.surface + " Ha"
              : ""
        }`}
      </ModalHeader>
      <ModalBody
        className={"modal-body-form"}
        style={{ height: 500, padding: 0 }}
      >
        <MapWrapper
          mapRef={mapRef}
          pageCarto
          showZoomControl
          showScaleline
          onMapClick={() => {}}
          layers={[
            getTileLayerXYZ(context.carto.urlCarto.pac.url),
            layerParcellesExploitation,
          ]}
        />
      </ModalBody>
      <ModalFooter
        className="modal-footer-form"
        style={{ justifyContent: "left" }}
      >
        <CancelButton
          style={{ marginTop: 15 }}
          onClick={() => {
            toggle();
          }}
        />
      </ModalFooter>
    </Modal>
  );
}
