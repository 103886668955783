import {
  valueFormatterSurface,
  valueFormatterUnite,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Numéro de contrat",
    field: "numerocontrat",
  },
  {
    headerName: "Date du contrat",
    field: "datecontrat",
  },
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Code DC",
    field: "codedc",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Mail",
    field: "mail",
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
  },
  {
    headerName: "Montant de la cotisation",
    field: "prixcotisation",
    valueFormatter: (params) => valueFormatterUnite(params, " € HT"),
  },
  {
    headerName: "Surface engagée",
    field: "surface",
    valueFormatter: valueFormatterSurface,
  },
];
