import { useEffect, useState } from "react";
import LoginCard from "../components/Login/LoginCard";
import CreationAccountCard from "../components/Login/CreationAccountCard";
import PasswordForgottenCard from "../components/Login/PasswordForgottenCard";
import ResetPasswordCard from "../components/Login/ResetPasswordCard";
import { Alert, Col, Collapse, Row } from "reactstrap";
import {
  createNotification,
  deleteQueryParam,
  getQueryParam,
} from "../config/utils";
import AuthService from "../config/AuthService";
import useConstructor from "../config/hooks/useConstructor";

export default function Login() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showResetPasswordCard, setShowResetPasswordCard] = useState(false);
  const [showCreateAccountCard, setShowCreateAccountCard] = useState(false);
  const [showPasswordForgottenCard, setShowPasswordForgottenCard] =
    useState(false);
  const [showFormulaireCreatedAlert, setShowFormulaireCreatedAlert] =
    useState(false);
  const [
    showFormulaireReinitPasswordAlert,
    setShowFormulaireReinitPasswordAlert,
  ] = useState(false);
  const [showFormulaireCreatedError, setShowFormulaireCreatedError] =
    useState(false);
  const [errorCreationFormulaire, setErrorCreationFormulaire] = useState();

  const onClickCreateAccount = async (formulaire) => {
    const res = await AuthService.createFormulaireUtilisateur(formulaire);
    if (res.status === 200) {
      if (res.data.success) {
        setShowFormulaireCreatedAlert(true);
      }
    } else if (res.status === 500) {
      setShowFormulaireCreatedError(true);
      setErrorCreationFormulaire(res.data.detail);
    }
  };

  const onClickReinitPassword = async (res) => {
    setShowFormulaireReinitPasswordAlert(res.success);
  };

  useConstructor(() => {
    if (getQueryParam("code") && getQueryParam("action") === "reinitpassword") {
      setShowResetPasswordCard(true);
    }
  });

  useEffect(() => {
    if (getQueryParam("loggedout") === "true") {
      createNotification("success", "Succès", "Déconnecté avec succès !");
      deleteQueryParam("loggedout");
    }
  }, []);

  const renderSuccessAlertFormulaireUtilisateur = () => {
    return (
      <Collapse isOpen={showFormulaireCreatedAlert} mountOnEnter unmountOnExit>
        <Col
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <Alert
            color="success"
            style={{ textAlign: "center", borderRadius: "2em" }}
          >
            Votre demande de création de compte a bien été envoyée ! <br />
            Vous serez prévenu dès que votre demande sera validée par un
            administrateur.
            <br />
            <a
              href="#"
              className="alert-link"
              onClick={() => {
                setShowFormulaireCreatedAlert(false);
              }}
            >
              Retour à la page de connexion
            </a>
          </Alert>
        </Col>
      </Collapse>
    );
  };

  const renderSuccessAlertFormulaireReinitPassword = () => {
    return (
      <Collapse
        isOpen={showFormulaireReinitPasswordAlert}
        mountOnEnter
        unmountOnExit
      >
        <Col
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <Alert
            color="success"
            style={{ textAlign: "center", borderRadius: "2em" }}
          >
            Un mail de réinitialisation a été envoyé sur votre adresse ! <br />
            Merci de cliquer sur le lien présent dans le mail afin de continuer
            la procédure de réinitialisation du mot de passe.
            <br />
            <br />
            Attention : le lien présent dans le mail n'est valable que 15
            minutes.
            <br />
            <a
              href="#"
              className="alert-link"
              onClick={() => {
                setShowFormulaireReinitPasswordAlert(false);
              }}
            >
              Retour à la page de connexion
            </a>
          </Alert>
        </Col>
      </Collapse>
    );
  };

  const renderErrorAlertFormulaireUtilisateur = () => {
    return (
      <Collapse isOpen={showFormulaireCreatedError} mountOnEnter unmountOnExit>
        <Col
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <Alert
            color="danger"
            style={{ textAlign: "center", borderRadius: "2em" }}
          >
            Une erreur est survenue :
            <br />
            {errorCreationFormulaire} <br />
            <a
              href="#"
              className="alert-link"
              onClick={() => {
                setShowFormulaireCreatedError(false);
              }}
            >
              Retour à la page de connexion
            </a>
          </Alert>
        </Col>
      </Collapse>
    );
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 30,
      }}
    >
      {renderSuccessAlertFormulaireReinitPassword()}
      {renderSuccessAlertFormulaireUtilisateur()}
      {renderErrorAlertFormulaireUtilisateur()}
      <Collapse
        isOpen={
          showCreateAccountCard &&
          !showFormulaireCreatedAlert &&
          !showFormulaireReinitPasswordAlert &&
          !showPasswordForgottenCard
        }
        mountOnEnter
        unmountOnExit
      >
        <Col
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <CreationAccountCard
            onClickValidate={onClickCreateAccount}
            onClickClose={() => setShowCreateAccountCard(false)}
          />
        </Col>
      </Collapse>
      <Collapse
        isOpen={
          showResetPasswordCard &&
          !showCreateAccountCard &&
          !showFormulaireCreatedAlert &&
          !showFormulaireReinitPasswordAlert &&
          !showPasswordForgottenCard
        }
        mountOnEnter
        unmountOnExit
      >
        <Col
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <ResetPasswordCard
            onClickClose={() => {
              setShowResetPasswordCard(false);
              deleteQueryParam("code");
              deleteQueryParam("action");
            }}
          />
        </Col>
      </Collapse>
      <Collapse
        isOpen={
          showPasswordForgottenCard &&
          !showCreateAccountCard &&
          !showFormulaireCreatedAlert &&
          !showFormulaireReinitPasswordAlert
        }
        mountOnEnter
        unmountOnExit
      >
        <Col
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <PasswordForgottenCard
            onClickValidate={onClickReinitPassword}
            onClickClose={() => {
              setShowPasswordForgottenCard(false);
            }}
          />
        </Col>
      </Collapse>
      <Collapse
        isOpen={
          !showCreateAccountCard &&
          !showFormulaireCreatedAlert &&
          !showFormulaireCreatedError &&
          !showPasswordForgottenCard &&
          !showFormulaireReinitPasswordAlert &&
          !showResetPasswordCard
        }
        mountOnEnter
        unmountOnExit
      >
        <Col
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <LoginCard
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            onClickCreateAccount={() => setShowCreateAccountCard(true)}
            onClickPasswordForgotten={() => setShowPasswordForgottenCard(true)}
          />
        </Col>
      </Collapse>
    </Row>
  );
}
