import { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import AddButton from "../../components/Buttons/AddButton";
import { columns } from "../../components/Grids/Exploitation/surfacespotentielle";
import StoreContext from "../../context/StoreContext";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../components/AgGrid";

export default function TableSurfacesPotentielles() {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Intentions de semis</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton
              onClick={() => {
                navigate(
                  "/app/carto/parcellaire-exploitation?section=intentionsemis&newintention=true",
                );
              }}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <AgGrid
          columnDefs={columns}
          rowData={store.surfacesPotentielles}
          height={350}
        />
      </CardBody>
    </Card>
  );
}
