import React, { useContext } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import droits from "../../config/CodesDroits";
import { RenderIf } from "../../config/utils";
import { useNavigate } from "react-router-dom";
import { IContrat } from "src/config/types/contrat";
import { IConvention } from "src/config/types/convention";
import { ICertificationIgp } from "src/config/types/certificationigp";

export default function FicheExploitation(props: any): React.JSX.Element {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>
                Fiche Exploitation -{" "}
                {store.exploitation.informations.raisonsociale}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={store.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_EXPLOITATION,
                  droits.modalite.modification,
                )}
              >
                <Button
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={props.onClickUpdate}
                >
                  <i className="fa fa-pencil" aria-hidden="true" />
                </Button>
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="7">
              <h5>Informations administratives</h5>
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>
                  <tr>
                    <th scope="row">Statut juridique</th>
                    <td>
                      {store.exploitation.informations.libellestatutjuridique}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Raison sociale</th>
                    <td>{store.exploitation.informations.raisonsociale}</td>
                  </tr>
                  <tr>
                    <th scope="row">Adresse</th>
                    <td>
                      {store.exploitation.informations.adresse}
                      <br />
                      {store.exploitation.informations.codepostal}{" "}
                      {store.exploitation.informations.ville}
                    </td>
                  </tr>
                  <RenderIf
                    isTrue={store.exploitation.informations.envoicourrier}
                  >
                    <tr>
                      <th scope="row">Adresse d'envoi de courrier</th>
                      <td>
                        {store.exploitation.informations.adresseenvoicourrier}
                        <br />
                        {
                          store.exploitation.informations
                            .codepostalenvoicourrier
                        }{" "}
                        {
                          store.exploitation.informations
                            .nomcommuneenvoicourrier
                        }
                      </td>
                    </tr>
                  </RenderIf>
                  <tr>
                    <th scope="row">Numéro SIRET</th>
                    <td>{store.exploitation.informations.siret}</td>
                  </tr>
                  <RenderIf
                    isTrue={
                      !store.utilisateur.informations.isProducteur &&
                      store.utilisateur.informations.isProducteur !== undefined
                    }
                  >
                    <>
                      <tr>
                        <th scope="row">Code Dijon Céréales</th>
                        <td>{store.exploitation.informations.codedc ?? "-"}</td>
                      </tr>
                      <tr>
                        <th scope="row">Code Octagri</th>
                        <td>
                          {store.exploitation.informations.codeoctagri &&
                          store.exploitation.informations.codeoctagri !== ""
                            ? store.exploitation.informations.codeoctagri
                            : "-"}
                        </td>
                      </tr>
                    </>
                  </RenderIf>
                  <tr>
                    <th scope="row">Numéro UNILEVER</th>
                    <td>
                      {store.exploitation.informations.numerounilever &&
                      store.exploitation.informations.numerounilever !== ""
                        ? store.exploitation.informations.numerounilever
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Année d'adhésion (année de récolte)</th>
                    <td>
                      {store.exploitation.informations.millesimeadhesion ?? "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Abandon de la culture de moutarde</th>
                    <td>
                      <b>
                        {store.exploitation.informations.abandon ? (
                          <Alert
                            color="danger"
                            style={styles.alertStyle}
                            fade={false}
                          >
                            OUI
                          </Alert>
                        ) : (
                          <Alert
                            color="success"
                            style={styles.alertStyle}
                            fade={false}
                          >
                            NON
                          </Alert>
                        )}
                      </b>
                    </td>
                  </tr>
                  <RenderIf
                    isTrue={
                      !store.utilisateur.informations.isProducteur &&
                      store.utilisateur.informations.isProducteur !== undefined
                    }
                  >
                    <>
                      <tr>
                        <th scope="row">Liste rouge</th>
                        <td>
                          <b>
                            {store.exploitation.informations.listerouge ? (
                              <Alert
                                color="danger"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                OUI
                              </Alert>
                            ) : (
                              <Alert
                                color="success"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                NON
                              </Alert>
                            )}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Actif en {store.millesime.idmillesime}
                        </th>
                        <td>
                          <b>
                            {store.exploitation.informations.actif ? (
                              <Alert
                                color="success"
                                style={{
                                  ...styles.alertStyle,
                                  marginBottom: 0,
                                }}
                                fade={false}
                              >
                                OUI
                              </Alert>
                            ) : (
                              <Alert
                                color="danger"
                                style={{
                                  ...styles.alertStyle,
                                  marginBottom: 0,
                                }}
                                fade={false}
                              >
                                NON
                              </Alert>
                            )}
                          </b>
                          {store.exploitation.informations.commentaireactif}
                        </td>
                      </tr>
                    </>
                  </RenderIf>
                </tbody>
              </Table>
            </Col>
            <Col md="5">
              <Row style={{ marginTop: 15 }}>
                <Col md="5">
                  <h5>Zones de production</h5>
                  <b>
                    {store.exploitation.informations?.libellezoneproduction}
                  </b>
                </Col>
                <Col md="7">
                  <h5>Rendement de référence moyen</h5>
                  <RenderIf
                    isTrue={
                      store.exploitation.informations.rendementrefmoyen !== 0
                    }
                  >
                    <b>
                      {store.exploitation.informations.rendementrefmoyen} t/ha
                    </b>
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      store.exploitation.informations.rendementrefmoyen === 0
                    }
                  >
                    <Alert color="danger" fade={false}>
                      Aucun rendement de référence renseigné...
                    </Alert>
                  </RenderIf>
                </Col>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <Col>
                  <Table style={{ color: "#FFFFFF" }}>
                    <tbody>
                      <tr>
                        <th scope="row">
                          Rendement sur {store.millesime.idmillesime}
                        </th>
                        <td>
                          {store.exploitation.informations.rendementref.find(
                            (r: any) =>
                              r.millesime === store.millesime.idmillesime,
                          )
                            ? store.exploitation.informations.rendementref.find(
                                (r: any) =>
                                  r.millesime === store.millesime.idmillesime,
                              ).valeur + " t/Ha"
                            : "Non renseigné"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Cotisation payée</th>
                        <td>
                          <b>
                            {store?.contratsExploitation
                              .find(
                                (contrat: IContrat) =>
                                  !contrat.abandon &&
                                  contrat.millesime ===
                                    store.millesime.idmillesime,
                              )
                              ?.cotisation.at(0).paye ? (
                              <Alert
                                color="success"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                OUI
                              </Alert>
                            ) : (
                              <Alert
                                color="danger"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                NON
                              </Alert>
                            )}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Numéro de contrat</th>
                        <td>
                          {store.contratsExploitation.find(
                            (contrat: IContrat) =>
                              !contrat.abandon &&
                              contrat.millesime === store.millesime.idmillesime,
                          ) ? (
                            <a
                              href="#"
                              style={{ textDecoration: "none", color: "white" }}
                              onClick={() =>
                                navigate(
                                  `/app/contrat?id=${
                                    store.contratsExploitation.find(
                                      (contrat: IContrat) => !contrat.abandon,
                                    ).idcontrat
                                  }`,
                                )
                              }
                            >
                              {
                                store.contratsExploitation.find(
                                  (contrat: IContrat) => !contrat.abandon,
                                ).numerocontrat
                              }
                            </a>
                          ) : (
                            "Pas de contrat en cours"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Convention signée</th>
                        <td>
                          <b>
                            {store.conventions.find(
                              (convention: IConvention) =>
                                convention.millesime ===
                                  store.millesime.idmillesime &&
                                convention.signee,
                            ) ? (
                              <Alert
                                color="success"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                OUI
                              </Alert>
                            ) : (
                              <Alert
                                color="danger"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                NON
                              </Alert>
                            )}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <h5>IGP</h5>
                  <Table style={{ color: "#FFFFFF" }}>
                    <tbody>
                      <tr>
                        <th scope="row">Habilité</th>
                        <td>
                          <b>
                            {store.exploitation.informations.certificationigp.find(
                              (certification: ICertificationIgp) =>
                                certification.habilite,
                            ) ? (
                              <Alert
                                color="success"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                OUI
                              </Alert>
                            ) : (
                              <Alert
                                color="danger"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                NON
                              </Alert>
                            )}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Actif</th>
                        <td>
                          <b>
                            {store.exploitation.informations.certificationigp.find(
                              (certification: ICertificationIgp) =>
                                certification.millesime ===
                                  store.millesime.idmillesime &&
                                certification.actif,
                            ) ? (
                              <Alert
                                color="success"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                OUI
                              </Alert>
                            ) : (
                              <Alert
                                color="danger"
                                style={styles.alertStyle}
                                fade={false}
                              >
                                NON
                              </Alert>
                            )}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <RenderIf
                    isTrue={[
                      "ADMINISTRATEUR",
                      "RESPO_ADMIN",
                      "CONSEILLER",
                    ].includes(
                      store.utilisateur?.profilsUtilisateur[0]?.codeprofil,
                    )}
                  >
                    <>
                      <h5>Commentaire</h5>
                      <Table style={{ color: "#FFFFFF" }}>
                        <tbody>
                          <tr>
                            <th scope="row">Commentaire APGMB</th>
                            <td>
                              {store.exploitation.informations.commentaireapgmb}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  </RenderIf>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

const styles = {
  alertStyle: {
    height: 12,
    width: "25%",
    padding: "0rem 1rem 1.5rem",
    display: "flex",
    justifyContent: "center",
  },
};
