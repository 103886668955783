import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleDefault,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Adresse",
    field: "adresse",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Contrat renseigné",
    field: "hascontrat",
    cellStyle: (params) =>
      cellStyleSwitchDefaultBoolean(
        params.value,
        cellStyleDanger,
        cellStyleSuccess,
        cellStyleDefault,
      ),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
];
