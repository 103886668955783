import AuthService from "../../../config/AuthService";
import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
  valueFormatterDate,
  valueFormatterSurface,
  valueFormatterUnite,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Numéro",
    field: "numeroannexe",
    minWidth: 180,
    cellRenderer: (params) =>
      params.value == null ? "Contrat initial" : "Annexe n° " + params.value,
  },
  {
    headerName: "Surface",
    field: "surface",
    minWidth: 150,
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Montant",
    field: "prixcotisation",
    minWidth: 150,
    valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
  },
  {
    headerName: "Montant payé",
    field: "montantpayettc",
    minWidth: 150,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_COTISATION", 30),
    valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
  },
  {
    headerName: "Reste à payer",
    field: "resteapayer",
    minWidth: 150,
    valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
  },
  {
    headerName: "Date de paiement",
    field: "datepaye",
    minWidth: 200,
    valueFormatter: valueFormatterDate,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_COTISATION", 30),
  },
  {
    headerName: "Payée",
    field: "paye",
    minWidth: 170,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_COTISATION", 30),
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          value: true,
          label: "OUI",
        },
        {
          value: false,
          label: "NON",
        },
      ],
    },
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
  {
    headerName: "Pénalité payée",
    field: "penalitepayee",
    minWidth: 170,
    hide: !(
      AuthService.isLoggedIn() && AuthService.hasDroit("GESTION_COTISATION", 30)
    ),
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_COTISATION", 30),
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          value: true,
          label: "OUI",
        },
        {
          value: false,
          label: "NON",
        },
      ],
    },
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, cellRendererDefaultBoolean),
  },
];
