export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Nom",
    field: "nom",
  },
  {
    headerName: "Prénom",
    field: "prenom",
  },
  {
    headerName: "Adresse",
    field: "adresse",
  },
  {
    headerName: "Code postal",
    field: "codepostal",
  },
  {
    headerName: "Commune",
    field: "nomcommune",
  },
  {
    headerName: "Numéro Unilever",
    field: "numerounilever",
  },
  {
    headerName: "Code DC",
    field: "codedc",
  },
  {
    headerName: "SIRET",
    field: "siret",
  },
  {
    headerName: "Mail",
    field: "mail",
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
  },
  {
    headerName: "Année d'adhésion (année récolte)",
    field: "millesimeadhesion",
  },
  {
    headerName: "Type de production",
    field: "libelletypeproduction",
  },
];
