import APIController from "../APIController";
import {
  IVisite,
  IVisitePOST,
  IVisitePUT,
  IVisitesParcelle,
} from "../types/visiteparcelles";
import { AxiosResponse } from "axios";

class VisiteParcellesController extends APIController {
  constructor() {
    super("/visiteparcelles");
  }

  async getVisiteParcelles(millesime: number): Promise<IVisitesParcelle[]> {
    return await this.getRequest("/", {
      params: {
        millesime: millesime,
      },
    });
  }

  async insertVisiteParcelle(
    visite: IVisitePOST,
  ): Promise<AxiosResponse<IVisite[]>> {
    return await this.postRequest("/", visite);
  }

  async updateVisiteParcelle(
    idvisite: number,
    visite: IVisitePUT,
  ): Promise<AxiosResponse<IVisite[]>> {
    return await this.putRequest(`/${idvisite}`, visite);
  }
}

export default new VisiteParcellesController();
