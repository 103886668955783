import React, { useState } from "react";
import {
  IVisite,
  IVisitePUT,
  IVisitesParcelle,
} from "../../config/types/visiteparcelles";
import {
  Button,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import CancelButton from "../../components/Buttons/CancelButton";
import MapPreviewParcelles from "./MapPreviewParcelles";
import { createNotification, RenderIf } from "../../config/utils";
import AgGrid from "../../components/AgGrid";
import { columns } from "./grid/ListeVisitesParcelle";
import FormNewVisite from "./ModalFicheVisite/FormNewVisite";
import useKeyPress from "../../config/hooks/useKeyPress";
import Keys from "../../config/Keys";
import { CellEditingStoppedEvent } from "ag-grid-community";
import VisiteParcellesController from "../../config/apiUtils/visiteParcellesController";

interface IProps {
  visiteParcelle: IVisitesParcelle;
  isOpen: boolean;
  onClose: () => void;
}

export default function ModalFicheVisite(props: IProps): React.JSX.Element {
  const [showFormNewVisite, setShowFormNewVisite] = useState<boolean>(false);

  useKeyPress(Keys.Escape, () => {
    props.onClose();
  });

  const onEditComplete = async (params: CellEditingStoppedEvent) => {
    const form: IVisitePUT = {
      actioncorrectivemiseplace: params.data.actioncorrectivemiseplace,
      actioncorrectiveproposee: params.data.actioncorrectiveproposee,
      autresanomalies: params.data.autresanomalies,
      commentaire: params.data.commentaire,
      conforme: params.data.conforme,
      conformeapresaction: params.data.conformeapresaction,
      datevisite: params.data.datevisite,
      datevisitesupplementaire: params.data.datevisitesupplementaire,
      nbcolza: params.data.nbcolza != "" ? params.data.nbcolza : null,
      nbsanvesravenelles:
        params.data.nbsanvesravenelles != null
          ? params.data.nbsanvesravenelles
          : null,
      presencecolza: params.data.presencecolza,
      presencesanvesravenelles: params.data.presencesanvesravenelles,
    };

    const res = await VisiteParcellesController.updateVisiteParcelle(
      params.data.idvisite,
      form,
    );

    if (res.status === 200) {
      createNotification("success", "Succès", "Visite modifiée avec succès");
      const visiteTmp = props.visiteParcelle;

      // @ts-ignore
      visiteTmp.visites = res.data;
    }
  };

  const getLastVisite = (visites: IVisite[]): IVisite | null => {
    if (visites.length === 0) return null;

    // Créer un mapping idvisite → visite pour un accès rapide
    const visiteMap = new Map(visites.map((v) => [v.idvisite, v]));

    // Trouver la visite qui n'est référencée par aucune autre (la plus récente)
    return (
      visites.find(
        (visite) =>
          !Array.from(visiteMap.values()).some(
            (v) => v.idvisiteprecedente === visite.idvisite,
          ),
      ) || null
    );
  };

  return (
    <Modal
      size={"xl"}
      centered
      scrollable={false}
      isOpen={props.isOpen}
      autoFocus={false}
      fullscreen={"xl"}
      style={{
        minWidth: "90%",
      }}
    >
      <ModalHeader className="modal-header-form">
        Suivi de la parcelle : {props.visiteParcelle?.parcelle.nomparcelle}
      </ModalHeader>
      <ModalBody className="modal-body-form">
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <RenderIf isTrue={props.visiteParcelle?.parcelle.geometrie != null}>
            <Col
              md={6}
              style={{
                paddingTop: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MapPreviewParcelles
                geometries={
                  props.visiteParcelle?.parcelle.geometrie
                    ? props.visiteParcelle?.parcelle.geometrie
                    : null
                }
                width={"100%"}
                height={300}
              />
            </Col>
          </RenderIf>
          <Col
            md={props.visiteParcelle?.parcelle.geometrie != null ? 6 : 12}
            style={{ paddingTop: 10, display: "flex", flexDirection: "column" }}
          >
            <Table
              style={{
                color: "#FFFFFF",
              }}
              striped
            >
              <tbody>
                <tr>
                  <th scope="row">Exploitation</th>
                  <td>
                    {props.visiteParcelle?.exploitation?.raisonsociale ?? "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Nom</th>
                  <td>{props.visiteParcelle?.parcelle.nomparcelle}</td>
                </tr>
                <tr>
                  <th scope="row">Localisation</th>
                  <td>
                    {props.visiteParcelle?.parcelle.codepostal}{" "}
                    {props.visiteParcelle?.parcelle.nomcommune}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Type de sol</th>
                  <td>{props.visiteParcelle?.parcelle.libelletypesol}</td>
                </tr>
                <tr>
                  <th scope="row">Culture</th>
                  <td>{props.visiteParcelle?.parcelle.libelleculture}</td>
                </tr>
                <tr>
                  <th scope="row">Surface</th>
                  <td>
                    {props.visiteParcelle?.parcelle.surfacesaisie ??
                      props.visiteParcelle?.parcelle.surface}{" "}
                    Ha
                  </td>
                </tr>
              </tbody>
            </Table>
            <Button
              color={"primary"}
              onClick={() => setShowFormNewVisite(true)}
            >
              Renseigner une visite
            </Button>
          </Col>
        </Row>
        <Collapse isOpen={showFormNewVisite} mountOnEnter unmountOnExit>
          <hr />
          <Row style={{ marginTop: 10 }}>
            <FormNewVisite
              close={(newVisite: IVisite[] | null | undefined) => {
                if (
                  newVisite != null &&
                  props.visiteParcelle?.visites != null
                ) {
                  const visiteTmp = props.visiteParcelle;

                  // @ts-ignore
                  visiteTmp.visites = newVisite;
                }
                setShowFormNewVisite(false);
              }}
              parcelle={props.visiteParcelle?.parcelle}
              numeroVisite={props.visiteParcelle?.visites.length + 1}
              premiereVisite={
                props.visiteParcelle?.visites == null ||
                props.visiteParcelle?.visites.length === 0
              }
              visitePrecedente={getLastVisite(props.visiteParcelle?.visites)}
            />
          </Row>
          <hr />
        </Collapse>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <h3>Historique des visites</h3>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <AgGrid
              // @ts-ignore
              rowData={props.visiteParcelle?.visites.map((visite) => ({
                ...visite,
                exploitation: props.visiteParcelle?.exploitation,
              }))}
              columnDefs={columns}
              showFilterLine
              height={400}
              onCellEditingStopped={onEditComplete}
              emptyText={`Aucune visite effectuée sur la parcelle <b>${props.visiteParcelle?.parcelle.nomparcelle}</b>`}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="modal-footer-form">
        <Row style={{ width: "100%" }}>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 10,
            }}
          >
            <CancelButton onClick={props.onClose} />
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
