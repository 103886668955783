import { Col, Row } from "reactstrap";
import TableCertificationsExploitation from "./certificationexploitation/TableCertificationsExploitation";
import React from "react";

export default function CertificationExploitation(): React.JSX.Element {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Certifications exploitation</h2>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30, minHeight: 500 }}>
        <Col md="12">
          <TableCertificationsExploitation />
        </Col>
      </Row>
    </>
  );
}
