import React, { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import CertificationExploitationController from "../../config/apiUtils/CertificationExploitationController";
import { columns } from "../../components/Grids/CertificationExploitation/certificationexploitation";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import AgGrid from "../../components/AgGrid";

export default function TableCertificationsExploitation(): React.JSX.Element {
  const context = useContext(StoreContext);
  const [loading, setLoading] = useState<boolean>(false);

  useConstructor(async () => {
    setLoading(true);
    context.certifications.setCertificationsExploitation(
      await CertificationExploitationController.getCertificationsExploitation(
        context.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Certifications exploitation</CardTitle>
      </CardHeader>
      <CardBody>
        {loading && <SpinLoadingAnimation />}
        {!loading && (
          <AgGrid
            columnDefs={columns}
            rowData={context.certifications.certificationsExploitation}
            height={400}
            showFilterLine
          />
        )}
      </CardBody>
    </Card>
  );
}
