import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleSwitchDefaultBoolean,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
  },
  {
    headerName: "Code DC",
    field: "codedc",
  },
  {
    headerName: "Formation",
    field: "libelleformation",
  },
  {
    headerName: "Nom",
    field: "nom",
  },
  {
    headerName: "Prénom",
    field: "prenom",
  },
  {
    headerName: "Mail",
    field: "mail",
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
  },
  {
    headerName: "Date de la formation",
    field: "dateformation",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Millésime",
    field: "millesime",
  },
  {
    headerName: "Formation suivie",
    field: "suivi",
    cellStyle: (params) => cellStyleSwitchDefaultBoolean(params.value),
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, cellRendererDefaultBoolean);
    },
  },
  {
    headerName: "Commentaire",
    field: "commentaire",
  },
];
