import { Col, Row } from "reactstrap";
import React from "react";

interface IProps {
  children: React.JSX.Element;
}

export default function Analyses(props: IProps): React.JSX.Element {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Analyses</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
      </Row>
      {props.children}
    </>
  );
}
