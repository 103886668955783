import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { columns } from "../../components/Grids/ListeParcelle/tableparcelle";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ParcelleController from "../../config/apiUtils/ParcelleController";
import AgGrid from "../../components/AgGrid";

export default function TableParcelles(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  useConstructor(async () => {
    setLoading(true);
    const typesSol = [];
    const resParcelles = await ParcelleController.getParcelles();
    store.setParcelles(resParcelles);
    resParcelles.forEach((parcelle) => {
      if (!typesSol.some((type) => type === parcelle.libelletypesol)) {
        typesSol.push(parcelle.libelletypesol);
      }
    });
    setLoading(false);
  });

  const onRowClick = async (params) => {
    props.onClickShowFicheParcelle(params.data.idparcelle);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des parcelles</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <AgGrid
            columnDefs={columns}
            rowData={store.parcelles}
            onRowClicked={onRowClick}
            showFilterLine
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
