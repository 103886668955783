import React, { useContext, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "src/components/SpinLoadingAnimation";
import CotisationController from "src/config/apiUtils/CotisationController";
import useConstructor from "src/config/hooks/useConstructor";
import { RenderIf } from "src/config/utils";
import StoreContext from "src/context/StoreContext";
import TableCotisation from "src/views/cotisation/TableCotisation";
import { ICotisation } from "src/config/types/cotisation";

export default function Cotisation(): React.JSX.Element {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [cotisations, setCotisations] = useState<ICotisation[]>([]);

  useConstructor(async () => {
    setLoading(true);
    const tmp = await CotisationController.getCotisationByMillesime(
      store.millesime.idmillesime,
    );
    tmp.forEach((cotisation: ICotisation) => {
      if (
        cotisation.surfaceinitiale !== null &&
        cotisation.surfacefinale !== null &&
        cotisation.cotisationannuelle !== null
      ) {
        cotisation.diffsurface = parseFloat(
          (cotisation.surfacefinale - cotisation.surfaceinitiale).toFixed(3),
        );
        cotisation.montantdiff = parseFloat(
          (
            (cotisation.surfacefinale - cotisation.surfaceinitiale) *
            cotisation.cotisationannuelle *
            1.2
          ).toFixed(2),
        );
      }
    });
    setCotisations(tmp);
    setLoading(false);
  });

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des cotisations</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button color="primary">
            <i className="fa-solid fa-plus" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <TableCotisation cotisations={cotisations} />
          </RenderIf>
        </Col>
      </Row>
    </>
  );
}
