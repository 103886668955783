import React from "react";
import {
  themeQuartz,
  colorSchemeDarkBlue,
  RowSelectedEvent,
  RowSelectionOptions,
  RowClickedEvent,
  CellEditingStoppedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AG_GRID_LOCALE_FR } from "@ag-grid-community/locale";
import { RenderIf } from "../config/utils";
import SpinLoadingAnimation from "./SpinLoadingAnimation";
import { cellStyleDefault } from "../config/gridUtils";
import useConstructor from "../config/hooks/useConstructor";

const customFrenchLocale = {
  ...AG_GRID_LOCALE_FR,
  true: "Oui",
  false: "Non",
};

interface IProps {
  rowData: object[];
  columnDefs: object[];
  onCellEditingStopped?: (params: any) => void;
  onRowClicked?: (params: RowClickedEvent) => void;
  showFilterLine?: boolean;
  height?: number;
  pagination?: boolean;
  paginationPageSize?: number;
  paginationPageSizeSelector?: number[];
  exportCsv?: boolean;
  selectable?: boolean;
  multiSelect?: boolean;
  onRowSelectionChanged?: ((params: any) => void) | undefined;
  onCellClicked?: ((params: any) => void) | undefined;
  onCellDoubleClicked?: ((params: any) => void) | undefined;
  emptyText?: string;
  setRef?: (ref: any) => void;
}

export default function AgGrid({
  rowData = [],
  columnDefs,
  onCellEditingStopped,
  onRowClicked,
  showFilterLine = false,
  height = 500,
  pagination = false,
  paginationPageSize = 50,
  paginationPageSizeSelector = [25, 50, 100, 200],
  exportCsv = false,
  selectable = false,
  multiSelect = true,
  onRowSelectionChanged = undefined,
  onCellClicked = undefined,
  onCellDoubleClicked = undefined,
  emptyText = undefined,
  setRef = undefined,
}: IProps) {
  const [loading, setLoading] = useState(false);
  const gridRef = useRef<any>(null);
  const theme = useMemo(() => {
    return themeQuartz.withPart(colorSchemeDarkBlue).withParams({
      backgroundColor: "rgb(42,51,63)",
      oddRowBackgroundColor: "rgb(73,100,113)",
    });
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 180,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  const onBtnExport = useCallback(() => {
    // @ts-ignore
    gridRef.current.api.exportDataAsCsv({
      fileName: "test",
      exportedRows: "filteredAndSorted",
    });
  }, []);

  const loadingCellRenderer = useCallback(SpinLoadingAnimation, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: "Chargement en cours...",
    };
  }, []);

  const rowSelection = useMemo((): RowSelectionOptions => {
    if (multiSelect) {
      return {
        mode: "multiRow",
        selectAll: "filtered",
        enableClickSelection: true,
      };
    }
    return {
      mode: "singleRow",
    };
  }, []);

  const onRowSelected = useCallback((event: RowSelectedEvent) => {
    if (selectable && onRowSelectionChanged != null) {
      onRowSelectionChanged(event.api.getSelectedNodes());
    }
  }, []);

  const updateTooltip = (columns: any) => {
    columns.forEach((c: any) => {
      if (c.valueFormatter && !c.tooltipField) {
        c.tooltipValueGetter = (params: any) =>
          String(c.valueFormatter(params)).replace(/\n/g, "<br/>");
      } else if (c.cellRenderer && !c.tooltipField) {
        c.tooltipValueGetter = (params: any) =>
          String(c.cellRenderer(params)).replace(/\n/g, "<br/>");
      } else if (c.field && !c.tooltipField) {
        c.tooltipField = c.field;
      }

      c.headerTooltip = c.headerName;

      if (c.children) {
        updateTooltip(c.children);
      }
    });
  };

  const updateCellStyle = (columns: any) => {
    columns.forEach((c: any) => {
      if (
        c.cellStyle == null ||
        c.cellStyle == undefined ||
        !Object.keys(c).includes("cellStyle")
      ) {
        c.cellStyle = cellStyleDefault;
      }

      if (c.children) {
        updateCellStyle(c.children);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    updateTooltip(columnDefs);
    updateCellStyle(columnDefs);
    setLoading(false);
  });

  useConstructor(() => {
    if (setRef) {
      setRef(gridRef);
    }
  });

  return (
    <>
      <RenderIf isTrue={exportCsv}>
        <div>
          <button onClick={onBtnExport}>Download CSV export file</button>
        </div>
      </RenderIf>
      <RenderIf isTrue={!loading}>
        <div style={{ height }}>
          <AgGridReact
            ref={gridRef}
            localeText={customFrenchLocale}
            theme={theme}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={showFilterLine ? defaultColDef : undefined}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            tooltipShowDelay={1000}
            loadingCellRenderer={loadingCellRenderer}
            loadingCellRendererParams={loadingCellRendererParams}
            enableCellTextSelection={true}
            rowSelection={selectable ? rowSelection : undefined}
            stopEditingWhenCellsLoseFocus
            onRowSelected={onRowSelected}
            onRowClicked={onRowClicked}
            onCellEditingStopped={async (params: CellEditingStoppedEvent) => {
              // Si l'édition est annulée, on arrête la fonction immédiatement
              if (
                params.newValue === undefined ||
                params.newValue === params.oldValue
              ) {
                return;
              }

              if (onCellEditingStopped) {
                await onCellEditingStopped(params);
              }

              if (gridRef.current) {
                gridRef.current.api.refreshCells({
                  rowNodes: [gridRef.current.api.getRowNode(params.node.id)],
                  force: true, // 🔥 Force la mise à jour
                });
              }
            }}
            onCellClicked={onCellClicked}
            onCellDoubleClicked={onCellDoubleClicked}
            overlayNoRowsTemplate={`<span style='padding: 10px; font-size: 16px;'>${emptyText ?? "Aucune donnée disponible"}</span>`}
          />
        </div>
      </RenderIf>
    </>
  );
}
