import AuthService from "../../../config/AuthService";
import {
  valueFormatterSurface,
  valueFormatterTonnage,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Parcelle",
    children: [
      {
        headerName: "Visualiser",
        field: "visualiser",
        minWidth: 100,
        filter: false,
        hide: true,
        cellRenderer: () => <i className="fa-regular fa-map"></i>,
      },
      {
        headerName: "Nom de la parcelle",
        field: "nomparcelle",
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: "Commune",
        field: "nomcommune",
        minWidth: 200,
        flex: 1,
        valueGetter: (params) =>
          params.data.codepostal + " " + params.data.nomcommune,
      },
      {
        headerName: "Variété",
        field: "libellevariete",
        minWidth: 170,
        flex: 1,
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: [],
        },
      },
    ],
  },
  {
    headerName: "Silo de livraison",
    field: "nomsilo",
    minWidth: 170,
    flex: 1,
  },
  {
    headerName: "Quantité de semences",
    field: "quantitesemence",
    minWidth: 170,
    flex: 1,
    valueFormatter: valueFormatterTonnage,
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface engagée",
        children: [
          {
            headerName: "Surface engagée printemps",
            field: "surfaceengageeprintemps",
            minWidth: 170,
            flex: 1,
            editable: false,
            columnGroupShow: "open",
            valueFormatter: valueFormatterSurface,
          },
          {
            headerName: "Surface engagée automne",
            field: "surfaceengageeautomne",
            minWidth: 170,
            flex: 1,
            editable: false,
            columnGroupShow: "open",
            valueFormatter: valueFormatterSurface,
          },
          {
            headerName: "Surface engagée totale",
            field: "surfaceengagee",
            minWidth: 170,
            flex: 1,
            columnGroupShow: "closed",
            valueFormatter: valueFormatterSurface,
          },
        ],
      },
      {
        headerName: "Surface engagée",
        children: [
          {
            headerName: "Surface réelle printemps",
            field: "surfaceprintemps",
            minWidth: 170,
            flex: 1,
            editable: true,
            columnGroupShow: "open",
            valueFormatter: valueFormatterSurface,
          },
          {
            headerName: "Surface réelle automne",
            field: "surfaceautomne",
            minWidth: 170,
            flex: 1,
            editable: true,
            columnGroupShow: "open",
            valueFormatter: valueFormatterSurface,
          },
          {
            headerName: "Surface réelle totale",
            field: "surfacetotale",
            minWidth: 170,
            flex: 1,
            valueGetter: (params) =>
              params.data.surfaceautomne + params.data.surfaceprintemps,
            columnGroupShow: "closed",
            valueFormatter: valueFormatterSurface,
          },
        ],
      },
    ],
  },
  {
    headerName: "Quantité livrée",
    field: "quantitelivree",
    minWidth: 170,
    flex: 1,
    valueFormatter: valueFormatterTonnage,
  },
  {
    headerName: "Type de sol",
    field: "libelletypesol",
    minWidth: 170,
    flex: 1,
  },
];
