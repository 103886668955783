import React, { useContext, useMemo, useState } from "react";
import StoreContext from "../../context/StoreContext";
import { getTileLayerXYZ } from "../../config/carto/utils";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import MapWrapper from "../../components/carto/MapWrapper";
import { IFeature } from "../../config/types/geojson";

interface IProps {
  geometries: IFeature | null;
  width: number | string;
  height: number | string;
}

export default function MapPreviewParcelles(props: IProps): React.JSX.Element {
  const context = useContext(StoreContext);
  const layer = getTileLayerXYZ(context.carto.urlCarto.pac.url);
  const [layerParcellesExploitation] = useState(
    new VectorLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(props.geometries),
      }),
      style: {
        "stroke-width": 0.75,
        "stroke-color": "rgb(166,106,0)",
        "fill-color": "rgba(255,162,0,0.7)",
      },
    }),
  );

  const zoomFeatures = () => {
    context.carto.zoomMap(new GeoJSON().readFeatures(props.geometries), {
      duration: 0,
      padding: [10, 10, 10, 10],
    });
  };

  useMemo(() => {
    zoomFeatures();
  }, [props.geometries]);

  return (
    <div style={{ height: props.height, width: props.width }}>
      <MapWrapper
        onMapClick={() => {}}
        layers={[layer, layerParcellesExploitation]}
      />
    </div>
  );
}
