const colors = {
  success: {
    background: "#d1e7dd",
    color: "#0f5132",
  },
  danger: {
    background: "#f9d7da",
    color: "#842029",
  },
  warning: {
    background: "#f5d883",
    color: "#664d00",
  },
  disabled: {
    background: "#b4b4b4",
    color: "#000000",
  },
};

export default colors;
