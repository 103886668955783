import React, { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import { RenderIf } from "../../../config/utils";
import StoreContext from "../../../context/StoreContext";

export default function InformationsExploitationWidget(): React.JSX.Element {
  const context = useContext(StoreContext);

  return (
    <Card style={{ height: 300, maxHeight: 300, marginTop: 10 }}>
      <CardHeader style={{ backgroundColor: "#344955" }}>
        <CardTitle style={{ fontWeight: "bold" }}>
          {context.exploitation.informations?.raisonsociale}
        </CardTitle>
      </CardHeader>
      <CardBody style={{ overflowY: "auto" }}>
        <Table style={{ color: "#FFFFFF" }}>
          <tbody>
            <tr>
              <td>
                <b>
                  {context.exploitation.informations?.ville ??
                    "Commune non renseignée"}
                </b>
              </td>
            </tr>
            <tr>
              <td>
                Siret :{" "}
                {context.exploitation.informations?.siret ??
                  "SIRET non renseigné"}
              </td>
            </tr>
            <tr>
              <td>
                {context.exploitation.informations?.libellezoneproduction}
              </td>
            </tr>
            <RenderIf isTrue={false}>
              <tr>
                <td>
                  {context.exploitation.parcellesExploitation.length} parcelle
                  {context.exploitation.parcellesExploitation.length > 1
                    ? "s"
                    : ""}
                </td>
              </tr>
            </RenderIf>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
