import React, { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import AnalysesController from "../../config/apiUtils/AnalysesController";
import moment from "moment";
import { columns } from "../../components/Grids/Analyses/contratsincomplets";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import Analyses from "../Analyses";
import CsvButton from "../../components/Buttons/CsvButton";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../components/AgGrid";
import { getColumnsMapForCsv } from "../../config/gridUtils";
import { IContratIncomplet } from "../../config/types/analyses";
import { RowClickedEvent } from "ag-grid-community";

export default function ContratsIncomplets(): React.JSX.Element {
  const navigate = useNavigate();
  const context = useContext(StoreContext);

  const [data, setData] = useState<IContratIncomplet[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useConstructor(async () => {
    setLoading(true);
    const res = await AnalysesController.getContratsIncomplets(
      context.millesime.idmillesime,
    );
    setData(res);
    setLoading(false);
  });

  const onRowClick = (event: RowClickedEvent) => {
    navigate(`/app/contrat?id=${event.data.idcontrat}`);
  };

  return (
    <Analyses>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>Contrats incomplets</CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={data.length > 0}>
                    <CsvButton
                      color="primary"
                      onClick={() => {
                        const name =
                          "analyses_contrats_incomplets_" +
                          moment().format("YYYY-MM-DD");
                        downloadCsv(
                          convertToCSV(data, ";", getColumnsMapForCsv(columns)),
                          name,
                        );
                        createNotification(
                          "info",
                          "Information",
                          "Export téléchargé dans le fichier : " + name,
                        );
                      }}
                    />
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <AgGrid
                  columnDefs={columns}
                  rowData={data}
                  onRowClicked={onRowClick}
                  showFilterLine
                />
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Analyses>
  );
}
