import { NavLink } from "react-router-dom";
import { Card, CardHeader, CardBody, CardTitle, Row } from "reactstrap";
import layoutRoutes from "../../config/layoutRoutes";
import { useNavigate } from "react-router-dom";
import React from "react";
import logoAPGMB from "src/assets/img/logo-apgmb.jpeg";

export default function InformationLogiciel(): React.JSX.Element {
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3">Informations</CardTitle>
      </CardHeader>
      <CardBody>
        <Row style={{ display: "flex" }}>
          <div style={{ flex: 0 }}>
            <img src={logoAPGMB}></img>
          </div>
          <div style={{ flex: 1 }}>
            <p>
              Ce logiciel est maintenu par l'Association des Producteurs de
              Graines de Moutarde en Bourgogne (APGMB) et est destiné
              exclusivement à l'usage des membres enregistrés du site.
              <br />
              En poursuivant votre navigation sur ce logiciel, vous acceptez les
              conditions d'utilisation posées par l'APGMB et vous engagez à les
              respecter.
            </p>
          </div>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <p>
            Ce logiciel a été développé par Billon Prestations, prestataire de
            services informatique.
            <br />© 2023 Billon Prestations. Tous droits réservés.
          </p>
          <p>
            Consulter les{" "}
            <NavLink
              style={{ textDecoration: "none", color: "#f9aa33" }}
              to={`${layoutRoutes.userLayout}/legal`}
            >
              mentions légales
            </NavLink>
            .
          </p>
          <p>
            Le logiciel Ecodim est actuellement en version{" "}
            <a
              style={{
                textDecoration: "none",
                color: "#f9aa33",
                cursor: "pointer",
              }}
              onClick={() => navigate("/app/versions")}
              title="Voir les versions"
              id="versionnum"
            >
              {window.env.BUILD_VERSION}
            </a>{" "}
            .
          </p>
        </Row>
      </CardBody>
    </Card>
  );
}
