import APIController from "../APIController";
import {
  IZoneProduction,
  IZoneProductionPOST,
  IZoneProductionPUT,
} from "src/config/types/zoneproduction";
import { AxiosResponse } from "axios";

class ZoneProductionController extends APIController {
  constructor() {
    super("/zoneproduction");
  }

  async getZonesProduction(): Promise<IZoneProduction[]> {
    return await this.getRequest("/");
  }

  async updateZoneProduction(
    iderefzoneproduction: number,
    zoneProduction: IZoneProductionPUT,
  ): Promise<AxiosResponse<IZoneProduction>> {
    return await this.putRequest(`/${iderefzoneproduction}`, zoneProduction);
  }

  async insertZoneProduction(
    zoneProduction: IZoneProductionPOST,
  ): Promise<AxiosResponse<IZoneProduction>> {
    return await this.postRequest("/", zoneProduction);
  }
}

export default new ZoneProductionController();
