import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import React, { useContext, useState } from "react";
import useConstructor from "../../config/hooks/useConstructor";
import ContactController from "../../config/apiUtils/ContactController";
import ValidateButton from "../../components/Buttons/ValidateButton";
import StoreContext from "../../context/StoreContext";
import { createNotification } from "../../config/utils";
import { IMotifContact } from "../../config/types/contact";

export default function FormulaireContact(): React.JSX.Element {
  const context = useContext(StoreContext);
  const [motifs, setMotifs] = useState<IMotifContact[]>([]);

  useConstructor(async () => {
    setMotifs(await ContactController.getMotifsContact());
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = {
      idmotifcontact: event.target.idmotifcontact.value,
      message: event.target.message.value,
      idutilisateur: context.utilisateur.informations.idutilisateur,
      idexploitation: context.exploitation.informations.idexploitation,
      millesime: context.millesime.idmillesime,
    };

    const res = await ContactController.sendDemandeContact(form);

    if (res.status === 200) {
      createNotification(
        "success",
        "Demande " +
          motifs.find(
            (motif) =>
              motif.idmotifcontact == event.target.idmotifcontact.value,
          )?.libelle +
          " envoyé avec succès",
      );
    }
  };

  const renderMotifsOption = () => {
    return motifs.map((motif, key) => (
      <option key={key} value={motif.idmotifcontact}>
        {motif.libelle}
      </option>
    ));
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Besoin d'aide ?</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <Label for={"idmotifcaontact"}>Type de demande</Label>
              <Input
                name={"idmotifcontact"}
                type={"select"}
                className="select-input"
                required
              >
                {renderMotifsOption()}
              </Input>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col>
              <Label for={"message"}>Demande</Label>
              <Input name={"message"} type={"textarea"} rows={5} required />
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidateButton />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
