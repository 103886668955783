export const columns = [
  {
    headerName: "Nom de la parcelle",
    field: "nomparcelle",
    minWidth: 200,
    defaultFlex: 1,
    editable: false,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    minWidth: 200,
    defaultFlex: 1,
    valueGetter: (params) =>
      params.data.codepostal + " " + params.data.nomcommune,
  },
  {
    headerName: "Surface engagée printemps",
    field: "surfaceengageeprintemps",
    minWidth: 170,
    defaultFlex: 1,
    editable: false,
  },
  {
    headerName: "Surface engagée automne",
    field: "surfaceengageeautomne",
    minWidth: 170,
    defaultFlex: 1,
    editable: false,
  },
  {
    headerName: "Surface engagée totale",
    field: "surfaceengagee",
    minWidth: 170,
    defaultFlex: 1,
  },
  {
    headerName: "Surface réelle printemps",
    field: "surfaceprintemps",
    minWidth: 170,
    defaultFlex: 1,
    editable: true,
  },
  {
    headerName: "Surface réelle automne",
    field: "surfaceautomne",
    minWidth: 170,
    defaultFlex: 1,
    editable: true,
  },
];
