import APIController from "../APIController";
import { IFormulaireContact, IMotifContact } from "../types/contact";
import { AxiosResponse } from "axios";

class ContactController extends APIController {
  constructor() {
    super("/contact");
  }

  async getMotifsContact(): Promise<IMotifContact[]> {
    return await this.getRequest("/motifs");
  }

  async getMotifContactById(idmotifcontact: number): Promise<IMotifContact> {
    return await this.getRequest(`/motifs/${idmotifcontact}`);
  }

  async sendDemandeContact(
    formulaire: IFormulaireContact,
  ): Promise<AxiosResponse> {
    return await this.postRequest("/send", formulaire);
  }
}

export default new ContactController();
